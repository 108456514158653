import { DiscordCTA } from "../../components/discord cta/DiscordCTA";
import { Header } from "../../components/header/Header";
import { ErrorMessage } from "../../components/middleware states/ErrorMessage";
import { LoadingSpinner } from "../../components/middleware states/LoadingAnimation";
import { Supporters } from "../../components/supporters/Supporters";
import { TzTable, TzTableCell, TzTableHeader, TzTableRow } from "../../components/tz table/TzTable";
import { useModal } from "../../ModalContext";
import { CasinoCategory, InfoCategory, ReviewData } from "../../models/ReviewData";
import { useGetReviewQuery } from "../../state/api";
import { ScoreCalculator } from "../score calculator/ScoreCalculator";
import "./AuditsPage.scss";

function ScoreToColor(score: number) {
  if (score >= 0.75) return "#00BD57";
  if (score >= 0.6) return "#97BD00";
  if (score >= 0.4) return "#C99101";
  if (score >= 0.2) return "#E76203";
  if (score >= 0) return "#E73629";
}

// export interface CasinoMetric {
//   text: string;
//   value: boolean;
//   score: number;
//   maxScore: number;
//   children?: {
//     [key: string]: CasinoSubMetric
//   }
// }

function DropdownCategory({ casino, categoryTitle, category }: { casino: string, categoryTitle: string, category: CasinoCategory  }) {
  const scoreColor = ScoreToColor(category.score / category.maxScore);

  return (
    <div className="audits-table__category">
      <div className="audits-table__category__header">
        <div className="audits-table__category__title">
          <p>{ categoryTitle }</p>
        </div>
        {
          category.maxScore > 0 && 
          (<div className="audits-table__score" style={{backgroundColor: scoreColor}}>
            <p>{category.score} / {category.maxScore}</p>
          </div>)
        }
      </div>
      <div className="audits-table__category__content">
        {category.children && Object.entries(category.children)
          .filter(([key, value]) => key !== 'Icon URL') 
          .filter(([key, value]) => key !== 'Description') 
          .filter(([key, value]) => key !== 'Company Name')
          .map(([metricKey, metricData]) => (
            <div className="audits-table__metric" key={`${casino}-${metricKey}`}>
              <div className="audits-table__metric__title"><p>{metricKey}</p></div>
              <div className="audits-table__metric__value">
                <p>
                  {metricKey === "Logs" ? (
                    <a className="" href={metricData.text} target="_blank" rel="noopener noreferrer">Link</a>
                  ) : (
                    metricData.text?.length > 0 ? metricData.text : metricData.score + " Points"
                  )}
                </p>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

function AuditsTable({auditsData} : {auditsData: ReviewData}) {
  const casinosData = Object.entries(auditsData.casinos);

  const headers: TzTableHeader[] = [
    { label: <p>#</p>, align: "left" },
    { label: <p>Casino</p>, align: "left" },
    { label: <p>Score</p>, align: "center", sortable: true },

    { label: <p>Compliance</p>, align: "center", sortable: true, hideOnMobile: true },
    { label: <p>Responsible Gaming</p>, align: "center", sortable: true, hideOnMobile: true },
    { label: <p>Security Measures</p>, align: "center", sortable: true, hideOnMobile: true },
    { label: <p>Support</p>, align: "center", sortable: true, hideOnMobile: true },

    { label: "", align: "right" }
  ];

  const rowData = casinosData.map(([casinoName, casinoData]) => {
    const infoCategories = (casinoData["Info"] as any as CasinoCategory);
    //console.log(infoCategories);
    
    const complianceCategory = casinoData["Compliance"] as CasinoCategory;
    const responsibleGamblingCategory = casinoData["Responsible Gambling"] as CasinoCategory;
    const securityMeasuresCategory = casinoData["Security Measures"] as CasinoCategory;
    const supportCategory = casinoData["Support"] as CasinoCategory;

    const scoreColor = ScoreToColor(casinoData.score / casinoData.maxScore);

    const row: TzTableRow =
      {
        key: casinoName,
        cells: 
        [
          { 
            content: <p key={`rank`}>{casinoData.rank}</p>
          },
          { 
            content: <div className="audits-table__casino"><img src={infoCategories.children["Icon URL"].text}/><p>{casinoName}</p></div> 
          },
          { 
            content: <div className="audits-table__score" style={{backgroundColor: scoreColor}}><p>{casinoData.score} / {casinoData.maxScore}</p></div>, 
            sortValue: casinoData.score 
          },
          { 
            content: <div className="audits-table__score hide-on-expand"><p>{complianceCategory.score} / {complianceCategory.maxScore}</p></div>,
            sortValue: complianceCategory.score
          },
          { content: <div className="audits-table__score hide-on-expand"><p>{responsibleGamblingCategory.score} / {responsibleGamblingCategory.maxScore}</p></div>, 
            sortValue: responsibleGamblingCategory.score 
          },
          { content: <div className="audits-table__score hide-on-expand"><p>{securityMeasuresCategory.score} / {securityMeasuresCategory.maxScore}</p></div>, 
            sortValue: securityMeasuresCategory.score 
          },
          { 
            content: <div className="audits-table__score hide-on-expand"><p>{supportCategory.score} / {supportCategory.maxScore}</p></div>, 
            sortValue: supportCategory.score
          },
          { 
            content:           
            <div className="audits-table__arrow">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 8" fill="none">
                <path d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z" fill="white"/>
              </svg>
            </div>
          },
        ],
        dropdownContent: 
        <div className="audits-table__dropdown">
          <DropdownCategory casino={casinoName} categoryTitle="Casino Information" category={infoCategories} />
          <div className="audits-table__category-divider"></div>
          <DropdownCategory casino={casinoName} categoryTitle="Compliance" category={complianceCategory} />
          <div className="audits-table__category-divider"></div>
          <DropdownCategory casino={casinoName} categoryTitle="Responsible Gaming" category={responsibleGamblingCategory} />
          <div className="audits-table__category-divider"></div>
          <DropdownCategory casino={casinoName} categoryTitle="Security Measures" category={securityMeasuresCategory} />
          <div className="audits-table__category-divider"></div>
          <DropdownCategory casino={casinoName} categoryTitle="Support" category={supportCategory} />
        </div>
      }

    return row;
  })

  return (
    <>
      <TzTable headers={headers} rowData={rowData} tableClassName="tz-audits-table" />
    </>
  );
}

export function AuditsPage() {
  const { data: auditsData, isLoading, isError } = useGetReviewQuery();

  const { openModal, closeModal } = useModal();

  const handleOpenModal = () => {
    openModal(<ScoreCalculator toggleModal={closeModal} />);
  };

  if (isError) {
    return (
      <div className="audits">
        <Header title={"CASINO AUDITS"} desc={"Casino audits with a focus on compliance, fairness and safety."} />
        <ErrorMessage message="There was an error loading audits data" />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="audits">
        <Header title={"CASINO AUDITS"} desc={"Casino audits with a focus on compliance, fairness and safety."} />
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <div className="audits">
        <Header title={"CASINO AUDITS"} desc={"Casino audits with a focus on compliance, fairness and safety."} />
        <div className="audits-table">
          <div className="score-button-container"> 
            {/* <div className="button score-button" onClick={handleOpenModal}><p>Score Calculator</p></div> */}
          </div>
          { auditsData && auditsData.casinos && <AuditsTable auditsData={auditsData} /> }
        </div>
      </div>
    </div>
  )
}