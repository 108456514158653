import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ReviewData } from '../models/ReviewData';
import { useEffect, useState } from 'react';
import { DashboardData } from '../models/DashboardData';
import { CreatorData } from '../models/CreatorsData';
import { StreamData } from '../models/StreamData';
import { BlogListResponse, BlogPostResponse } from '../models/BlogData';
import { GameData } from '../models/GameData';

//@ts-ignore
const baseUrl = process.env.REACT_APP_API_URL + "/api/";

const baseQuery = fetchBaseQuery({
  baseUrl
});

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQuery,
  tagTypes: ["Dashboard", "Creators", "Contact", "Review", "Blogs", "Session", "Bets"],
  endpoints: (builder) => ({
    getSessionToken: builder.query<{ token: string }, void>({
      query: () => '/session',
      providesTags: ['Session'],
    }),
    // Endpoint to play a game, sending the session token in the header
    playPlinko: builder.mutation<{ message: string; vrfRes?: any }, void>({
      query: () => {
        const token = localStorage.getItem('sessionToken');
        const clientSeed = localStorage.getItem('clientSeed');
        return {
          url: '/plinko',
          method: 'POST',
          body: {
            clientSeed
          },
          headers: token ? { authorization: `Bearer ${token}` } : {},
        };
      },
    }),
    playRoulette: builder.mutation<{ message: string; vrfRes?: any }, void>({
      query: () => {
        const token = localStorage.getItem('sessionToken');
        const clientSeed = localStorage.getItem('clientSeed');
        return {
          url: '/roulette',
          method: 'POST',
          body: {
            clientSeed
          },
          headers: token ? { authorization: `Bearer ${token}` } : {},
        };
      },
    }),
    getBetHistory: builder.query<{ bets: GameData[] }, void>({
      query: () => {
        const token = localStorage.getItem('sessionToken');
        return {
          url: '/bets',               // <--- your backend GET /bets endpoint
          method: 'GET',
          headers: token ? { authorization: `Bearer ${token}` } : {}
        };
      },
      providesTags: ['Bets'], // optional for cache invalidation
    }),
    getDashboard: builder.query<DashboardData, void>({
      query: () => '/dashboard',
      providesTags: ['Dashboard']
    }),
    getCreators: builder.query<CreatorData, void>({
      query: () => '/creators',
      providesTags: ['Creators']
    }),
    getCreator: builder.query<StreamData, string>({
      query: (id) => {
        if (id === "") {
          return ""; // Return an empty string or a placeholder to avoid making the request
        }
        return `/creators/${id}`;
      },
      providesTags: (result, error, id) => [{ type: 'Creators', id }],
      transformResponse: (response: any, meta, arg) => {
        if (arg === "") {
          return {kickStreams: []}; // Return an empty object if the id is empty
        }
        return response;
      }
    }),
    getReview: builder.query<ReviewData, void>({
      query: () => '/review',
      providesTags: ['Review'],
    }),
    contact: builder.mutation<any, any>({
      query: (contact: any) => ({
          url: `/contact`,
          method: 'POST',
          body: contact
      }),
      invalidatesTags: ['Contact']
    }),
    getBlogs: builder.query<BlogListResponse, number>({
      query: (page = 1) => `/blogs?page=${page}`,
      providesTags: (result, error, page) => [{ type: "Blogs", id: `page-${page}` }],
    }),
    getBlog: builder.query<BlogPostResponse, string>({
      query: (slug) => (slug ? `/blogs/${slug}` : ""),
      providesTags: (result, error, id) => [{ type: "Blogs", id }],
    }),
    subscribeNewsletter: builder.mutation<boolean, { email: string }>({
      query: ({ email }) => ({
        url: '/blogs/subscribe',
        method: 'POST',
        body: { email },
        headers: {
          'Content-Type': 'application/json'
        },
      }),
      // Transform the response to return true if the subscription status is "active"
      transformResponse: (response: any, meta) => {
        // If meta.response.status equals 200, return true.
        return meta?.response?.status === 200;
      },
    }),
  })
})

export const {
  useGetSessionTokenQuery,
  usePlayPlinkoMutation,
  usePlayRouletteMutation,
  useGetBetHistoryQuery,
  useGetDashboardQuery,
  useGetReviewQuery,
  useGetCreatorsQuery,
  useGetCreatorQuery,
  useContactMutation,
  useGetBlogsQuery,
  useGetBlogQuery,
  useSubscribeNewsletterMutation
} = apiSlice;
