import { TzTable, TzTableHeader, TzTableRow } from "../../../../components/tz table/TzTable";
import { ChainTimeframeData, DashboardData, EntityData } from "../../../../models/DashboardData";
import { useAnalyticsSettingsContext } from "../../AnalyticsSettingsContext";
import { ToSmallNumber } from "../../AnalyticsUtility";
import { DashSectionHeader } from "../section header/DashSectionHeader";
import "./Breakdowns.scss";

export function TokenBreakdown() {
  const { dashboardData, selectedCasinos, selectedChains, timeframe } = useAnalyticsSettingsContext();


 const headers: TzTableHeader[] = [
    { label: <p>#</p>, align: "left", width: "10%", mobileWidth: "6%" },
    { label: <p>Currency</p>, align: "left", width: "10%", mobileWidth: "12%" },
    { label: <p>Token Share</p>, align: "right", width: "17.5%", mobileWidth: "13%", sortable: true },
    { label: <p>Deposit Volume</p>, align: "right", width: "20%" },
    { label: <p>Deposits</p>, align: "right", width: "19%", sortable: true, mobileWidth: "17%" },
    { label: <p>AVG Deposit</p>, align: "right", sortable: true, mobileWidth: "23%" },
  ];

  const _data: any = {};

  const selectedData = dashboardData.entities.filter((entity) => selectedCasinos.includes(entity.id)).map(x => x.chains.find(y => y.chain === "AGG"));

  for (const chainData of selectedData) {
    if (!chainData) continue;
    //@ts-ignore
    const timeframeData = chainData[timeframe] as ChainTimeframeData;

    for (const transfer of timeframeData.transfers) {
      if (!_data[transfer.currencyName]) {
        _data[transfer.currencyName] = {
          currencyName: transfer.currencyName,
          iconUrl: dashboardData.tokenInfo.find(x => x.token === transfer.currencyName)?.iconUrl,
          deposits: 0,
          amountUsd: 0,
        };
      }

      _data[transfer.currencyName].deposits += transfer.deposits;
      _data[transfer.currencyName].amountUsd += transfer.amountUsd;
    }
  }

  const data = (Object.values(_data) as any[]).sort((a, b) => b.amountUsd - a.amountUsd);

  const totalDeposited = data.reduce((acc, groupedTransfers) => acc + groupedTransfers.amountUsd, 0);

  const aggregatedData = () => {
    if (!data || data.length <= 9) return data;
  
    const firstNine = data.slice(0, 9);
    const others = data.slice(9);
  
    const aggregatedOthers = others.reduce((acc, curr) => {
      acc.amountUsd += curr.amountUsd;
      acc.deposits += curr.deposits;
      return acc;
    }, { currencyName: "OTHER", amountUsd: 0, deposits: 0 });
  
    return [...firstNine, aggregatedOthers];
  };

  const shortData = aggregatedData();

  const rowData = shortData.sort((a, b) => b.amountUsd - a.amountUsd).map((groupedTransfers, index) => {
    const tokenShare = (groupedTransfers.amountUsd / totalDeposited * 100).toFixed(2) + "%"; // Round to 1 decimal place
    const deposited  = "$" + ToSmallNumber(groupedTransfers.amountUsd);
    const deposits = ToSmallNumber(groupedTransfers.deposits);
    const avgDeposit = "$" + ToSmallNumber(groupedTransfers.amountUsd / groupedTransfers.deposits);

    const row: TzTableRow =
      {
        key: index,
        cells: 
        [
          { 
            content: <p>{index + 1}</p>
          },
          { 
            content: <div className="audits-table__casino">
              {groupedTransfers.currencyName === "OTHER" ? <></> : <img style={groupedTransfers.iconUrl?.includes("coinmarketcap") ? {borderRadius: "100%"} : {}} src={groupedTransfers.iconUrl} alt={`Icon ${groupedTransfers.currencyName}`} /> }
              <p>{groupedTransfers.currencyName}</p>
              </div> 
          },
          { 
            content: <p>{tokenShare}</p>,
            sortValue: groupedTransfers.amountUsd / totalDeposited
          },
          { 
            content: <p>{deposited}</p>,
            sortValue: groupedTransfers.amountUsd
          },
          { 
            content: <p>{deposits}</p>,
            sortValue: groupedTransfers.deposits
          },
          { 
            content: <p>{avgDeposit}</p>,
            sortValue: groupedTransfers.amountUsd / groupedTransfers.deposits
          },
        ],
      }

    return row;
  })

  return (
    <div className="dashboard-section market-breakdown">
      <DashSectionHeader title={"Token Breakdown"} />
      <TzTable headers={headers} rowData={rowData} tableClassName="tz-breakdown-table" />
    </div>
  )
}