//@ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import Matter from 'matter-js';
import "./PlinkoGame.scss";
import gemSvg from '../../../../assets/gem.svg';
import { usePlayPlinkoMutation } from '../../../../state/api';
import { ProvablyFair } from '../../../provably fair/ProvablyFair';
import { PFButton } from '../../../../components/game buttons/PFButton';
import { useModal } from '../../../../ModalContext';
import { PlayButton } from '../../../../components/game buttons/PlayButton';


const cardsParams = [
  [
    [
        543.6718395340805,
        0.422102124493101,
        -0.08194515660623286
    ],
    [
        544.3639840872905,
        0.35939670404209556,
        0.010786432444768136
    ],
    [
        544.7311256211835,
        0.11981108048820072,
        0.08513379332466929
    ],
    [
        536.6902873435262,
        0.4704168399189488,
        0.07297067895525071
    ],
    [
        537.1835417830921,
        0.30929928029454734,
        0.028411175895610263
    ],
    [
        543.345333834553,
        0.26526627436885636,
        0.06297432007193296
    ],
    [
        541.9264603036443,
        0.22011595612116353,
        -0.04519284407950877
    ],
    [
        542.1063408212809,
        0.34071819368115563,
        -0.08009628741083069
    ],
    [
        535.6659481455532,
        0.3322840856174496,
        0.05890379685476615
    ],
    [
        543.4349012896882,
        0.17467439373329202,
        0.006342588386345893
    ],
    [
        544.4994036340341,
        0.16770741052755306,
        -0.07804329950566045
    ],


    [
        539.8026402639747,
        0.23776967930757156,
        0.06241955582337475
    ],


    [
        544.8042604943614,
        0.4502078203300569,
        0.048931494773906216
    ],

    [
        543.2617042395993,
        0.44605887054399307,
        -0.08057594086520554
    ]
  ],
  [
    [
        541.6020899276572,
        0.29497731976882813,
        0.004924376637639539
    ],
    [
        537.2043732086594,
        0.42556057593156527,
        -0.019147382743196363
    ],
    [
        542.8026903117402,
        0.3241984984398504,
        0.027940748166524632
    ],
    [
        537.8574281601701,
        0.10357691140578124,
        -0.04242248508403495
    ],
    [
        541.6568857749683,
        0.16271219878385174,
        0.06220222244391063
    ],
    [
        535.4017442895172,
        0.10833540380078484,
        0.0640718189178998
    ],
    [
        542.2412973520512,
        0.39620710238644297,
        -0.019484081492754202
    ],
    [
        543.5908581219562,
        0.34495885722680997,
        -0.09764317831162526
    ],




    [
        541.3091329998769,
        0.2628351360507999,
        0.05118618087788743
    ],
    [
        537.9851556538415,
        0.31552328939269836,
        -0.07177319809346616
    ],
    [
        543.5664017688928,
        0.448811379349062,
        -0.0775119520049361
    ],
    [
        543.2214308600346,
        0.33496863113123193,
        -0.0012531941335470532
    ],
    [
        541.0634364387475,
        0.26829586026194524,
        -0.07713036706797682
    ],
    [
        538.6101421520236,
        0.1738261097655694,
        -0.0007768142657202937
    ],
    [
        538.4880576106821,
        0.3774681650604593,
        -0.02440718336838108
    ],
    [
      535.9770407153559,
      0.3152143508699264,
      -0.06863678166599332
    ],
    [
        537.2616836237863,
        0.2319088639970106,
        -0.0039034345605567736
    ],
    [
        539.2337452789126,
        0.2844842481367691,
        0.013580311375815592
    ],
    [
        543.4204807173248,
        0.4881555435442728,
        -0.08330139567668389
    ],
    [
        539.0746379021326,
        0.37239828844648837,
        -0.010502369275209845
    ],
  ],
  [
    [
      540.3364032044828,
      0.48429561885714123,
      0.031459041420784616
    ],
    [
      540.98565720272,
      0.32850698428343206,
      0.09151775819537372
    ],
    [
      539.8093305100375,
      0.2385418429197099,
      0.02931553183109852
    ],
    [
        542.4062041454658,
        0.10575865513167325,
        -0.0680834868064109
    ],
    [
      543.7252100262741,
      0.47707677843009666,
      -0.03819703151738625
    ],
      [
        542.0745003171463,
        0.4992600952639682,
        0.08381030574656877
    ],
      [
        540.0577027617342,
        0.1320003744724855,
        0.0445085568324473
    ],
    [
      542.931350601651,
      0.46726770140577634,
      -0.06323280194475563
    ],
      [
        543.5474175452783,
        0.3969662516808743,
        0.0007659170908991709
    ],
    [
      536.2676602164681,
      0.4818036438940324,
      0.04254539928957329
  ],
  [
      542.682622164305,
      0.3229175062489523,
      -0.09010152158527177
  ],
  [
    540.3949710127195,
    0.20799001576277076,
    -0.02434361727569563
],
[
    539.604529515676,
    0.1826784196987581,
    -0.03642492141430256
],

    [
        539.106898469585,
        0.3538192027966802,
        -0.02264300209082193
    ],
    [
        538.7635181755298,
        0.3823344521127806,
        0.0571562556323086
    ],
    [
        538.3080993757493,
        0.1973059679532333,
        -0.08784522065844214
    ],
    [
        541.2788862039998,
        0.25713631519010305,
        0.05859282154839578
    ],
    [
        537.3142266066183,
        0.2224035429398611,
        -0.06607514936317878
    ],
    [
        542.0445749975175,
        0.12217395623127213,
        0.02230482463898791
    ],
    [
        540.4188323792525,
        0.16280000871768624,
        -0.08944643297741744
    ],
  ]
]

const PlinkoGame = () => {
  const canvasRef = useRef(null);
  const engineRef = useRef<null | Matter.Engine>(null);
  const renderRef = useRef<null | Matter.Render>(null);
  //const didRunOnce = useRef(false);
  const intervalIdRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [bounceCounters, setBounceCounters] = useState({ "0": 0, "1": 0, "2": 0 });

  const handleCardHit = (cardId: number) => {
    setBounceCounters(prev => ({ ...prev, [cardId]: prev[cardId] + 1 }));
  };

  // ============ MAIN GAME SETUP ============
  useEffect(() => {
    const engine = Matter.Engine.create();
    const world = engine.world;
    engineRef.current = engine;

    const canvas = canvasRef.current as any as HTMLCanvasElement;
    const render = Matter.Render.create({
      canvas,
      engine,
      options: {
        width: 1080,
        height: 900 + 225 + 1200 + 10,
        background: '',
        wireframes: false,
      },
    });
    renderRef.current = render;
    const runner = Matter.Runner.create();

    // Card zones
    const cardZone1 = Matter.Bodies.rectangle(
      420 - (1520 - 1080) / 2,
      1130 + 1200 + 10,
      300,
      10,
      { isStatic: true, render: { fillStyle: '#ff0000' }, label: "cardZone1" }
    );
    const cardZone2 = Matter.Bodies.rectangle(
      755 - (1520 - 1080) / 2,
      1130 + 1200 + 10,
      300,
      10,
      { isStatic: true, render: { fillStyle: '#0000ff' }, label: "cardZone2" }
    );
    const cardZone3 = Matter.Bodies.rectangle(
      1100 - (1520 - 1080) / 2,
      1130 + 1200 + 10,
      300,
      10,
      { isStatic: true, render: { fillStyle: '#00ff00' }, label: "cardZone3" }
    );

    // Pegs
    const pegRadius = 8;
    const pegRows = 7;
    const pegOffsetX = 33;
    const pegOffsetY = 450 + 1200;
    const pegSpacingX = 169;
    const pegSpacingY = 105;
    const pegs: Matter.Body[] = [];

    for (let row = 2; row < pegRows; row++) {
      const numPegs = row + 1;
      for (let col = 0; col < numPegs; col++) {
        const x = pegOffsetX + ((pegRows - row - 1) * pegSpacingX) / 2 + col * pegSpacingX;
        const y = pegOffsetY + row * pegSpacingY;
        const pegBody = Matter.Bodies.circle(x, y, pegRadius, {
          isStatic: true,
          label: 'peg',
          render: { fillStyle: 'none' },
        });
        pegs.push(pegBody);
      }
    }

    Matter.Composite.add(world, [...pegs, cardZone1, cardZone2, cardZone3]);

    Matter.Events.on(engine, 'collisionStart', (event) => {
      event.pairs.forEach((pair) => {
        const { bodyA, bodyB } = pair;
        const gem = bodyA.label === 'gem' ? bodyA : bodyB;

        if (
          (bodyA.label === 'cardZone1' && bodyB.label === 'gem') ||
          (bodyB.label === 'cardZone1' && bodyA.label === 'gem')
        ) {
          Matter.Composite.remove(world, gem);
          handleCardHit(0);
        }
        if (
          (bodyA.label === 'cardZone2' && bodyB.label === 'gem') ||
          (bodyB.label === 'cardZone2' && bodyA.label === 'gem')
        ) {
          Matter.Composite.remove(world, gem);
          handleCardHit(1);
        }
        if (
          (bodyA.label === 'cardZone3' && bodyB.label === 'gem') ||
          (bodyB.label === 'cardZone3' && bodyA.label === 'gem')
        ) {
          Matter.Composite.remove(world, gem);
          handleCardHit(2);
        }

        if (bodyA.label === "peg" || bodyB.label === "peg") {
          const peg = bodyA.label === 'peg' ? bodyA : bodyB;
          peg.isPulsing = true;
          peg.pulseStartTime = performance.now();
        }
      });
    });

    Matter.Events.on(render, 'afterRender', () => {
      const ctx = render.context;
      const now = performance.now();
      const pulseDuration = 500;
      pegs.forEach((peg) => {
        ctx.save();
        const baseGrad = ctx.createLinearGradient(
          peg.position.x,
          peg.position.y - peg.circleRadius,
          peg.position.x,
          peg.position.y + peg.circleRadius
        );
        baseGrad.addColorStop(0, '#444751');
        baseGrad.addColorStop(1, '#2A3045');
        ctx.fillStyle = baseGrad;
        ctx.beginPath();
        ctx.arc(peg.position.x, peg.position.y, peg.circleRadius, 0, Math.PI * 2);
        ctx.fill();
        ctx.restore();

        if (peg.isPulsing && peg.pulseStartTime) {
          let elapsed = now - peg.pulseStartTime;
          let progress = Math.min(elapsed / pulseDuration, 1);
          let spread = progress * 20;
          let alpha = 0.2 * (1 - progress);
          ctx.save();
          ctx.beginPath();
          ctx.arc(peg.position.x, peg.position.y, peg.circleRadius + spread, 0, Math.PI * 2);
          ctx.fillStyle = `rgba(253, 158, 251, ${alpha})`;
          ctx.fill();

          const grad = ctx.createLinearGradient(
            peg.position.x,
            peg.position.y - peg.circleRadius,
            peg.position.x,
            peg.position.y + peg.circleRadius
          );
          grad.addColorStop(0.04, '#FD9EFB');
          grad.addColorStop(0.8, '#F658DD');
          ctx.fillStyle = grad;
          ctx.beginPath();
          ctx.arc(peg.position.x, peg.position.y, peg.circleRadius, 0, 2 * Math.PI);
          ctx.fill();
          ctx.restore();

          if (progress === 1) {
            peg.isPulsing = false;
            peg.pulseStartTime = null;
          }
        }
      });
    });

    Matter.Render.run(render);
    Matter.Runner.run(runner, engine);

    return () => {
      Matter.Render.stop(render);
      Matter.Runner.stop(runner);
      Matter.World.clear(world, false);
      Matter.Engine.clear(engine);
      render.textures = {};
    };
  }, []);

  // ============ DROP GEM IN MAIN GAME ============
  const dropGem = (bin: number) => {

    // const gemX = 540;
    // const restitution = 0.3;
    // const angularVelocity = 0;

    const [gemX, restitution, angularVelocity] = cardsParams[bin][Math.floor(Math.random() * cardsParams[bin].length)];

    if (!engineRef.current) return;
    const divisor = 6;
    const gemVertices = [
      { x: 140 / divisor, y: 0 },
      { x: 414 / divisor, y: 0 },
      { x: 552 / divisor, y: 142 / divisor },
      { x: 275.5 / divisor, y: 418 / divisor },
      { x: 0, y: 142 / divisor }
    ];
    const gem = Matter.Bodies.fromVertices(
      gemX,
      -200,
      [gemVertices],
      {
        isStatic: false,
        restitution: restitution,
        label: 'gem',
        collisionFilter: {
          group: -1,
        },
        render: {
          sprite: {
            texture: gemSvg,
            xScale: 1 / divisor,
            yScale: 1 / divisor,
          },
        },
      },
      true
    );
    Matter.Body.setAngularVelocity(gem, angularVelocity);
    Matter.Composite.add(engineRef.current.world, gem);
  };

  // ===== API Call & Simulation =====
  const [playPlinko] = usePlayPlinkoMutation();

  const performGameRound = async () => {
    try {
      const result = await playPlinko().unwrap();
      const outcome = result.vrfRes.outcome;
      // Map outcome to a bin value (for example purposes)
      const bin = outcome === plinkoData[0].result ? 0 : outcome === plinkoData[1].result ? 1 : 2;
      dropGem(bin);
    } catch (err) {
      console.error("Error calling playGame API:", err);
    }
  };

  const hasCalledImmediate = useRef(false);

  useEffect(() => {
    if (isPlaying) {
      if (!hasCalledImmediate.current) {
        performGameRound();
        hasCalledImmediate.current = true;
      }
      intervalIdRef.current = setInterval(performGameRound, 2500);
    } else {
      clearInterval(intervalIdRef.current);
      hasCalledImmediate.current = false; // Reset for next time
    }
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [isPlaying]);

  const { openModal, closeModal } = useModal();

  const handleOpenModal = () => {
    openModal(<ProvablyFair toggleModal={closeModal} />);
  };

  return (
    <>
      <div className="plinko">
        <canvas ref={canvasRef} />
        <div className="cards">
          <BouncyPlinkoCard result={0} bounceCounters={bounceCounters} />
          <BouncyPlinkoCard result={1} bounceCounters={bounceCounters} />
          <BouncyPlinkoCard result={2} bounceCounters={bounceCounters} />
        </div>
        <div className="plinko__controls">
          <div onClick={() => setIsPlaying(!isPlaying)}>
            <PlayButton play={!isPlaying} disabled={false}/>
          </div>
          <PFButton openModal={handleOpenModal} />
        </div>
      </div>
    </>
  );
};

function BouncyPlinkoCard({ result, bounceCounters }: any) {
  return (
    <div key={`${result}-${bounceCounters[result]}`} className={`card ${bounceCounters[result] > 0 ? 'bounce' : ''}`}>
      <BasePlinkoCard result={result} />
    </div>
  );
}

export function PlinkoCard({result}: {result: number}) {
  return (
    <div className="card">
      <BasePlinkoCard result={result} />
    </div>
  )
}

export const plinkoData = [
  {
    id: 0,
    result: "Innovative",
    description: "Leading the charge with innovative solutions."
  },
  {
    id: 1,
    result: "Comprehensive",
    description: "Thorough analysis of the leading crypto casinos."
  },
  {
    id: 2,
    result: "Data-driven",
    description: "Harnessing cutting edge technology to find value."
  }
]

function BasePlinkoCard({result}: {result: number}) {
  const id = Math.min(result, plinkoData.length - 1)
  const title = plinkoData[id].result;
  const desc = plinkoData[id].description;

  //console.log(result)

  return (
    <>
      <div className="card__title">{title}</div>
      <div className="card__desc">{desc}</div>
    </>
  )
}

export default PlinkoGame;
