import React, { useState, useRef, useEffect } from 'react';
import styles from './CustomSelect.module.scss';

export interface SelectOption {
  value: string;
  label: string;
  leftIcon?: React.ReactNode;
  rightIcons?: React.ReactNode[]; 
}


export interface SelectProps {
  options: SelectOption[];
  value: string[];
  onChange: (val: string[]) => void;
  placeholder?: string;
  multiple?: boolean;
  className?: string;
  maxDisplayItems?: number;
  // Add a prop to control whether we show icons in the right or not
  showSelectedIcons?: boolean;
}

export const Select: React.FC<SelectProps> = ({
  options,
  value,
  onChange,
  placeholder = 'Select...',
  multiple = false,
  className,
  maxDisplayItems = 1,
  showSelectedIcons = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  // Close if user clicks outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelectOption = (optionValue: string) => {
    if (!multiple) {
      onChange([optionValue]);
      setIsOpen(false);
    } else {
      if (value.includes(optionValue)) {
        onChange(value.filter((val) => val !== optionValue));
      } else {
        onChange([...value, optionValue]);
      }
    }
  };

  // Decide what to show as the label (left side)
  let displayLabel = placeholder;
  if (value.length > 0) {
    if (!multiple) {
      const selectedOption = options.find((opt) => opt.value === value[0]);
      displayLabel = selectedOption ? selectedOption.label : placeholder;
    } else {
      if (value.length < maxDisplayItems) {
        displayLabel = value
          .map((val) => options.find((opt) => opt.value === val)?.label)
          .filter(Boolean)
          .join(', ');
      } else {
        displayLabel = `${value.length} selected`;
      }
    }
  }

  return (
    <div className={`${styles.container} ${className || ''}`} ref={containerRef}>
      <button
        type="button"
        className={"button " + styles.selectButton}
        onClick={() => setIsOpen(!isOpen)}
      >
        {/* Left area: displayed label */}
        <span className={styles.selectLabel}>{displayLabel}</span>

        {/* Right area: if showSelectedIcons, put them next to the arrow */}
        <div className={styles.rightSide}>
          <span className={styles.arrowIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 8" fill="none">
              <path d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z" fill="white"/>
            </svg>
          </span>
        </div>
      </button>

      {isOpen && (
        <div className={styles.dropdown}>
          {options.map((option) => {
            const isSelected = value.includes(option.value);

            return (
              <div
                key={option.value}
                className={`button ${styles.option} ${isSelected ? styles.selected : ''}`}
                onClick={() => handleSelectOption(option.value)}
              >
                {/* Left icon (if provided) */}
                {option.leftIcon && (
                  <span className={styles.leftIcon}>{option.leftIcon}</span>
                )}

                {/* The option label in the middle */}
                <span className={styles.optionLabel}>
                  <p>{option.label}</p>
                </span>

                {/* The right icons (if any). If multiple, map over them. */}
                {option.rightIcons && option.rightIcons.length > 0 && (
                  <span className={styles.rightIconsContainer}>
                    {option.rightIcons.map((icon, idx) => (
                      <span key={idx} className={styles.rightIcon}>
                        {icon}
                      </span>
                    ))}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
