import { useState } from "react";
import { ProvablyFair } from "../../pages/provably fair/ProvablyFair";
import "./PFButton.scss";

export function PFButton({openModal}: {openModal: () => void}) {
  return (
    <div className="pf-button-container">
      <div className="button pf-button" onClick={() => openModal()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M9.12495 12.9635L13.8333 8.25521L12.6458 7.06771L9.12495 10.5885L7.37495 8.83854L6.18745 10.026L9.12495 12.9635ZM9.99995 18.3385C8.06939 17.8524 6.47564 16.7448 5.2187 15.0156C3.96175 13.2865 3.33328 11.3663 3.33328 9.25521V4.17187L9.99995 1.67188L16.6666 4.17187V9.25521C16.6666 11.3663 16.0381 13.2865 14.7812 15.0156C13.5243 16.7448 11.9305 17.8524 9.99995 18.3385ZM9.99995 16.5885C11.4444 16.1302 12.6388 15.2135 13.5833 13.8385C14.5277 12.4635 14.9999 10.9358 14.9999 9.25521V5.31771L9.99995 3.44271L4.99995 5.31771V9.25521C4.99995 10.9358 5.47217 12.4635 6.41662 13.8385C7.36106 15.2135 8.5555 16.1302 9.99995 16.5885Z" fill="white"/>
        </svg>
        <p>
          Provably Fair
        </p>
      </div>
    </div>
  )
}