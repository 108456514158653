// useCasinoSettings.ts

import { useState } from 'react';
import React, { createContext, useContext } from 'react';
import { DashboardData } from '../../models/DashboardData';

interface AnalyticsSettingsHook {
  dashboardData: DashboardData;
  setDashboardData: React.Dispatch<React.SetStateAction<DashboardData>>;
  selectedCasinos: string[];
  setSelectedCasinos: React.Dispatch<React.SetStateAction<string[]>>;
  selectedChains: string[];
  setSelectedChains: React.Dispatch<React.SetStateAction<string[]>>;
  timeframe: string;
  setTimeframe: React.Dispatch<React.SetStateAction<string>>;
}

function useAnalyticsSettings(): AnalyticsSettingsHook {
  const [dashboardData, setDashboardData] = useState<DashboardData>({} as any);
  const [selectedCasinos, setSelectedCasinos] = useState<string[]>(["Stake", "Rollbit", "Roobet", "Gamdom", "Shuffle"]);
  const [selectedChains, setSelectedChains] = useState<string[]>(["ETH", "SOL", "BSC", "TRX"]);
  const [timeframe, setTimeframe] = useState<string>('year');

  return {
    dashboardData,
    setDashboardData,
    selectedCasinos,
    setSelectedCasinos,
    selectedChains,
    setSelectedChains,
    timeframe,
    setTimeframe,
  };
}


const AnalyticsSettingsContext = createContext<AnalyticsSettingsHook | undefined>(undefined);

export const AnalyticsSettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const settings = useAnalyticsSettings();
  return (
    <AnalyticsSettingsContext.Provider value={settings}>
      {children}
    </AnalyticsSettingsContext.Provider>
  );
};

export const useAnalyticsSettingsContext = (): AnalyticsSettingsHook => {
  const context = useContext(AnalyticsSettingsContext);
  if (!context) {
    throw new Error("useAnalyticsSettingsContext must be used within an AnalyticsSettingsProvider");
  }
  return context;
};