// AuthContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useGetSessionTokenQuery } from './state/api';
import * as jwt_decode from 'jwt-decode';

interface AuthContextValue {
  token: string | null;
  clientSeed: string | null;
  userId: string | null;
  setClientSeed: (seed: string) => void;
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Read token from localStorage synchronously.
  const existingToken = localStorage.getItem('sessionToken');

  // Only fetch session token if one doesn't already exist.
  const { data } = useGetSessionTokenQuery(undefined, {
    skip: !!existingToken, // Skip query if token exists
  });

  const [session, setSessionState] = useState<string | null>(() => existingToken);
  const [clientSeed, setClientSeedState] = useState<string | null>(() => localStorage.getItem('clientSeed'));
  const [userId, setUserIdState] = useState<string | null>(() => localStorage.getItem('userId'));

  const setSessionToken = (newToken: string) => {
    setSessionState(newToken);
    localStorage.setItem('sessionToken', newToken);
  };

  const setClientSeed = (newSeed: string) => {
    setClientSeedState(newSeed);
    localStorage.setItem('clientSeed', newSeed);
  };

  const setUserId = (newUserId: string) => {
    setUserIdState(newUserId);
    localStorage.setItem('userId', newUserId);
  };

  // Initialize clientSeed if not set
  useEffect(() => {
    if (!clientSeed) {
      setClientSeed('');
    }
  }, [clientSeed]);

  // When data is fetched and available, update the token.
  useEffect(() => {
    if (data) {
      setSessionToken(data.token);
    }
  }, [data]);

  // If a token exists, decode it to extract the sessionId and store it in localStorage.
  useEffect(() => {
    if (session) {
      try {
        const decoded = jwt_decode.jwtDecode(session) as any;
        const sessionId = decoded.sessionId; // Assuming the token payload includes a field named "sessionId"
        setUserId(sessionId);
      } catch (err) {
        console.error('Error decoding token:', err);
      }
    }
  }, [session]);

  return (
    <AuthContext.Provider value={{ token: session, clientSeed, userId, setClientSeed }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextValue => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
