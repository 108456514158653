import { useEffect, useState } from "react"
import "./Consultancy.scss"
import homeBackground from "../../../../assets/home-background.mp4";
import ethIcon from "../../../../assets/crypto/eth-icon.png";
import solIcon from "../../../../assets/crypto/sol-icon.png";
import trxIcon from "../../../../assets/crypto/trx-icon.png";
import bscIcon from "../../../../assets/crypto/bsc-icon.png";
import { NavLink } from "react-router-dom";

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function Consultancy() {
  const [ ethNetwork, setEthNetwork ] = useState(0);
  const [ bscNetwork, setBscNetwork ] = useState(0);
  const [ solNetwork, setSolNetwork ] = useState(0);
  const [ trxNetwork, setTrxNetwork ] = useState(0);
  const [ allNetworks, setAllNetworks ] = useState(0);

  const ethIncrement = 915700000 / 30 / 24 / 60 / 60 / 10 * 5;
  const bscIncrement = 247300000 / 30 / 24 / 60 / 60 / 10 * 5;
  const solIncrement = 292000000 / 30 / 24 / 60 / 60 / 10 * 5;
  const trxIncrement = 1299200000 / 30 / 24 / 60 / 60 / 10 * 5;

  useEffect(() => {
    const ethInterval = setInterval(() => {
      setEthNetwork((prev) => prev + ethIncrement);
    }, 500);

    const bscInterval = setInterval(() => {
      setBscNetwork((prev) => prev + bscIncrement);
    }, 500);

    const solInterval = setInterval(() => {
      setSolNetwork((prev) => prev + solIncrement);
    }, 500);

    const trxInterval = setInterval(() => {
      setTrxNetwork((prev) => prev + trxIncrement);
    }, 500);

    // Cleanup intervals on component unmount
    return () => {
      clearInterval(ethInterval);
      clearInterval(bscInterval);
      clearInterval(solInterval);
      clearInterval(trxInterval);
    };
  }, []);


  return (
    <div className="consultancy">
      <div className="hero__background">
        <video autoPlay muted loop playsInline className="hero__background__video">
          <source src={homeBackground} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero__background__overlay"></div>
        <div className="hero__background__overlay2"></div>
      </div>
      <div className="consultancy__left">
        <div className="live-deposits">
          <div className="live-deposits__title">
            <p>Average live deposits</p>
          </div>
          <div className="live-deposits__networks">
            <div className="live-deposits__tracker">
              <img src={ethIcon}></img>
              <p>{formatter.format(ethNetwork)}</p>
            </div>
            <div className="live-deposits__tracker">
              <img src={bscIcon}></img>
              <p>{formatter.format(bscNetwork)}</p>
            </div>
            <div className="live-deposits__tracker">
              <img src={solIcon}></img>
              <p>{formatter.format(solNetwork)}</p>
            </div>
            <div className="live-deposits__tracker">
              <img src={trxIcon}></img>
              <p>{formatter.format(trxNetwork)}</p>
            </div>
          </div>
          <div className="live-deposits__sub-title">
            <p>All networks</p>
          </div>
          <div className="live-deposits__tracker">
            <p>{ formatter.format(ethNetwork + bscNetwork + solNetwork + trxNetwork) }</p>
          </div>
          <div className="live-deposits__desc">
            <p>Displayed figures are estimates based on average deposit volume per second from the top 10 crypto casinos by volume in the past month.</p>
          </div>
        </div>
      </div>
      <div className="consultancy__right">
        <div className="consultancy__title">
          <h1>INNOVATIVE<br></br>VALUE DRIVEN<br></br>CONSULTANCY</h1>
        </div>
        <div className="consultancy__desc">
          <p>
            We offer consultancy services in the crypto iGaming industry to set the Tanzanite standard for all crypto casino's and assure the best experience for users.
          </p>
        </div>
        <NavLink to={'/contact'}>
          <div className="button consultancy__contact"><p>Get in touch</p></div>
        </NavLink>
      </div>
    </div>
  )
}