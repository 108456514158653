import { JSX, useEffect, useMemo, useState } from "react";
import "./VerifySection.scss";
import { Buffer } from "buffer";
import blake from "blakejs";

import { verify } from "@roamin/ecvrf";
import { TestimonialCard, testimonialsData } from "../../home/components/testimonials/Testimonials";
import { PlinkoCard, plinkoData } from "../../home/components/plinko game/PlinkoGame";
const toHex = require('string-hex')

function verifyInputs(
  publicKey: string,
  request: string,
  proof: string,
  randomHash: string
): boolean {
  try {
    // Convert the request string to hex before verification
    const requestHex = toHex(request);

    // The verify function returns the computed VRF output as a hex string (no '0x' prefix).
    const result = verify(publicKey, proof, requestHex);

    // Compare the result to randomHash. You may want to compare case-insensitively:
    return result.toLowerCase() === randomHash.toLowerCase();
  } catch (error) {
    return false;
  }
}

function bytesToNumber(hashBytes: Uint8Array, numRange: number) {
  const decValues = Array.from(hashBytes);

  // Each term: byte / (256^(index+1))
  const terms = decValues.map((byte, i) => byte / 256 ** (i + 1));
  const sum = terms.reduce((acc, val) => acc + val, 0);
  const multiplied = sum * numRange;
  const finalNumber = Math.floor(multiplied);

  return { decValues, terms, sum, multiplied, finalNumber };
}

function chunkBytes(hashBytes: Uint8Array, chunkSize: number): Uint8Array[] {
  const chunks: Uint8Array[] = [];
  for (let i = 0; i < hashBytes.length; i += chunkSize) {
    chunks.push(hashBytes.slice(i, i + chunkSize));
  }
  return chunks;
}

function SeedToBytesTable({ randomHash, hashBytes }: { randomHash: string, hashBytes: Uint8Array | null }) {
  if (!hashBytes) return null;

  const hexValues = Array.from(hashBytes).map((byte) => byte.toString(16).padStart(2, "0"));
  const decValues = Array.from(hashBytes);

  return (
    <>
      <div className="pf-calculation__hash">
        <p>BLAKE2b({randomHash}::32)</p>
      </div>
      <table>
      <tbody>
        <tr>
          {hexValues.map((hex, index) => (
            <td 
              key={index}
              style={{
                width: "2.3em",
              }}
            >{hex}</td>
          ))}
        </tr>
        <tr>
          {decValues.map((dec, index) => (
            <td key={index} className="bold">
              {dec}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
    </>
  );
}

function BytesToNumberTable({ randomNumRange, hashBytes }: { randomNumRange: number, hashBytes: Uint8Array | null }) {
  if (!hashBytes) return null;

  const fourByteChunks = chunkBytes(hashBytes, 4);

  return (
    <div className="pf-calculation__to-numbers">
      {/* For each 4-byte chunk, produce a separate table */}
      {fourByteChunks.map((chunk, chunkIndex) => {
        const { decValues, terms, sum, multiplied, finalNumber } = bytesToNumber(
          chunk,
          randomNumRange
        );

        // Build row data for each term in the chunk.
        const termRows = decValues.map((byte, i) => ({
          sign: i === 0 ? "" : "+",
          term: terms[i].toFixed(12),
          expression: `(${byte.toString().padStart(3, "0")} / (256^${i + 1}))`,
        }));

        // Add a row for the sum.
        const sumRow = {
          sign: "=",
          term: sum.toFixed(12),
          expression: "",
        };

        // Add a row for multiplied-by-numRange.
        const multipliedString = multiplied.toFixed(12).split(".");
        const multipliedRow = {
          sign: "=",
          term: (
            <>
              <span className="bold">{multipliedString[0]}</span>.
              {multipliedString[1]}
            </>
          ),
          expression: `(*${randomNumRange})`,
        };

        const rows = [...termRows, sumRow, multipliedRow];

        return (
          <div className="pf-calculation__to-number" key={chunkIndex}>
            <div className="bold">
              <p>
                {/* Show the chunkIndex and finalNumber as you like */}
                ({Array.from(chunk).join(", ")})
                → [0, ..., {randomNumRange}] = {finalNumber}
              </p>
            </div>
            <table>
              <tbody>
                {rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td style={{ textAlign: "right" }}>{row.sign}</td>
                    <td style={{ textAlign: "right", width: "10em" }}>{row.term}</td>
                    <td
                      style={{
                        textAlign: "right",
                        paddingLeft: "2em",
                        width: "11em",
                        opacity: 0.5,
                      }}
                    >
                      {row.expression}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
}

function PlinkoFinalResultTable({ numberResults }: { numberResults: number[] | null }) {

  if (numberResults === null) return <>Error</>

  const relevantNumResults = numberResults?.slice(0, 5);
  const finalResult = Math.floor(relevantNumResults.reduce((partialSum, a) => partialSum + a, 0) / 2);

  const getClass = (col: number) => (finalResult === col ? " highlight" : "");

  return (
    <div className="pf-calculation__final-result">
      <p>
        Outcome index: floor(({relevantNumResults.join(" + ")}) / 2) = {finalResult}
      </p>
      <div className="pf-calculation__final-result__options">
        {plinkoData.map((res, index) => (
          <div key={index} className={"pf-calculation__final-result__option" + getClass(index)}>
            <div>
              <p>{index}</p>
            </div>
            <div>
              <p>{res.result}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function RouletteFinalResultTable({ numberResults }: { numberResults: number[] | null }) {

  if (numberResults === null) return <>Error</>

  const relevantNum = numberResults[0];

  const getClass = (col: number) => (relevantNum === col ? " highlight" : "");

  return (
    <div className="pf-calculation__final-result">
      <div className="pf-calculation__final-result__options">
        {testimonialsData.map((test, index) => (
          <div key={index} className={"pf-calculation__final-result__option" + getClass(index)}>
            <div><p>{index}</p></div>
            <div><p>{test.id}</p></div>
          </div>
        ))}
      </div>
    </div>
  );
}

function PlinkoResult({ plinkoResult }: { plinkoResult: number | null }) {
  if (plinkoResult == null) {
    return <div>Enter valid Random Hash</div>;
  }
  
  return (
    <div className="pf-section--verify__plinko-card">
      <PlinkoCard result={plinkoResult} />
    </div>
  );
}

function RouletteResult({ rouletteResult }: { rouletteResult: number | null }) {
  if (rouletteResult == null) {
    return <div>Enter valid Random Hash</div>;
  }

  return (
    <div style={{width: "50em"}}>
      <TestimonialCard testimonialObject={testimonialsData[rouletteResult]} isCenter={true} />
    </div>
  );
}

export function VerifySection(
  {
    _request = "",
    _proof = "",
    _randomHash = "",
    _selectedGame = "plinko"
  }: {
    _request?: string;
    _proof?: string;
    _randomHash?: string;
    _selectedGame?: "plinko" | "roulette";
  } = {} // Default to an empty object
) {
  // function body
  const [publicKey, setPublicKey] = useState(
    "03563449d50f16f54a4aa65465855aaa69650d48c3feeb517e099caf8bdf8fdd5c"
  );
  const [request, setRequest] = useState(_request);
  const [proof, setProof] = useState(_proof);
  const [randomHash, setRandomHash] = useState(_randomHash);
  const [selectedGame, setSelectedGame] = useState<"plinko" | "roulette">(_selectedGame);

  const [bytes, setBytes] = useState<Uint8Array | null>(null);
  const [numberResults, setNumberResults] = useState<number[] | null>(null);

  const [randomNumRange, setRandomNumRange] = useState<number>(2);

  const [plinkoResult, setPlinkoResult] = useState<number | null>(null);
  const [rouletteResult, setRouletteResult] = useState<number | null>(null);

  const allFieldsFilled = publicKey && request && proof && randomHash;
  let fairnessMessage: JSX.Element | null = null;
  let fairnessClass = "";

  const isVerified = useMemo(() => {
    return verifyInputs(publicKey, request, proof, randomHash);
  }, [publicKey, request, proof, randomHash]);

  if (!allFieldsFilled) {
    // Warning: user hasn't filled all fields
    fairnessMessage = (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path d="M1 21L12 2L23 21H1ZM4.45 19H19.55L12 6L4.45 19ZM12 18C12.2833 18 12.5208 17.9042 12.7125 17.7125C12.9042 17.5208 13 17.2833 13 17C13 16.7167 12.9042 16.4792 12.7125 16.2875C12.5208 16.0958 12.2833 16 12 16C11.7167 16 11.4792 16.0958 11.2875 16.2875C11.0958 16.4792 11 16.7167 11 17C11 17.2833 11.0958 17.5208 11.2875 17.7125C11.4792 17.9042 11.7167 18 12 18ZM11 15H13V10H11V15Z" fill="currentColor"/>
        </svg>
        <p>Fill out all the fields above</p>
      </>
    );
    fairnessClass = "pf-section--verify__vrf--warning";
  } else {
    // All fields filled, check if they're valid
    if (isVerified) {
      fairnessMessage = (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path d="M10.95 15.55L16.6 9.9L15.175 8.475L10.95 12.7L8.85 10.6L7.425 12.025L10.95 15.55ZM12 22C9.68333 21.4167 7.77083 20.0875 6.2625 18.0125C4.75417 15.9375 4 13.6333 4 11.1V5L12 2L20 5V11.1C20 13.6333 19.2458 15.9375 17.7375 18.0125C16.2292 20.0875 14.3167 21.4167 12 22ZM12 19.9C13.7333 19.35 15.1667 18.25 16.3 16.6C17.4333 14.95 18 13.1167 18 11.1V6.375L12 4.125L6 6.375V11.1C6 13.1167 6.56667 14.95 7.7 16.6C8.83333 18.25 10.2667 19.35 12 19.9Z" fill="currentColor"/>
          </svg>
          <p>Provable Fairness verified</p>
        </>
      );
      fairnessClass = "pf-section--verify__vrf--verified";
    } else {
      fairnessMessage = (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16C13 15.7167 12.9042 15.4792 12.7125 15.2875C12.5208 15.0958 12.2833 15 12 15C11.7167 15 11.4792 15.0958 11.2875 15.2875C11.0958 15.4792 11 15.7167 11 16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM11 13H13V7H11V13ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="currentColor"/>
          </svg>
          <p>Provable Fairness not verified</p>
        </>
      );
      fairnessClass = "pf-section--verify__vrf--unverified";
    }
  }


  useEffect(() => {
    if (selectedGame === "plinko") {
      setRandomNumRange(2);
    }
    if (selectedGame === "roulette") {
      setRandomNumRange(testimonialsData.length);
    }
  }, [selectedGame]);

  // Compute 32-byte BLAKE2b hash when randomHash changes
  useEffect(() => {
      const hashBytes = blake.blake2b(randomHash, undefined, 32);
      setBytes(hashBytes);
  }, [randomHash]);

  // Convert bytes to final number
  useEffect(() => {
    if (bytes) {
      const fourByteChunks = chunkBytes(bytes, 4);

      const _numberResults = [];

      for (let i = 0; i < fourByteChunks.length; i++) {
        _numberResults.push(bytesToNumber(fourByteChunks[i], randomNumRange).finalNumber)
      }

      setNumberResults(_numberResults);
    } else {
      setNumberResults(null);
    }
  }, [bytes, randomNumRange]);

  useEffect(() => {
    if (selectedGame === "plinko") {
      if (numberResults) {
        const relevantNumResults = numberResults?.slice(0, 5);
        const finalResult = Math.floor(relevantNumResults.reduce((partialSum, a) => partialSum + a, 0) / 2);
      
        setPlinkoResult(finalResult);
      } else {
        setPlinkoResult(null);
      }
    } else {
      if (numberResults) {
        setRouletteResult(numberResults[0]);
      } else {
        setRouletteResult(null);
      }
    }
  }, [numberResults, selectedGame]);

  return (
    <div className="pf-section pf-section--verify">
      <div className="pf-section__title pf-section__title--verify">
        <h1>VERIFY FAIRNESS</h1>
      </div>

      <div className="pf-section__sub-title pf-section__sub-title--verify">
        <h2>1. Verify Randomness</h2>
      </div>

      <div className="pf-section--verify__fields">
        <div className="pf-section--verify__field">
          <label>
            <p>Public Key</p>
          </label>
          <div className="pf-section--verify__field__input-group">
            <input
              type="text"
              placeholder="Enter request ID"
              value={publicKey}
              onChange={(e) => setPublicKey(e.target.value)}
            />
          </div>
        </div>

        <div className="pf-section--verify__field">
          <label>
            <p>Request</p>
          </label>
          <div className="pf-section--verify__field__input-group">
            <input
              placeholder="Enter Request"
              value={request}
              onChange={(e) => setRequest(e.target.value)}
            />
          </div>
        </div>

        <div className="pf-section--verify__field">
          <label>
            <p>Proof</p>
          </label>
          <div className="pf-section--verify__field__input-group">
            <input
              type="text"
              placeholder="Enter Proof"
              value={proof}
              onChange={(e) => setProof(e.target.value)}
            />
          </div>
        </div>

        <div className="pf-section--verify__field">
          <label>
            <p>Random Hash</p>
          </label>
          <div className="pf-section--verify__field__input-group">
            <input
              type="text"
              placeholder="Enter Random Hash"
              value={randomHash}
              onChange={(e) => setRandomHash(e.target.value)}
            />
          </div>
        </div>

        {/* Display the fairness message here */}
        <div className="pf-section--verify__field">
          <label></label>
          <div className={"pf-section--verify__message " + fairnessClass}>{fairnessMessage}</div>
        </div>

        <div className="pf-section__sub-title pf-section__sub-title--verify">
          <h2>2. Verify Result</h2>
        </div>

        <div className="pf-section--verify__field">
          <label>
            <p>Game</p>
          </label>
          <div className="pf-section--verify__field__select">
            <select
              value={selectedGame}
              onChange={(e) =>
                setSelectedGame(e.target.value as "plinko" | "roulette")
              }
            >
              <option value="plinko">Gem Plinko</option>
              <option value="roulette">Testimonial Roulette</option>
            </select>
            <svg className="pf-section--verify__field__select-arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 15.4L6 9.4L7.4 8L12 12.6L16.6 8L18 9.4L12 15.4Z" fill="#E3E3E3"/>
            </svg>
          </div>
        </div>

        <div className="pf-section--verify__field">
          <label>
            <p>Seed</p>
          </label>
          <div className="pf-section--verify__field__input-group">
            <input
              type="text"
              placeholder="Enter Seed (Same as random hash from VRF)"
              value={randomHash}
              onChange={(e) => setRandomHash(e.target.value)}
            />
          </div>
        </div>

        <div className="pf-section--verify__field">
          <label>
            <p>Result</p>
          </label>
          <div className="pf-section--verify__game-result">
            { selectedGame === "plinko" && <PlinkoResult plinkoResult={plinkoResult} />}
            { selectedGame === "roulette" && <RouletteResult rouletteResult={rouletteResult} />}
          </div>
        </div>

        <div className="pf-section--verify__field">
          <label>
            <p>Calculation</p>
          </label>
          <div className="pf-calculation">
            <div className="pf-calculation__section">
              {/* Suggestion: rename pf-calculation__header to something like 
                  "pf-calculation__section-title" for clarity */}
              <div className="pf-calculation__header">
                <p>Seed to Bytes</p>
              </div>
              <div className="pf-calculation__table-container">
                <SeedToBytesTable randomHash={randomHash} hashBytes={bytes} />
              </div>
            </div>

            <div className="pf-calculation__section">
              <div className="pf-calculation__header">
                <p>Bytes to Numbers</p>
              </div>
              <div className="pf-calculation__table-container">
                <BytesToNumberTable randomNumRange={randomNumRange} hashBytes={bytes} />
              </div>
            </div>

            <div className="pf-calculation__section">
              <div className="pf-calculation__header">
                <p>Final Result</p>
              </div>
              { selectedGame === "plinko" && <PlinkoFinalResultTable numberResults={numberResults} />}
              { selectedGame === "roulette" && <RouletteFinalResultTable numberResults={numberResults} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
