import React, { ReactNode } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import './PinkScroll.scss';

interface CustomScrollAreaProps {
  children: ReactNode;
}

const PinkScroll: React.FC<CustomScrollAreaProps> = ({ children }) => {
  return (
    <SimpleBar
      autoHide={false}
      style={{ width: '100%', height: '100%' }}
      className='pink-scroll'
    >
      {children}
    </SimpleBar>
  );
};

export default PinkScroll;
