import { useEffect, useMemo, useState } from "react";
import "./ProvablyFair.scss";
import { TzTable, TzTableHeader } from "../../components/tz table/TzTable";
import { useBetHistory } from "../../BetHistoryStateProvider";
import { useGetBetHistoryQuery } from "../../state/api";
import { GameData } from "../../models/GameData";
import { HistorySection } from "./history section/HistorySection";
import { VerifySection } from "./verify section/VerifySection";

import * as jwt_decode from 'jwt-decode';
import { useAuth } from "../../AuthContext";
import PinkScroll from "../../components/pink scroll/PinkScroll";
import { VerifyModal } from "./verify modal/VerifyModal";
import SimpleBar from "simplebar-react";
import { plinkoData } from "../home/components/plinko game/PlinkoGame";
import { testimonialsData } from "../home/components/testimonials/Testimonials";


export function formatLongInfo(str: string, start: number = 5, end: number = 5) {
  if (str.length <= start + end) return str;
  return `${str.slice(0, start)}...${str.slice(-end)}`;
}

export const formatUnixTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  const datePart = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  const timePart = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });
  return `${datePart}, ${timePart}`;
};

function CopyButton({ textToCopy }: { textToCopy: string }) {
  const copyText = () => {
    // Modern browsers using the Clipboard API
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          //console.log("Text copied to clipboard!");
          // Optionally, provide user feedback here
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      // Fallback for older browsers using execCommand
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // Avoid scrolling to bottom
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        //console.log("Text copied to clipboard!");
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <div className="button provably-fair__key-info__copy" onClick={copyText}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M6 11.9999C5.63333 11.9999 5.31944 11.8694 5.05833 11.6083C4.79722 11.3471 4.66667 11.0333 4.66667 10.6666V2.66659C4.66667 2.29992 4.79722 1.98603 5.05833 1.72492C5.31944 1.46381 5.63333 1.33325 6 1.33325H12C12.3667 1.33325 12.6806 1.46381 12.9417 1.72492C13.2028 1.98603 13.3333 2.29992 13.3333 2.66659V10.6666C13.3333 11.0333 13.2028 11.3471 12.9417 11.6083C12.6806 11.8694 12.3667 11.9999 12 11.9999H6ZM6 10.6666H12V2.66659H6V10.6666ZM3.33333 14.6666C2.96667 14.6666 2.65278 14.536 2.39167 14.2749C2.13056 14.0138 2 13.6999 2 13.3333V3.99992H3.33333V13.3333H10.6667V14.6666H3.33333Z" fill="#E8EAED"/>
      </svg>
    </div>
  );
}

function UpdateClientSeedModal({ isOpen, onClose, currentSeed, onUpdate }: { isOpen: boolean, onClose: () => void, currentSeed: string, onUpdate: (newSeed: string) => void }) {
  const [newSeed, setNewSeed] = useState(currentSeed || "");
  const isValid = /^.{0,32}$/.test(newSeed);


  const handleUpdate = () => {
    //console.log("newSeed", newSeed)
    // Update localStorage and parent state with the new seed
    localStorage.setItem('clientSeed', newSeed);
    onUpdate(newSeed);
    onClose();
  };

  if (!isOpen) return null;

  const handleOuterOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOuterOverlayClick}>
      <div className="client-seed-modal">
        <div className="client-seed-modal__header">
          <p>Update Client Seed</p>
          <svg className="button" onClick={onClose} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none">
            <path d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z" fill="white"/>
          </svg>
        </div>
        <div className="client-seed-modal__content">
          <input
              className="client-seed-modal__input"
              type="text"
              value={newSeed}
              onChange={(e) => setNewSeed(e.target.value)}
              placeholder="Enter new client seed"
              pattern="^.{0,32}$"
            />
            <p className="client-seed-modal__message">
              Maximum 32 characters
            </p>
        </div>

          {/* <button onClick={onClose}>Cancel</button> */}
          <button disabled={!isValid} className="client-seed-modal__update" onClick={handleUpdate}><p>Update</p></button>
      </div>
    </div>
  );
}

function MainSection() {
  return (
    <div className="pf-section pf-section--main">
      <div className="pf-section__title">
        <h1>PROVABLY FAIR</h1>
      </div>
      <div className="pf-section--main__content">
        <div className="pf-main__paragraph">
          <div className="pf-section__sub-title">
            <h2>Provably Fair Gaming</h2>
          </div>
          <div className="pf-main__desc">
            <p>Provably fair gaming utilises cryptographic techniques in order to ensure each result is random and unable to be tampered with by the house. Players choose a client seed, allowing them to contribute their own input into the process of generating outcomes. This two-way mechanism prevents either the casino or the player from having all the control or prior knowledge of results and enables transparent verification of the fairness.</p>
          </div>
        </div>
        <div className="pf-main__paragraph">
          <div className="pf-section__sub-title">
            <h2>Why Provably Fair Matters</h2>
          </div>
          <div className="pf-main__desc">
            <p>Players deserve to have a secure and transparent experience when playing games, especially when their money is involved, and that is where the significance of provable fairness comes in. By using cryptographic proofs, the days of relying solely on the house’s word are gone - users can verify every bet, creating a space where the operator can prove their integrity, and players can feel assured.</p>
          </div>
        </div>
        <div className="pf-main__paragraph">
          <div className="pf-section__sub-title">
            <h2>How Provably Fair Works</h2>
          </div>
          <div className="pf-main__desc">
            <p>Behind each bet is a verifiable algorithm that merges the player’s client seed and the operator’s cryptographic inputs. Although there are many methods that can be used to do this, the fundamentals remain the same - the final outcome is generated by several independent variables that together cannot be exploited by either party. After a round is over, players can confirm that no manipulation took place by looking at the verifiable proof. This could involve checking the operators commitment (hashed server seed, future blockchain block hash, etc) is consistent, proofs supplied by a VRF match up with the input and output, or anything cryptographically provable.</p>
          </div>
        </div>
        <div className="pf-main__paragraph">
          <div className="pf-section__sub-title">
            <h2>Advantages Over Traditional Models</h2>
          </div>
          <div className="pf-main__desc">
            <p>Conventional systems rely heavily on third-party audits or simply the goodwill of the operator, meaning that players receive little transparency or insight into how results are generated. Provably fair models, however, ensure that users can have a direct influence on the process with their client seed, as well as verify each outcome independently. This approach allows players to feel confident and in control of their experience. Overall, a provably fair approach cultivates a healthier, more rewarding online gambling environment - one where fairness is not just a promise, but a provable fact.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function ImplementationSection() {
  return (
    <div className="pf-section pf-section--implementation">
      <div className="pf-section__title">
        <h1>IMPLEMENTATION</h1>
      </div>
      <div className="pf-section--main__content">
        <div className="pf-main__paragraph">
          <div className="pf-section__sub-title">
            <h2>1. Using a Verifiable Random Function</h2>
          </div>
          <div className="pf-main__desc">
            <p>
              We generate initial randomness with an Elliptic Curve Verifiable Random Function (VRF) based on RFC 9381. Specifically, our implementation uses the SECP256K1_SHA256_TAI cipher suite.
            </p>
            <br></br>
            <p>
              Each request to the VRF includes: User ID, Game Name, Nonce, Optional Client Seed. The request string format is:
            </p>
            <br></br>
            <pre><code>request = `${'{userId}'}+${'{gameName}'}+${'{nonce}'}+${'{clientSeed}'}`</code></pre>
            <br></br>
            <p>
              The VRF returns two pieces of data:
            </p>
            <ul>
              <li>VRF Output: A pseudo-random 32-byte hash which is cryptographically tied to the inputs.</li>
              <li>VRF Proof: A proof that anyone can use alongside our public key to confirm the VRF output is valid.</li>
            </ul>
          </div>
        </div>
        <div className="pf-main__paragraph">
          <div className="pf-section__sub-title">
            <h2>2. Seed to Bytes</h2>
          </div>
          <div className="pf-main__desc">
            <p>
              After receiving the VRF Output, we apply Blake2b with a length of 32.
            </p>
          </div>
        </div>
        <div className="pf-main__paragraph">
          <div className="pf-section__sub-title">
            <h2>3. Bytes to Number</h2>
          </div>
          <div className="pf-main__desc">
            <p>
              We chunk the result into groups of 4 bytes and use them as the basis for each random number generation.
            </p>
            <br></br>
            <p>
            Treat these bytes as digits in a base 256 fraction:
            </p>
            <br></br>
            <pre><code>uniform_float = (b1 / 256^1) + (b2 / 256^2) + (b3 / 256^3) + (b4 / 256^4)</code></pre>
            <br></br>
            <p>
              This produces a uniform floating point number in the range [0, 1).
            </p>
          </div>
        </div>
        <div className="pf-main__paragraph">
          <div className="pf-section__sub-title">
            <h2>Gem Plinko</h2>
          </div>
          <div className="pf-main__desc">
            <p>
              For Gem Plinko, there are 5 pins requiring 5 number generations to determine the path from top to bottom.
            </p>
            <br></br>
            <p>
              Each generated number is then mapped to a direction — 0 for left and 1 for right — by multiplying the float by 2.
            </p>
            <br></br>
            <p>
              The directions are added to get the final position and divided by 2 to translate to a corresponding card using the following indexing:
            </p>
            <br></br>
            <pre><code>
              // Index of 0 to {plinkoData.length - 1} : {plinkoData[0].result} to {plinkoData[plinkoData.length - 1].result}<br></br>
              const TESTIMONIALS = [ {plinkoData.map(item => `"${item.result}"`).join(", ")} ];
            </code></pre>
          </div>
        </div>
        <div className="pf-main__paragraph">
          <div className="pf-section__sub-title">
            <h2>Testimonial Roulette</h2>
          </div>
          <div className="pf-main__desc">
            <p>
              For Testimonial Roulette, there are {testimonialsData.length} possible outcomes in the form of testimonials.
            </p>
            <br></br>
            <p>
              The first generated number is mapped to an outcome index by multipling the float by {testimonialsData.length}.
            </p>
            <br></br>
            <p>
              The outcome index is then translated to a corresponding testimonial using the following indexing:
            </p>
            <br></br>
            <pre><code>
              // Index of 0 to {testimonialsData.length - 1} : {testimonialsData[0].id} to {testimonialsData[testimonialsData.length - 1].id}<br></br>
              const TESTIMONIALS = [ {testimonialsData.map(item => `"${item.id}"`).join(", ")} ];
            </code></pre>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ProvablyFair({toggleModal}: {toggleModal: () => void}) {
  const existingClientSeed = localStorage.getItem('clientSeed');
  if (!existingClientSeed) {
  localStorage.setItem('clientSeed', "");
  }

  const publicKey = "03563449d50f16f54a4aa65465855aaa69650d48c3feeb517e099caf8bdf8fdd5c";
  const { userId } = useAuth();

  const [clientSeed, setClientSeed] = useState(localStorage.getItem('clientSeed') || "");
  const [isClientSeedModalOpen, setIsClientSeedModalOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("history");

  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [selectedBet, setSelectedBet] = useState<GameData | null>(null);

  const handleOuterOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      toggleModal();
    }
  };

  function PFMenu() {
    return (
      <div className="provably-fair__menu">
        {/* Provably Fair Menu Item */}
        <div
          className="button provably-fair__menu-item"
          onClick={() => setSelectedMenuItem("provablyFair")}
        >
          <div className="provably-fair__menu-item__icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18" fill="#fff">
              <path
                d="M9.12498 12.9596L13.8333 8.2513L12.6458 7.0638L9.12498 10.5846L7.37498 8.83463L6.18748 10.0221L9.12498 12.9596ZM9.99998 18.3346C8.06942 17.8485 6.47567 16.7409 5.21873 15.0117C3.96179 13.2826 3.33331 11.3624 3.33331 9.2513V4.16797L9.99998 1.66797L16.6666 4.16797V9.2513C16.6666 11.3624 16.0382 13.2826 14.7812 15.0117C13.5243 16.7409 11.9305 17.8485 9.99998 18.3346ZM9.99998 16.5846C11.4444 16.1263 12.6389 15.2096 13.5833 13.8346C14.5278 12.4596 15 10.9319 15 9.2513V5.3138L9.99998 3.4388L4.99998 5.3138V9.2513C4.99998 10.9319 5.4722 12.4596 6.41665 13.8346C7.36109 15.2096 8.55553 16.1263 9.99998 16.5846Z"
                fillOpacity={selectedMenuItem === "provablyFair" ? 1 : 0.5}
              />
            </svg>
          </div>
          <div
            className="provably-fair__menu-item__text"
            style={{ opacity: selectedMenuItem === "provablyFair" ? 1 : 0.5 }}
          >
            Provably Fair
          </div>
        </div>

        {/* Implementation Menu Item */}
        <div
          className="button provably-fair__menu-item"
          onClick={() => setSelectedMenuItem("implementation")}
        >
          <div className="provably-fair__menu-item__icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22" fill="#fff">
            <path d="M17.15 20.7L11.1 14.6C10.7667 14.7333 10.4292 14.8333 10.0875 14.9C9.74583 14.9667 9.38333 15 9 15C7.33333 15 5.91667 14.4167 4.75 13.25C3.58333 12.0833 3 10.6667 3 9C3 8.4 3.08333 7.82917 3.25 7.2875C3.41667 6.74583 3.65 6.23333 3.95 5.75L7.6 9.4L9.4 7.6L5.75 3.95C6.23333 3.65 6.74583 3.41667 7.2875 3.25C7.82917 3.08333 8.4 3 9 3C10.6667 3 12.0833 3.58333 13.25 4.75C14.4167 5.91667 15 7.33333 15 9C15 9.38333 14.9667 9.74583 14.9 10.0875C14.8333 10.4292 14.7333 10.7667 14.6 11.1L20.7 17.15C20.9 17.35 21 17.5917 21 17.875C21 18.1583 20.9 18.4 20.7 18.6L18.6 20.7C18.4 20.9 18.1583 21 17.875 21C17.5917 21 17.35 20.9 17.15 20.7ZM17.875 18.575L18.55 17.9L12.15 11.5C12.45 11.1667 12.6667 10.7792 12.8 10.3375C12.9333 9.89583 13 9.45 13 9C13 8 12.6792 7.12917 12.0375 6.3875C11.3958 5.64583 10.6 5.2 9.65 5.05L11.5 6.9C11.7 7.1 11.8 7.33333 11.8 7.6C11.8 7.86667 11.7 8.1 11.5 8.3L8.3 11.5C8.1 11.7 7.86667 11.8 7.6 11.8C7.33333 11.8 7.1 11.7 6.9 11.5L5.05 9.65C5.2 10.6 5.64583 11.3958 6.3875 12.0375C7.12917 12.6792 8 13 9 13C9.43333 13 9.86667 12.9333 10.3 12.8C10.7333 12.6667 11.125 12.4583 11.475 12.175L17.875 18.575Z"
                fillOpacity={selectedMenuItem === "implementation" ? 1 : 0.5}
              />
            </svg>
          </div>
          <div
            className="provably-fair__menu-item__text"
            style={{ opacity: selectedMenuItem === "implementation" ? 1 : 0.5 }}
          >
            Implementation
          </div>
        </div>

        {/* History Menu Item */}
        <div
          className="button provably-fair__menu-item"
          onClick={() => setSelectedMenuItem("history")}
        >
          <div className="provably-fair__menu-item__icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="#fff">
              <path
                d="M10 17.5C8.08333 17.5 6.41319 16.8646 4.98958 15.5938C3.56597 14.3229 2.75 12.7361 2.54167 10.8333H4.25C4.44444 12.2778 5.08681 13.4722 6.17708 14.4167C7.26736 15.3611 8.54167 15.8333 10 15.8333C11.625 15.8333 13.0035 15.2674 14.1354 14.1354C15.2674 13.0035 15.8333 11.625 15.8333 10C15.8333 8.375 15.2674 6.99653 14.1354 5.86458C13.0035 4.73264 11.625 4.16667 10 4.16667C9.04167 4.16667 8.14583 4.38889 7.3125 4.83333C6.47917 5.27778 5.77778 5.88889 5.20833 6.66667H7.5V8.33333H2.5V3.33333H4.16667V5.29167C4.875 4.40278 5.73958 3.71528 6.76042 3.22917C7.78125 2.74306 8.86111 2.5 10 2.5C11.0417 2.5 12.0174 2.69792 12.9271 3.09375C13.8368 3.48958 14.6285 4.02431 15.3021 4.69792C15.9757 5.37153 16.5104 6.16319 16.9062 7.07292C17.3021 7.98264 17.5 8.95833 17.5 10C17.5 11.0417 17.3021 12.0174 16.9062 12.9271C16.5104 13.8368 15.9757 14.6285 15.3021 15.3021C14.6285 15.9757 13.8368 16.5104 12.9271 16.9062C12.0174 17.3021 11.0417 17.5 10 17.5ZM12.3333 13.5L9.16667 10.3333V5.83333H10.8333V9.66667L13.5 12.3333L12.3333 13.5Z"
                fillOpacity={selectedMenuItem === "history" ? 1 : 0.5}
              />
            </svg>
          </div>
          <div
            className="provably-fair__menu-item__text"
            style={{ opacity: selectedMenuItem === "history" ? 1 : 0.5 }}
          >
            History
          </div>
        </div>

        {/* Verify Menu Item */}
        <div
          className="button provably-fair__menu-item"
          onClick={() => setSelectedMenuItem("verify")}
        >
          <div className="provably-fair__menu-item__icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="#fff">
              <path
                d="M8.83335 13.8346L14.7084 7.95964L13.5417 6.79297L8.83335 11.5013L6.45835 9.1263L5.29169 10.293L8.83335 13.8346ZM10 18.3346C8.84724 18.3346 7.76391 18.1159 6.75002 17.6784C5.73613 17.2409 4.85419 16.6471 4.10419 15.8971C3.35419 15.1471 2.76044 14.2652 2.32294 13.2513C1.88544 12.2374 1.66669 11.1541 1.66669 10.0013C1.66669 8.84852 1.88544 7.76519 2.32294 6.7513C2.76044 5.73741 3.35419 4.85547 4.10419 4.10547C4.85419 3.35547 5.73613 2.76172 6.75002 2.32422C7.76391 1.88672 8.84724 1.66797 10 1.66797C11.0417 1.66797 12.0174 1.88672 12.9271 2.32422C13.8368 2.76172 14.6285 3.35547 15.3021 4.10547C15.9757 4.85547 16.5104 5.73741 16.9062 6.7513C17.3021 7.76519 17.5 8.84852 17.5 10.0013C17.5 11.1541 17.3021 12.2374 17.6771 13.2513C17.2396 14.2652 16.6459 15.1471 15.8959 15.8971C15.1459 16.6471 14.2639 17.2409 13.25 17.6784C12.2361 18.1159 11.1528 18.3346 10 18.3346ZM10 16.668C11.8611 16.668 13.4375 16.0221 14.7292 14.7305C16.0209 13.4388 16.6667 11.8624 16.6667 10.0013C16.6667 8.14019 16.0209 6.5638 14.7292 5.27214C13.4375 3.98047 11.8611 3.33464 10 3.33464C8.13891 3.33464 6.56252 3.98047 5.27085 5.27214C3.97919 6.5638 3.33335 8.14019 3.33335 10.0013C3.33335 11.8624 3.97919 13.4388 5.27085 14.7305C6.56252 16.0221 8.13891 16.668 10 16.668Z"
                fillOpacity={selectedMenuItem === "verify" ? 1 : 0.5}
              />
            </svg>
          </div>
          <div
            className="provably-fair__menu-item__text"
            style={{ opacity: selectedMenuItem === "verify" ? 1 : 0.5 }}
          >
            Verify Fairness
          </div>
        </div>
      </div>
    )
  }

  function KeyInfos() {
    return (
      <div className="provably-fair__key-infos">
        <div className="provably-fair__key-info">
          <div className="provably-fair__key-info__label">
            <p>Client Seed</p>              
            <div 
              className="button provably-fair__key-info__update"
              onClick={() => setIsClientSeedModalOpen(true)}
            >
              Edit
            </div>
          </div>
          <div className="provably-fair__key-info__box">
            <div
              className="provably-fair__key-info__value"
              style={!clientSeed ? { color: 'grey' } : {}}
            >
              {(!clientSeed || clientSeed === "") ? "None" : clientSeed}
            </div>
            <CopyButton textToCopy={(!clientSeed || clientSeed === "") ? "" : clientSeed} />
          </div>
        </div>
        <div className="provably-fair__key-info">
          <div className="provably-fair__key-info__label"><p>Public Key</p></div>
          <div className="provably-fair__key-info__box">
            <div className="provably-fair__key-info__value">
              {publicKey}
            </div>
            <CopyButton textToCopy={publicKey} />
          </div>
        </div>
        <div className="provably-fair__key-info">
          <div className="provably-fair__key-info__label"><p>User Id</p></div>
          <div className="provably-fair__key-info__box">
            <div className="provably-fair__key-info__value">
              {userId ?? "Loading..."} 
            </div>
            <CopyButton textToCopy={userId ?? ""} />
          </div>
        </div>
    </div>
    )
  }

  function CloseButton() {
    return (
      <svg className="button provably-fair__close-button" onClick={toggleModal} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none">
        <path d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z" fill="white"/>
      </svg>
    )
  }

  return (
    <div className="modal-overlay" onClick={handleOuterOverlayClick}>
      <div className="desktop">
        <div className="provably-fair">
          <div className="provably-fair__left">
            <PFMenu />
            <KeyInfos />
          </div>
          <div className="provably-fair__right">
            <CloseButton />
            <SimpleBar
              autoHide={false}
              style={{ width: '100%', height: '100%' }}
              className="provably-fair__scroll"
            >
              <div className="provably-fair__content">
                {selectedMenuItem === "provablyFair" && <MainSection />}
                {selectedMenuItem === "implementation" && <ImplementationSection />}
                {selectedMenuItem === "history" && <HistorySection setSelectedBet={setSelectedBet} openVerifyModal={() => setIsVerifyModalOpen(true)} />}
                {selectedMenuItem === "verify" && <VerifySection />}
              </div>
            </SimpleBar>
          </div>
        </div>
      </div>

      <div className="mobile">
        <div className="provably-fair">
          <CloseButton />
            <div className="provably-fair__left">
              <PFMenu />
              <KeyInfos />
            </div>
            <div className="provably-fair__right">
              <div className="provably-fair__content">
                {selectedMenuItem === "provablyFair" && <MainSection />}
                {selectedMenuItem === "implementation" && <ImplementationSection />}
                {selectedMenuItem === "history" && <HistorySection setSelectedBet={setSelectedBet} openVerifyModal={() => setIsVerifyModalOpen(true)}/>}
                {selectedMenuItem === "verify" && <VerifySection />}
              </div>
            </div>
        </div>
      </div>

      {/* Second modal for updating the client seed */}
      <UpdateClientSeedModal
        isOpen={isClientSeedModalOpen}
        onClose={() => setIsClientSeedModalOpen(false)}
        currentSeed={clientSeed}
        onUpdate={(newSeed) => setClientSeed(newSeed)}
      />

      <VerifyModal
        isOpen={isVerifyModalOpen}
        onClose={() => setIsVerifyModalOpen(false)}
        selectedBet={selectedBet}
      />
    </div>
  );
}