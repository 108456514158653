import { Logo } from "../../../components/Logo";
import "./CreatorCTA.scss";

export function CreatorCTA() {
  return (
    <div className="creator-cta">
      <div className="creator-cta__left">
        <div className="creator-cta__title">
          <h1>THE TANZANITE STANDARD</h1>
        </div>
        <div className="creator-cta__desc">
          <p>Are you an influencer and do you value transparency for your viewers? Apply today and become a part of the Tanzanite Standard.</p>
        </div>
        <a href={"https://forms.gle/YFuHcLkjGXx7w9QN7"} target="_blank" rel="noopener noreferrer">
          <div className="button creator-cta__button">
            <p>Join the program</p>
          </div>
        </a>
      </div>

      <div className="creator-cta__right">
        <Logo />
      </div>
    </div>
  )
}