import { NavLink } from "react-router-dom";
import { GhostPost } from "../../models/BlogData";
import "./BlogCard.scss";

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(date);
};

export function BlogCard({post}: {post: GhostPost}) {

  let blogType = post.title.toLowerCase().includes("promos") ? "Promos" : post.title.toLowerCase().includes("igaming") ? "Weekly" : "";


  return (
    <NavLink 
      key={post.id}
      to={'/blog/' + post.slug}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <div className="button blog-card">
        <div className="blog-card__img">
          <img src={post.feature_image || ''} alt={post.title} />
        </div>
        <div className="blog-card__content">
          <div className="blog-card__title">
            {blogType === "Weekly" && (
              <p>
                iGaming <span style={{color: "#FF76E9"}}>Weekly</span>
              </p>
            )}
            {blogType === "Promos" && (
              <p>
                Weekly <span style={{color: "#34F467"}}>Promos</span>
              </p>
            )}
            {blogType !== "Weekly" && blogType !== "Promos" && (
              <p>
                {post.title.split('|')[0]}
              </p>
            )}
          </div>
          <div className="blog-card__date">
            <p>{formatDate(post.published_at)}</p>
          </div>
        </div>
        <div className="blog-card__footer">
          <p>{post.excerpt}</p>
        </div>
      </div>
    </NavLink>
  )
}