import styles from './BlogPost.module.scss';
import { useGetBlogQuery } from '../../state/api';
import { useParams } from 'react-router-dom';
import { Header } from '../../components/header/Header';
import { ErrorMessage } from '../../components/middleware states/ErrorMessage';
import { LoadingSpinner } from '../../components/middleware states/LoadingAnimation';


const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  }).format(date);
};

export default function BlogPost() {
  const { slug } = useParams(); // Get slug from the URL
  const { data: post, isLoading, isError } = useGetBlogQuery(slug ?? "");

  if (isError) {
    return (
      <div className={styles.blog}>
        <div className={styles.blogPost}>
        <ErrorMessage message="There was an error loading blog" />
        </div>
      </div>
    );
  }

  if (isLoading || !post) {
    return (
      <div className={styles.blog}>
        <div className={styles.blogPost}>
         <LoadingSpinner />
        </div>
      </div>
    );
  }

  // Format the publish date
  const formattedDate = formatDate(post.published_at);

  return (
    <div className={styles.blog}>
      <div className={styles.blogPost}>
        <Header title={post.title.split("|")[0].toLocaleUpperCase()} desc={formattedDate} />
        <div className={styles.blogContent}>
          {post.feature_image && (
            <img src={post.feature_image} alt={post.title} className={styles.featureImage} />
          )}
          <div className={styles.content}>
            <div dangerouslySetInnerHTML={{ __html: post.html as any }} />
          </div>
        </div>
      </div>
    </div>
  );
}