import React, { useMemo, useState } from 'react';
import { Creator, CreatorData, Icons } from '../../../models/CreatorsData';
import "./CreatorOverview.scss";
import { TzTable, TzTableHeader, TzTableRow } from '../../../components/tz table/TzTable';
import { NavLink } from 'react-router-dom';
import { CreatorLevelBadge } from '../CreatorsPage';

export function CreatorOverview({ creatorsData }: { creatorsData: CreatorData }) {
  const [activeTable, setActiveTable] = useState("Streamers");
  const [searchQuery, setSearchQuery] = useState("");

  const headers: TzTableHeader[] = [
    { label: <p>#</p>, align: "left", width: "7.5%" },
    { label: <p>Creator</p>, align: "left", width: "17.5%", mobileWidth: "10%" },
    { label: <><div className="desktop"><p>Verification</p></div><div className="mobile"><p>Level</p></div></>, align: "center", sortable: true, width: "22%", mobileWidth: "7%" },
    { label: <p>Casino</p>, align: "center", sortable: true, width: "20%", mobileWidth: "0%", hideOnMobile: true },

    { label: <p>Followers</p>, align: "center", sortable: true, width: "20%", hideOnMobile: true },
    { label: <p>Profile</p>, align: "right", mobileWidth: "5%" },
  ];

  const { streamerRows, creatorRows } = useMemo(() => {
    const streamerRows: TzTableRow[] = creatorsData.creators.filter(x => x.streamerDeal !== null).map(x => {
      const deal = x.streamerDeal;
      const dealInfo = x.deals.find(x => x.media === "Streamer" && x.type == "Current");
      if (!deal || !dealInfo) return {} as any;

      return {
        key: "streamer-" + x.name,
        cells: [
          {
            content: <p>{deal.rank}</p>,
            sortValue: deal.rank
          },
          {
            content: 
              <div className='creator-table__creator-cell'>
                <img src={x.pfp} />
                <p>{x.name}</p>
                {dealInfo.verificationLevel > 0 && dealInfo.payments.filter(x => x.icon === "Raw").length > 0 ? <div className='creator-table__creator-pill'><p>Raw</p></div> : <></>}
              </div>,
            sortValue: x.name
          },
          {
            content: 
            <div className='creator-table__verification-cell'>
              <div className='desktop'><p>Level</p></div>
              <div className="creator-table__verification-badge">
                <CreatorLevelBadge level={deal.verificationLevel} />
              </div>
            </div>,
            sortValue: deal.rank
          },
          {
            content: <div className="creator-table__casino-cell"><img src={creatorsData.icons[dealInfo.casino]}></img><p>{dealInfo?.casino}</p></div>,
            sortValue: dealInfo.casino
          },
          {
            content: 
              <div className="creator-table__followers-cell">
                <img src={creatorsData.icons[dealInfo.platform] ?? ""}/>
                <p>{deal.followers.toLocaleString() ?? "0"}</p>
              </div>,
            sortValue: deal.followers
          },
          {
            content: 
            <NavLink to={"/creators/" + x.name.toLowerCase()}>
              <div className="button creator-table__view-cell">
                <p>View</p>
              </div>
            </NavLink>
          }
        ]
      };
    })

    const creatorRows: TzTableRow[] = creatorsData.creators.filter(x => x.creatorDeal !== null).map(x => {
      const deal = x.creatorDeal;
      const dealInfo = x.deals.find(x => x.media === "Creator" && x.type == "Current");
      if (!deal || !dealInfo) return {} as any;

      return {
        key: "creator-" + x.name,
        cells: [
          {
            content: <p>{deal.rank}</p>,
            sortValue: deal.rank
          },
          {
            content: 
              <div className='creator-table__creator-cell'>
                <img src={x.pfp} />
                <p>{x.name}</p>
                {dealInfo.verificationLevel > 0 && dealInfo.payments.filter(x => x.icon === "Raw").length > 0 ? <div className='creator-table__creator-pill'><p>Raw</p></div> : <></>}
              </div>,
            sortValue: x.name
          },
          {
            content: 
            <div className='creator-table__verification-cell'>
              <p>Level</p>
              <div className="creator-table__verification-badge">
                <CreatorLevelBadge level={deal.verificationLevel} />
              </div>
            </div>,
            sortValue: deal.rank
          },
          {
            content: <div className="creator-table__casino-cell"><img src={creatorsData.icons[dealInfo.casino]}></img><p>{dealInfo?.casino}</p></div>,
            sortValue: dealInfo.casino
          },
          {
            content: 
              <div className="creator-table__followers-cell">
                <img src={creatorsData.icons[dealInfo.platform] ?? ""}/>
                <p>{deal.followers.toLocaleString() ?? "0"}</p>
              </div>,
            sortValue: deal.followers
          },
          {
            content: 
            <NavLink to={"/creators/" + x.name.toLowerCase()}>
              <div className="button creator-table__view-cell">
                <p>Profile</p>
              </div>
            </NavLink>
          }
        ]
      };
    })
    
    return { streamerRows, creatorRows };
  }, [creatorsData]);

  return (
    <div className="creator-overview">
      <div className="creator-overview__title">
        <h1>OVERVIEW</h1>
      </div>
      <div className="creator-overview__header">
        <div className="creator-overview__header__left">
          <div className="creator-overview__table-toggles">
            <div
              className={`button creator-overview__table-toggle ${activeTable === "Streamers" ? "active" : ""}`}
              onClick={() => setActiveTable("Streamers")}
            >
              <p>Streamers</p>
            </div>
            <div
              className={`button creator-overview__table-toggle ${activeTable === "Creators" ? "active" : ""}`}
              onClick={() => setActiveTable("Creators")}
            >
              <p>Creators</p>
            </div>
          </div>
        </div>
        <div className="creator-overview__header__right">
          <div className="creator-overview__search">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
              <path d="M16.6 18L10.3 11.7C9.8 12.1 9.225 12.4167 8.575 12.65C7.925 12.8833 7.23333 13 6.5 13C4.68333 13 3.14583 12.3708 1.8875 11.1125C0.629167 9.85417 0 8.31667 0 6.5C0 4.68333 0.629167 3.14583 1.8875 1.8875C3.14583 0.629167 4.68333 0 6.5 0C8.31667 0 9.85417 0.629167 11.1125 1.8875C12.3708 3.14583 13 4.68333 13 6.5C13 7.23333 12.8833 7.925 12.65 8.575C12.4167 9.225 12.1 9.8 11.7 10.3L18 16.6L16.6 18ZM6.5 11C7.75 11 8.8125 10.5625 9.6875 9.6875C10.5625 8.8125 11 7.75 11 6.5C11 5.25 10.5625 4.1875 9.6875 3.3125C8.8125 2.4375 7.75 2 6.5 2C5.25 2 4.1875 2.4375 3.3125 3.3125C2.4375 4.1875 2 5.25 2 6.5C2 7.75 2.4375 8.8125 3.3125 9.6875C4.1875 10.5625 5.25 11 6.5 11Z" fill="white"/>
            </svg>
            <input
              placeholder={`Search ${activeTable.toLowerCase()}`}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="creator-overview__table">
        { activeTable === "Streamers" && <TzTable headers={headers} rowData={streamerRows} rowsPerPage={10} defaultSortDirection={"asc"} tableClassName='tz-creator-table' searchTerm={searchQuery} showPagination={true} /> }
        { activeTable === "Creators" && <TzTable headers={headers} rowData={creatorRows} rowsPerPage={10} defaultSortDirection={"asc"} tableClassName='tz-creator-table' searchTerm={searchQuery} showPagination={true}/> }
      </div>
    </div>
  );
}
