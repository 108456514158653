import { VictoryArea, VictoryAxis, VictoryChart, VictoryContainer, VictoryLegend, VictoryLine, VictoryStack } from 'victory';
import { useEffect, useRef, useState } from "react";
import { DashboardData, DataBlock_Clean, EntityData } from "../../../../models/DashboardData";
import { DashSectionHeader } from "../section header/DashSectionHeader";
import "./MainChart.scss";
import { useAnalyticsSettingsContext } from "../../AnalyticsSettingsContext";
import { ToSmallNumber } from "../../AnalyticsUtility";

export function MainChart() {
  const { dashboardData, selectedCasinos, timeframe } = useAnalyticsSettingsContext();

  const [chartDataType, setChartDataType] = useState('depositVolume');
  let casinoData = [];

  const chartRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0, fontSize: 0 });

  if (!(dashboardData === undefined || JSON.stringify(dashboardData) === "{}")) {
    for (const entityId of selectedCasinos) {
      const entity = dashboardData.entities.find((entity: EntityData) => entity.id === entityId);
      if (!entity) continue;

      const aggChain = entity.chains.find((chain: any) => chain.chain === "AGG");
      if (!aggChain) continue;

      //@ts-ignore
      const timeframeData: ChainTimeframeData = aggChain[timeframe];

      const newDataPoints = timeframeData.dataBlocks.map((dataBlock: DataBlock_Clean) => ({
        x: new Date(dataBlock.timestamp),
        y: chartDataType == "depositVolume" ? dataBlock.analytics.depositedUsd : chartDataType == "deposits" ? dataBlock.analytics.deposits : dataBlock.analytics.uniqueDepositors,
        casinoName: entityId, // Add the casino name to each data point
      }));
  
      // Calculate the sum of point.y values for the current casino's data
      const sumOfY = newDataPoints.reduce((acc: any, curr: any) => acc + curr.y, 0);
  
      casinoData.push({
        id: entityId,
        name: entity.name,
        color: entity.color,
        data: newDataPoints,
        sumOfY: sumOfY, // Add the sum to the casino data for sorting purposes
      });
    }
  
    // Sort the casinoData array by the sumOfY in descending order
    // If you prefer ascending order, swap 'b.sumOfY' and 'a.sumOfY'
    casinoData.sort((a, b) => a.sumOfY - b.sumOfY);
  
    // Optionally, if you don't need the sumOfY in the final objects, you can remove it
    casinoData.forEach(casino => delete casino.sumOfY);
  }
  
  const formatTick = (tick: any) => {
    const prefix = chartDataType === "depositVolume" ? "$" : "";
    return prefix + ToSmallNumber(tick);
  };

  useEffect(() => {
    function updateDimensions() {
      if (chartRef.current) {
        //@ts-ignore
        const containerWidth = chartRef.current.offsetWidth;
        // Change the aspect ratio for mobile vs desktop:
        // For mobile, for example, use 16:9 (width:height)
        // For desktop, use the default 3:2 ratio.
        const aspectRatio = window.innerWidth < 768 ? 16 / 9 : 4.5 / 2;
        const containerHeight = containerWidth / aspectRatio;
        const fontSize = window.innerWidth < 768 ? 12 : 12;
        setDimensions({
          width: containerWidth,
          height: containerHeight,
          fontSize: fontSize
        });
      }
    }
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <div className="dashboard-section main-chart">
      <DashSectionHeader title={chartDataType === "uniqueDepositors" ? "New Depositors" : chartDataType === "deposits" ? "Deposits" : "Deposit Volume"} />
      <div className="main-chart-container">
        <div className="main-chart__casino-keys" style={{backgroundColor: "transparent"}}>
          {casinoData.sort(x => x.sumOfY).reverse().map((casinoData) => {
            return (
              <div key={casinoData.id} className="main-chart__casino-key">
                <div className="main-chart__casino-pill" style={{backgroundColor: casinoData.color}}></div>
                <div className="main-chart__casino-name"><p>{casinoData.name}</p></div>
              </div>
            );
          })}
        </div>
        <div className="main-chart-container__victory-chart" style={{backgroundColor: "transparent"}} ref={chartRef}>
          {/* <div className={styles.watermark}>
            <div className={styles.desktop}><Logo size={22} /></div>
            <div className="mobile"><FullLogo size={16} /></div>
          </div> */}
            <VictoryChart
              scale={{ x: "time" }}
              containerComponent={<VictoryContainer style={{ width: "100%", height: "100%" }} responsive={true}/>}
              padding={{ top: 0, bottom: 21, left: 62, right: 2 }}
              width={dimensions.width}
              height={dimensions.height}
            >
              <VictoryAxis dependentAxis style={{ grid: { stroke: '#ccc', strokeWidth: 0.5, opacity: 0.2 }, tickLabels: { fill: "transparent" } }} />
              {casinoData.map(casino => (
                //@ts-ignore
                <VictoryLine
                  key={casino.id}
                  data={casino.data}
                  style={{ data: { stroke: casino.color, opacity: 0.7, strokeWidth: 1 } }}
                />
              ))}
              <VictoryAxis 
                domain={[0, Math.max(...casinoData.flatMap(casino => casino.data.map((point: any) => point.y)))]}
                dependentAxis
                tickFormat={formatTick}
                style={{ 
                  axis: { stroke: '#aaa'},
                  tickLabels: { fill: "#aaa", fontFamily: "Transducer, monospace", fontWeight: 300, fontSize: dimensions.fontSize, padding: 6 } 
                }} 
              />
              <VictoryAxis 
                fixLabelOverlap 
                style={{ 
                  axis: { stroke: '#aaa'}, 
                  tickLabels: { fill: "#aaa", fontFamily: "Transducer, monospace", fontWeight: 300, fontSize: dimensions.fontSize, padding: 6  } 
                }} 
              />
            </VictoryChart>
        </div>
        <div className="main-chart__chart-options-container">
          <div className="main-chart__chart-options">
            <div className={`button main-chart__chart-option ${chartDataType === "uniqueDepositors" ? "active" : ''}`} onClick={() => setChartDataType("uniqueDepositors")}><p>New Depositors</p></div>
            <div className={`button main-chart__chart-option ${chartDataType === "deposits" ? "active" : ''}`} onClick={() => setChartDataType("deposits")}><p>Deposits</p></div>
            <div className={`button main-chart__chart-option ${chartDataType === "depositVolume" ? "active" : ''}`} onClick={() => setChartDataType("depositVolume")}><p>Deposit Volume</p></div>
          </div>
        </div>
      </div>
    </div>
  )
}