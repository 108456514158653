import { Logo } from "../../../../components/Logo";
import { DashboardData } from "../../../../models/DashboardData";
import { useAnalyticsSettingsContext } from "../../AnalyticsSettingsContext";
import "./DashSectionHeader.scss";

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function DashSectionHeader({title}: {title: string}) {
  const { dashboardData, selectedChains, timeframe } = useAnalyticsSettingsContext();

  const chainOrder = ["ETH", "SOL", "BSC", "TRX"];  

  const sortedChains = selectedChains.sort((a, b) => chainOrder.indexOf(a) - chainOrder.indexOf(b));
  
  //console.log(selectedChains);

  return (
    <div className="dashboard-section__header">
      <div className="dashboard-section__header-left">
        <div className="dashboard-section__title"><p>{title}</p></div>
        <div className="dashboard-section__pills">
          <div className="dashboard-section__timeframe-pill"><p>1 {capitalizeFirstLetter(timeframe)}</p></div>
          <div className="dashboard-section__chains-pill">
            {sortedChains.map((chain) => (
              <img key={chain} src={dashboardData?.chainInfo.find(x => x.chain == chain)?.iconUrl} alt={`Icon ${chain}`} />
            ))}
          </div>
        </div>
      </div>
      <div className="desktop dashboard-section__header-right">
        <Logo />
        <h1>TANZANITE</h1>
      </div>
    </div>
  )
}