import React, { useEffect } from 'react';
import './styles/reset.scss';
import './styles/global.scss';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { HomePage } from './pages/home/HomePage';
import { Navbar } from './components/navbar/Navbar';
import { BetHistoryStateProvider } from './BetHistoryStateProvider';
import { AuditsPage } from './pages/audits/AuditsPage';
import { ModalProvider } from './ModalContext';
import { AuthProvider } from './AuthContext';
import Footer from './components/footer/Footer';
import { DiscordCTA } from './components/discord cta/DiscordCTA';
import { Supporters } from './components/supporters/Supporters';
import { CreatorsPage } from './pages/creators/CreatorsPage';
import { AnalyticsPage } from './pages/analytics/AnalyticsPage';
import { BlogPage } from './pages/blog/BlogPage';
import BlogPost from './pages/blog/BlogPost';
import { ContactPage } from './pages/contact/ContactPage';
import { CreatorPage } from './pages/creator/CreatorPage';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="page-container">
        <Navbar />
        <div className="page-content">
          <Routes>
            <Route path="/" element={       
              <AuthProvider>
                <BetHistoryStateProvider>
                  <ModalProvider>
                    <HomePage />
                  </ModalProvider>
                </BetHistoryStateProvider>
              </AuthProvider>  
            } />
              <Route path="/audits" element={
                <ModalProvider>
                  <AuditsPage />
                </ModalProvider>} />
              <Route path="/creators" element={<CreatorsPage />} />
              <Route path="/analytics" element={<AnalyticsPage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/blog/:slug" element={<BlogPost />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/creators/:creatorId" element={<CreatorPage />} />
            {/*
            <Route path="/creators/:creatorId" element={<CreatorPage />} />
            <Route path="/score-calculator" element={<ScoreCalculatorPage />} />
            <Route path="/analytics" element={<AnalyticsPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/contact" element={<ContactPage />} /> */}
          </Routes>
        </div>
        <DiscordCTA />
        <Supporters />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
