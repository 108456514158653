// BetHistoryProvider.tsx
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useGetBetHistoryQuery } from './state/api';
import { GameData } from './models/GameData';
import { useAuth } from './AuthContext';

interface BetHistoryContextValue {
  bets: GameData[] | null;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  isUninitialized: boolean;
}

const BetHistoryContext = createContext<BetHistoryContextValue | undefined>(undefined);

interface GlobalStateProviderProps {
  children: ReactNode;
}

export const BetHistoryStateProvider: React.FC<GlobalStateProviderProps> = ({ children }) => {
  const { token } = useAuth();

  const { data, isLoading, isError, error, isUninitialized } = useGetBetHistoryQuery(undefined, {
    skip: !token, // skips fetching when token is falsy
  });

  // Initialize bets with only plinko and roulette bets
  const [bets, setBets] = useState<GameData[] | null>(
    data?.bets?.filter(bet => bet.game === 'plinko' || bet.game === 'roulette') || null
  );

  // Update bets when initial data changes
  useEffect(() => {
    if (data?.bets) {
      const validBets = data.bets.filter(bet => bet.game === 'plinko' || bet.game === 'roulette');
      const plinkoBets = validBets.filter(bet => bet.game === 'plinko').slice(0, 100);
      const rouletteBets = validBets.filter(bet => bet.game === 'roulette').slice(0, 100);
      setBets([...plinkoBets, ...rouletteBets]);
    }
  }, [data]);

  // Setup WebSocket subscription for live updates
  useEffect(() => {
    const socket: Socket = io(process.env.REACT_APP_API_URL as string, {
      auth: { token },
    });

    socket.on('connect', () => {
      //console.log('Socket connected:', socket.id);
    });

    socket.on('betUpdate', (newBet: GameData) => {
      // Only process bets for "plinko" or "roulette"
      if (newBet.game !== 'plinko' && newBet.game !== 'roulette') {
        return;
      }

      setBets(prevBets => {
        // Combine new bet with existing valid bets
        const validBets = [newBet, ...(prevBets?.filter(bet => bet.game === 'plinko' || bet.game === 'roulette') || [])];

        // Limit each game type to 100 bets (most recent first)
        const plinkoBets = validBets.filter(bet => bet.game === 'plinko').slice(0, 100);
        const rouletteBets = validBets.filter(bet => bet.game === 'roulette').slice(0, 100);

        return [...plinkoBets, ...rouletteBets];
      });
    });

    socket.on('disconnect', () => {
      //console.log('Socket disconnected');
    });

    // Cleanup: disconnect the socket on unmount
    return () => {
      socket.disconnect();
    };
  }, [token]);

  return (
    <BetHistoryContext.Provider value={{ bets, isLoading, isError, error, isUninitialized }}>
      {children}
    </BetHistoryContext.Provider>
  );
};

export const useBetHistory = () => {
  const context = useContext(BetHistoryContext);
  if (!context) {
    throw new Error('useBetHistory must be used within a BetHistoryStateProvider');
  }
  return context;
};
