export function Logo () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 42" fill="none">
      <path d="M39.9019 0H14.3661C13.8577 0 13.3726 0.202666 13.0138 0.558163L0.279081 13.2929C-0.0930268 13.665 -0.0930268 14.2697 0.279081 14.6418L26.4262 40.8223C26.7984 41.1944 27.403 41.1944 27.7752 40.8223L53.9855 14.6418C54.3576 14.2697 54.3576 13.665 53.9855 13.2929L41.2508 0.558163C40.892 0.202666 40.4069 0 39.9019 0ZM26.0243 13.9707C26.4196 13.9707 26.7386 14.2896 26.7386 14.685V35.2041C26.7386 35.5231 26.3532 35.6825 26.1273 35.4566L5.16962 14.4724C4.89054 14.1933 4.89054 13.7381 5.16962 13.459L14.6318 3.99352C14.9009 3.72441 15.263 3.5749 15.6451 3.5749H25.9512C26.2668 3.5749 26.4296 3.95698 26.207 4.1829L17.2266 13.356C17.004 13.582 17.1668 13.964 17.4824 13.964H26.0209L26.0243 13.9707ZM28.1373 13.9707C27.742 13.9707 27.423 13.6517 27.423 13.2564V4.29586C27.423 3.90049 27.742 3.58154 28.1373 3.58154H38.6161C38.9949 3.58154 39.3603 3.73105 39.6294 4.00017L49.0916 13.4657C49.3706 13.7448 49.3706 14.1999 49.0916 14.479L28.1572 35.3869C28.0177 35.5264 27.7851 35.3669 27.8682 35.1842L37.3636 14.479C37.4732 14.2431 37.3005 13.9707 37.038 13.9707H28.134H28.1373Z" fill="url(#paint0_linear_8097_6277)"/>
      <defs>
        <linearGradient id="paint0_linear_8097_6277" x1="0.910335" y1="3.83737" x2="46.5235" y2="27.1472" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FD9EFB"/>
          <stop offset="1" stopColor="#F658DD"/>
        </linearGradient>
      </defs>
    </svg>
  )
}