import { useState } from "react"
import "./PlatformTools.scss"
import platformToolAuditsImage from "../../../../assets/platform-tool_audits.png"
import platformToolAnalyticsImage from "../../../../assets/platform-tool_analytics.png"
import platformToolCreatorsImage from "../../../../assets/platform-tool_creators.png"
import { NavLink } from "react-router-dom";

enum Tools {
  AUDITS,
  CREATORS,
  ANALYTICS
}

export function PlatformTools() {
  const [toolSelected, setToolSelected] = useState(Tools.CREATORS);

  return (
    <div className="platform-tools">
      <div className="platform-tools__title">
        <h1><span>OUR </span>PLATFORM TOOLS</h1>
      </div>
      <div className="platform-tools__desc">
        <p>
        Explore Tanzanite's expanding suite of tools designed for the entire crypto casino ecosystem.
        </p>
      </div>
      <div className="platform-tools__options">
        <div onClick={() => setToolSelected(Tools.AUDITS)} className={"button platform-tools__option" + (toolSelected === Tools.AUDITS ? " highlighted" : "")}><div>Audits</div></div>
        <div onClick={() => setToolSelected(Tools.CREATORS)} className={"button platform-tools__option" + (toolSelected === Tools.CREATORS ? " highlighted" : "")}><div>Creators</div></div>
        <div onClick={() => setToolSelected(Tools.ANALYTICS)} className={"button platform-tools__option" + (toolSelected === Tools.ANALYTICS ? " highlighted" : "")}><div>Analytics</div></div>
      </div>
      <div className="platform-tools__display">
        {toolSelected === Tools.AUDITS && (
          <>
            <div className="platform-tools__display__left">
              <div className="platform-tools__display__title"><h2>COMPREHENSIVE CASINO AUDITS</h2></div>
              <p className="platform-tools__display__desc">Our audits objectively evaluate crypto casinos on compliance, responsible gaming, security, and support, assigning clear comparison scores.</p>
              <NavLink to={'/audits'}>
                <div className="button platform-tools__display__button"><p>View more</p></div>
              </NavLink>
            </div>
            <div className="platform-tools__display__right">
              <img src={platformToolAuditsImage} />
            </div>            
          </>
        )}
        {toolSelected === Tools.CREATORS && (          
          <>
            <div className="platform-tools__display__left">
              <div className="platform-tools__display__title"><h2>STREAMER DEAL TRANSPARENCY</h2></div>
              <p className="platform-tools__display__desc">Check out creators who've verified the details of their casino deals through Tanzanite and easily compare their latest Kick stream analytics.</p>
              <NavLink to={'/creators'}>
                <div className="button platform-tools__display__button"><p>View more</p></div>
              </NavLink>
            </div>
            <div className="platform-tools__display__right">
              <img src={platformToolCreatorsImage} />
            </div>  
          </>
        )}
        {toolSelected === Tools.ANALYTICS && (
          <>
            <div className="platform-tools__display__left">
              <div className="platform-tools__display__title"><h2>CUTTING EDGE ANALYTICS</h2></div>
              <p className="platform-tools__display__desc">Gain detailed insights into deposit transactions and volumes across 4 crypto networks, and compare token usage and overall market share.</p>
              <NavLink to={'/analytics'}>
                <div className="button platform-tools__display__button"><p>View more</p></div>
              </NavLink>
            </div>
            <div className="platform-tools__display__right">
              <img src={platformToolAnalyticsImage} />
            </div>  
          </>
        )}
      </div>
    </div>
  )
}