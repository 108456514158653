import React, { useMemo } from 'react';
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryLegend,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';

import { KickSnapshotData } from '../../../models/StreamData';
import { Logo } from '../../../components/Logo';
import './StreamChart.scss';

interface ChartProps {
  data: KickSnapshotData[];
}

const MissingData = ({ missing }: { missing: boolean }) =>
  missing ? (
    <div style={{ marginBottom: '12px', display: 'flex', alignItems: 'center' }}>
      Missing Data
    </div>
  ) : null;

const StreamOverview = ({ data }: { data: KickSnapshotData[] }) => {
  if (data.length === 0) return <>No Data</>;

  const totalViewers = data.reduce((acc, snapshot) => acc + snapshot.viewers, 0);
  const averageViewers = totalViewers / data.length;
  const peakViewers = data.reduce(
    (max, snapshot) => (snapshot.viewers > max ? snapshot.viewers : max),
    0
  );

  let totalMessages = 0;
  let totalTimeMinutes = 0;

  for (let i = 1; i < data.length; i++) {
    const prev = data[i - 1];
    const current = data[i];
    const messagesDiff = current.totalMessages - prev.totalMessages;
    const validMessagesDiff = messagesDiff >= 0 ? messagesDiff : 0;
    const timeDiffMinutes =
      (new Date(current.timestamp).getTime() - new Date(prev.timestamp).getTime()) /
      (1000 * 60);
    totalMessages += validMessagesDiff;
    totalTimeMinutes += timeDiffMinutes;
  }

  const averageMessagesPerMinute =
    totalTimeMinutes > 0 ? totalMessages / totalTimeMinutes : 0;
  const viewerToMessageRatio =
    averageMessagesPerMinute > 0 ? averageViewers / averageMessagesPerMinute : 0;

  return (
    <div className="stream-chart__analytics-items">
      <div className="stream-chart__analytics-item">
        <div className="stream-chart__analytics-title">
          <p>Peak Viewers</p>
        </div>
        <div className="stream-chart__analytics-number">
          <p>{peakViewers}</p>
        </div>
      </div>
      <div className="stream-chart__analytics-item">
        <div className="stream-chart__analytics-title">
          <p>Avg Viewers</p>
        </div>
        <div className="stream-chart__analytics-number">
          <p>{averageViewers.toFixed(1)}</p>
        </div>
      </div>
      <div className="stream-chart__analytics-item">
        <div className="stream-chart__analytics-title">
          <p>Avg Msg Per Min</p>
        </div>
        <div className="stream-chart__analytics-number">
          <p>{averageMessagesPerMinute.toFixed(1)}</p>
        </div>
      </div>
      <div className="stream-chart__analytics-item">
        <div className="stream-chart__analytics-title">
          <p>Avg Viewer To Msg Ratio</p>
        </div>
        <div className="stream-chart__analytics-number">
          <p>{viewerToMessageRatio.toFixed(1)}</p>
        </div>
      </div>
    </div>
  );
};

const StreamChart: React.FC<ChartProps> = ({ data: rawData }) => {
  // Filter out invalid data points
  const data = rawData.filter((x) => x.viewers !== null && x.followers !== null);

  // Sort data by timestamp
  const sortedData = useMemo(
    () =>
      [...data].sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      ),
    [data]
  );

  // Process data for chart
  const processedData = useMemo(() => {
    if (sortedData.length === 0) return [];
    const startTime = new Date(sortedData[0].timestamp).getTime();
    return sortedData.map((point) => {
      const currentTime = new Date(point.timestamp).getTime();
      return {
        timeSinceStart: (currentTime - startTime) / (1000 * 60),
        followersGained: point.followers - sortedData[0].followers,
        messages: Math.floor(point.totalMessages / 10),
        viewers: point.viewers,
        uniqueMessagers: point.uniqueMessagers,
      };
    });
  }, [sortedData]);

  if (processedData.length === 0) {
    return (
      <div className="stream-chart">
        <MissingData missing={true} />
        <div>No data available</div>
      </div>
    );
  }

  // Prepare datasets for Victory
  const followersData = processedData.map((d) => ({
    x: d.timeSinceStart,
    y: d.followersGained,
  }));
  const messagesData = processedData.map((d) => ({
    x: d.timeSinceStart,
    y: d.messages,
  }));
  const viewersData = processedData.map((d) => ({
    x: d.timeSinceStart,
    y: d.viewers,
  }));
  const uniqueMessagersData = processedData.map((d) => ({
    x: d.timeSinceStart,
    y: d.uniqueMessagers,
  }));

  // Here, we use a configuration array for clarity
  const lineConfigs = [
    { data: followersData, name: 'Followers', stroke: '#00bfff' },
    { data: messagesData, name: 'Messages', stroke: '#32cd32' },
    { data: viewersData, name: 'Viewers', stroke: '#ff6347' },
    { data: uniqueMessagersData, name: 'Unique Messagers', stroke: '#ffa500' },
  ];

  return (
    <div className="stream-chart">
      <MissingData missing={data.length < rawData.length || data.length === 0} />
      <StreamOverview data={data} />
      <div className="stream-chart__chart-container">
        <div className="stream-chart__watermark">
          <Logo />
        </div>
        <VictoryChart
          // containerComponent={
          //   <VictoryVoronoiContainer
          //     // voronoiDimension="x"
          //     // // Blacklist components (like legend) that shouldn't trigger tooltips
          //     // voronoiBlacklist={['legend']}
          //     // labels={({ datum }) =>
          //     //   // Ensure datum exists before accessing its properties
          //     //   datum ? `${Math.round(datum.x)}m — Value: ${datum.y}` : ''
          //     // }
          //     // labelComponent={
          //     //   <VictoryTooltip
          //     //     style={{ fontSize: 12 }}
          //     //     flyoutStyle={{ fill: '#111525AA', padding: 10 }}
          //     //   />
          //     // }
          //   />
          // }
          padding={{ top: 25, bottom: 30, left: 40, right: 10 }}

        >
          <VictoryAxis
            label="Time Since Start (minutes)"
            style={{
              axisLabel: {
                fill: "#aaa",
                fontFamily: "Transducer, monospace",
                fontWeight: 400,
                padding: 30,
                fontSize: 8,
              },
              tickLabels: {
                fill: "#aaa",
                fontFamily: "Transducer, monospace",
                fontWeight: 300,
                fontSize: 8
              },
              axis: { stroke: '#aaa'}, 
              grid: { stroke: "transparent" },
            }}
            tickFormat={(x) => `${Math.round(x)}m`}
          />
          <VictoryAxis
            dependentAxis
            style={{
              axisLabel: { padding: 50, fill: "#aaa", fontSize: 14 },
              tickLabels: {
                fill: "#aaa",
                fontFamily: "Transducer, monospace",
                fontWeight: 300,
                fontSize: 8
              },
              axis: { stroke: '#aaa'},
              grid: { stroke: "#ccc", strokeWidth: 0.5, opacity: 0.2 },
            }}
          />
          {lineConfigs.map(({ data, name, stroke }) => {
            // @ts-ignore
            return (<VictoryLine
                key={name}
                data={data}
                style={{ data: { stroke, strokeWidth: 1 } }}
                interpolation="monotoneX"
              />
            );
          })}
          <VictoryLegend
            name="legend"
            x={0}
            y={-5}
            orientation="horizontal"
            gutter={20}
            style={{
              border: { stroke: "none" },
              labels: { fontSize: 8, fill: "#aaa" },
            }}
            data={[
              { name: "Followers Gained", symbol: { fill: "#00bfff" } },
              { name: "Messages / 10", symbol: { fill: "#32cd32" } },
              { name: "Viewers", symbol: { fill: "#ff6347" } },
              { name: "Unique Messagers", symbol: { fill: "#ffa500" } },
            ]}
          />
        </VictoryChart>
      </div>
    </div>
  );
};

export default StreamChart;
