import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import "./Testimonials.scss";

import stakePerson from "../../../../assets/testimonials/stake-person.png";
import stakeSite from "../../../../assets/testimonials/stake-site.png";

import bcgamePerson from "../../../../assets/testimonials/bcgame-person.png";
import bcgameSite from "../../../../assets/testimonials/bcgame-site.png";

import blockbetPerson from "../../../../assets/testimonials/blockbet-person.png";
import blockbetSite from "../../../../assets/testimonials/blockbet-site.png";

import rainbetPerson from "../../../../assets/testimonials/rainbet-person.png";
import rainbetSite from "../../../../assets/testimonials/rainbet-site.png";

import goatedPerson from "../../../../assets/testimonials/goated-person.png";
import goatedSite from "../../../../assets/testimonials/goated-site.png";

import sbxPerson from "../../../../assets/testimonials/sbx-person.png";
import sbxSite from "../../../../assets/testimonials/sbx-site.png";

import bethogPerson from "../../../../assets/testimonials/bethog-person.png";
import bethogSite from "../../../../assets/testimonials/bethog-site.png";

import shufflePerson from "../../../../assets/testimonials/shuffle-person.png";
import shuffleSite from "../../../../assets/testimonials/shuffle-site.png";

import yologroupPerson from "../../../../assets/testimonials/yologroup-person.png";
import yologroupSite from "../../../../assets/testimonials/yologroup-site.png";


import { usePlayRouletteMutation } from "../../../../state/api";
import { useModal } from "../../../../ModalContext";
import { ProvablyFair } from "../../../provably fair/ProvablyFair";
import { PlayButton, SpinButton } from "../../../../components/game buttons/PlayButton";
import { Logo } from "../../../../components/Logo";
import { PFButton } from "../../../../components/game buttons/PFButton";

interface Testimonial {
  id: string;
  logo: string;
  text: string;
  person: string;
  personRole: string;
  personPfp: string;
}

export const testimonialsData: Testimonial[] = [
  {
    id: "Stake",
    logo: stakeSite,
    text: "Big shoutout to Tanzanite, analysing & comparing global platforms not just by metrics such as volume, but also by their dedication to compliance, responsible gaming & security.",
    person: "Eddie",
    personRole: "Co-founder of Stake",
    personPfp: stakePerson
  },
  {
    id: "BCGame",
    logo: bcgameSite,
    text: "This team stands out in a world where creativity is rare. Their passion and innovation set them apart, proving that great people create great products.",
    person: "Jolie",
    personRole: "Marketing Lead at BC Game",
    personPfp: bcgamePerson
  },
  {
    id: "BlockBet",
    logo: blockbetSite,
    text: "Tanzanite have fast become an analytics bible for web3 gambling operators and have cornered the market for insight and analytics. Their market share reports and transparency research on creators is second to none.",
    person: "Alexis Zamboglou",
    personRole: "CEO of BlockBet",
    personPfp: blockbetPerson
  },
  {
    id: "Rainbet",
    logo: rainbetSite,
    text: "I appreciate the approach to data, no overcomplications and straightforward analytics.",
    person: "Silvio",
    personRole: "Partnerships & Communications at Rainbet",
    personPfp: rainbetPerson
  },
  {
    id: "BetHog",
    logo: bethogSite,
    text: "Tanzanite is the gold standard of the crypto casino industry.",
    person: "Nigel Eccles",
    personRole: "Co-founder of BetHog and FanDuel",
    personPfp: bethogPerson
  },
  {
    id: "Goated",
    logo: goatedSite,
    text: "Tanzanite has brought much-needed transparency to the crypto casino industry. Its analytics offer valuable insights into the largest players in the space, and the leaderboard format makes it easy to see where we stand. I imagine every casino operator checks it regularly.",
    person: "G3",
    personRole: "Founder of Goated",
    personPfp: goatedPerson
  },
  {
    id: "SBX",
    logo: sbxSite,
    text: "Tanzanite is a trusted leader in crypto gaming and influencer analytics, delivering essential market insights and performance data. Industry professionals depend on their expert analysis and timely newsletter to navigate this dynamic landscape.",
    person: "Gary Shannon",
    personRole: "Founder of SBX",
    personPfp: sbxPerson
  },
  {
    id: "Shuffle",
    logo: shuffleSite,
    text: "Tanzanite is one of the most comprehensive and accurate crypto casino data aggregation platforms we’ve seen to date. The team seems to be genuinely interested in bringing transparency and accountability to the space, which is a welcome move from us at Shuffle.",
    person: "Noah Dummett",
    personRole: "Co-founder of Shuffle",
    personPfp: shufflePerson
  },
  {
    id: "Yolo Entertainment",
    logo: yologroupSite,
    text: "Tanzanite has raised the standards in the iGaming space which has continued to translate positively back to end users. Bringing more transparency to our space is always welcome and should be applauded.",
    person: "Tim Heath",
    personRole: "Founder of yolo.com",
    personPfp: yologroupPerson
  },
  // {
  //   id: "sbx",
  //   logo: sbxSite,
  //   text: "Im too slow to have a real testiomnial atm",
  //   person: "Sbx Owner",
  //   personRole: "Co-founder of SBX",
  //   personPfp: sbxPerson
  // },
];

const LAPS = 5; // total number of extra sets
const repeatedTestimonials = Array(LAPS + 2)
  .fill(testimonialsData)
  .flat();

const originalLength = testimonialsData.length;
const startingIndex = originalLength;

function TestimonialButton({testimonialObject, onClick, isSelected, isSpinning}: { testimonialObject: Testimonial, onClick: () => void; isSelected: boolean; isSpinning: boolean }) {
  return (
    <div
      className={`button testimonial-button${isSelected ? " selected" : ""}${isSpinning ? " disabled" : ""}`}
      onClick={!isSpinning ? onClick : undefined}
    >
      <div className="testimonial-button_img-container">
        <img src={testimonialObject.logo} alt={testimonialObject.id} />
      </div>
      <div className="testimonial-button__overlay"></div>
    </div>
  );
}

export function TestimonialCard({ testimonialObject, isCenter }: { testimonialObject: Testimonial, isCenter: boolean }) {
  return (
    <div className={`testimonial-card${isCenter ? " selected" : ""}`}>
      <div>
      <div className="testimonial-card__message"><p>{testimonialObject.text}</p></div>
      </div>
      <div className="testimonial-card__footer">
        <div>
          <img src={testimonialObject.personPfp} />
        </div>
        <div className="testimonial-card__person">
          <div className="testimonial-card__person__name"><p>{testimonialObject.person}</p></div>
          <div className="testimonial-card__person__role"><p>{testimonialObject.personRole}</p></div>
        </div>
      </div>
    </div>
  );
}

// Register the CustomEase plugin
gsap.registerPlugin(CustomEase);
CustomEase.create("customEase", "M0,0 C0.084,0.61 0.139,0.715 0.197,0.781 0.26,0.853 0.374,1 1,1");

export function Testimonials() {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const cardWidthRef = useRef<number>(0);
  const wrapperWidthRef = useRef<number>(0);

  const [currentIndex, setCurrentIndex] = useState(startingIndex);
  const [isSpinning, setIsSpinning] = useState(false);

  const [preCenterIndex, setPreCenterIndex] = useState<number | null>(null);
  const [centerIndex, setCenterIndex] = useState<number | null>(startingIndex);

  const cardWidth = 33;
  const cardGap = 1;

  function computeX(virtualIndex: number) {
    const x = 50 - (cardWidth / 2) - (virtualIndex * cardWidth) - (virtualIndex) * cardGap;
    return x;
  }

  useLayoutEffect(() => {
    if (!containerRef.current) return;
    if (!wrapperRef.current) return;
  
    // measure the first card
    if (containerRef.current.children.length > 0) {
      const children = containerRef.current.children;
      const firstCard = children[0] as HTMLElement;
      const secondCard = children[1] as HTMLElement;
    
      // This distance is basically “card width + gap”
      const cardPlusGap = secondCard.offsetLeft - firstCard.offsetLeft;
    
      cardWidthRef.current = cardPlusGap;
    }
  
    // measure the wrapper
    wrapperWidthRef.current = wrapperRef.current.offsetWidth;
  
    // For example, if you have a "baseIndex", do an initial set:
    const initialX = computeX(startingIndex); 
    gsap.set(containerRef.current, { x: initialX + "%" });

  }, []);

  function handleButtonClick(i: number) {
    setPreCenterIndex(null)

    const targetIndex = startingIndex + i; // if you’re repeating sets
    const newX = computeX(targetIndex);
  
    gsap.to(containerRef.current, {
      duration: 0.8,
      x: newX + "%",
      ease: "power2.inOut",
      onUpdate: updateCenterCard,
      onComplete: () => {
        // update your state, etc.
      },
    });
  }

  function handleSpin(randomIndex: number) {
    if (!containerRef.current) return;

    setPreCenterIndex(null)
  
    const laps = 2; // how many “laps” you want

    const randomOffset = (Math.random() - 0.5) * (cardWidth / (cardWidth + cardGap));
  
    const spinIndex = startingIndex + (laps * originalLength) + randomIndex + randomOffset;
    let spinX = computeX(spinIndex);
  
    gsap.to(containerRef.current, {
      duration: 3,
      x: spinX + "%",
      ease: "customEase",
      onUpdate: updateCenterCard,
      onComplete: () => {
        setCurrentIndex(randomIndex);

        setPreCenterIndex(startingIndex + randomIndex)

        const targetIndex = spinIndex - randomOffset; // if you’re repeating sets
        const newX = computeX(targetIndex);

        gsap.to(containerRef.current, {
          duration: 0.2,
          x: newX + "%",
          ease: "power1.in",
          onComplete: () => {
            const targetIndexNormal = startingIndex + randomIndex; // if you’re repeating sets
            const newXNormal = computeX(targetIndexNormal);

            gsap.set(containerRef.current, { x: newXNormal + "%" });
            setIsSpinning(false);
          }
        });
      },
    });
  }

  function updateCenterCard() {
    if (!containerRef.current || !wrapperRef.current) return;
  
    const wrapperRect = wrapperRef.current.getBoundingClientRect();
    const wrapperCenterX = (wrapperRect.left + wrapperRect.right) / 2;
  
    let minDist = Infinity;
    let closestIdx: number | null = null;
  
    const children = containerRef.current.children;
    for (let i = 0; i < children.length; i++) {
      const cardEl = children[i] as HTMLElement;
      const cardRect = cardEl.getBoundingClientRect();
      const cardCenterX = (cardRect.left + cardRect.right) / 2;
  
      const dist = Math.abs(cardCenterX - wrapperCenterX);
      if (dist < minDist) {
        minDist = dist;
        closestIdx = i;
      }
    }
  
    if (closestIdx !== null) {
      setCenterIndex(closestIdx);
      setCurrentIndex(closestIdx);
    }
  }

  // ===== API Call & Simulation =====
  const [playRoulette] = usePlayRouletteMutation();

  const performGameRound = async () => {
    try {
      setIsSpinning(true);

      const result = await playRoulette().unwrap();
      const outcome = result.vrfRes.outcome;

      //console.log(outcome);

      const index = testimonialsData.findIndex(x => x.id === outcome);
      handleSpin(index);

    } catch (err) {
      console.error("Error calling playGame API:", err);

      setIsSpinning(false);
    }
  };

  const { openModal, closeModal } = useModal();

  const handleOpenModal = () => {
    openModal(<ProvablyFair toggleModal={closeModal} />);
  };

  return (
    <div className="testimonials">
      <div className="testimonials__introduction">
        <p>
          We started Tanzanite to establish a new standard for the casino industry by conducting thorough audits and ensuring compliance, security, support and safe gambling practices.
        </p>
      </div>
      <div className="testimonials__title">
        <h1>TRUSTED BY</h1>
      </div>

      <div className="testimonials-buttons">
        {testimonialsData.map((t, i) => {
          const isSelected = (currentIndex % originalLength) === i;
          return (
            <TestimonialButton
              key={t.id}
              testimonialObject={t}
              onClick={() => handleButtonClick(i)}
              isSelected={isSelected}
              isSpinning={isSpinning}
            />
          );
        })}
      </div>

      <div className="testimonial-game">
        <div className="testimonial-game__ticker-ball"></div>
        <div className="testimonial-game__ticker"></div>
        <div className="testimonial-cards-wrapper" ref={wrapperRef}>
          {/* Inner container we animate with GSAP */}
          <div className="testimonial-cards" ref={containerRef}>
          {repeatedTestimonials.map((t, idx) => {
            const isCenter = (preCenterIndex === idx) || (centerIndex === idx);
            return (
              <TestimonialCard
                key={idx}
                testimonialObject={t}
                isCenter={isCenter}
              />
            );
          })}
          </div>
        </div>
        <div className="testimonial__logo">
          <Logo />
        </div>
      </div>

      <div className="testimonial__controls">
        <div className="" onClick={!isSpinning ? performGameRound : undefined}>
          <SpinButton play={!isSpinning} disabled={isSpinning}/>
        </div>

        <PFButton openModal={handleOpenModal} />
      </div>
    </div>
  );
}
