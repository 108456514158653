import { useEffect, useState } from "react";
import { Header } from "../../components/header/Header";
import { useGetDashboardQuery } from "../../state/api";
import { DashboardData } from "../../models/DashboardData";
import { Toolbar } from "./components/toolbar/Toolbar";
import { MainChart } from "./components/main chart/MainChart";
import { MarketBreakdown } from "./components/breakdowns/MarketBreakdown";
import { TokenBreakdown } from "./components/breakdowns/TokenBreakdown";
import { AnalyticsSettingsProvider, useAnalyticsSettingsContext } from "./AnalyticsSettingsContext";
import { Dashboard } from "./components/dashboard/Dashboard";

import "./AnalyticsPage.scss";

export function AnalyticsPage() {
  return (
    <AnalyticsSettingsProvider>
      <div className="analytics">
        <Header title="ANALYTICS" desc="A public analytics dashboard for comparing casinos across networks" />
        <Dashboard />
      </div>
    </AnalyticsSettingsProvider>
  )
}