import { useState } from "react";
import { KickStreamData } from "../../../models/StreamData";
import "./CreatorStream.scss";
import StreamChart from "../stream chart/StreamChart";

const dateOptions: Intl.DateTimeFormatOptions = { 
  day: 'numeric', 
  month: 'long', // Full month name (e.g., June)
  year: 'numeric' 
};


function formatDate(_date: Date) {
  const date = new Date(_date);

  const formattedDate: string = date.toLocaleDateString(undefined, dateOptions);
  const formattedWithComma: string = formattedDate.replace(/ (\d{4})$/, ', $1');

  return formattedWithComma;
}

export function CreatorStream({kickStream, selected}: {kickStream: KickStreamData, selected: boolean}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(selected);

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <div className="creator-stream">
        <div className="creator-stream__header" onClick={toggleDropdown}>
          <div className="creator-stream__header__left">
            <div className="creator-stream__title">
              {kickStream.endTime === null && 
                <div className="creator-stream__live">
                  <div className="creator-stream__live-icon">
                  </div>
                  <p>Live -</p>
                </div>
              }
              <p>{kickStream.title}</p>
            </div>
            <div className="creator-stream__date">
              <p>{formatDate(new Date(kickStream.startTime))}</p>
            </div>
          </div>
          <div className={`creator-deal__header__arrow${isDropdownOpen ? " open" : ""}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 8" fill="none">
              <path d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z" fill="white"/>
            </svg>
          </div>
        </div>
        {isDropdownOpen && (
          <div className="creator-stream__content">
            <div className="creator-stream__graph">
              <StreamChart data={kickStream.snapshots}/>
            </div>
          </div>
        )}
      </div>
    </>
  )
}