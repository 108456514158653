import { HeroSection } from "./components/hero section/HeroSection";
import "./HomePage.scss";
import card from "../../assets/card.png";
import { useEffect, useState } from "react";
import { PlatformTools } from "./components/platform tools/PlatformTools";

import * as jwt_decode from 'jwt-decode';
import { ProvablyFair } from "../provably fair/ProvablyFair";
import { PFButton } from "../../components/game buttons/PFButton";
import { Consultancy } from "./components/consultancy/Consultancy";
import { Testimonials } from "./components/testimonials/Testimonials";
import { BlogStrip } from "../../components/blog/BlogStrip";
import { DiscordCTA } from "../../components/discord cta/DiscordCTA";
import { Supporters } from "../../components/supporters/Supporters";

export function HomePage() {
  return (
    <div>
      <HeroSection />
      <PlatformTools />
      <Consultancy />
      <Testimonials />
      <BlogStrip/>
    </div>
  )
}