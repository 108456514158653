import { useEffect, useMemo } from "react";
import { useGetDashboardQuery } from "../../../../state/api";
import { useAnalyticsSettingsContext } from "../../AnalyticsSettingsContext";
import { Toolbar } from "../toolbar/Toolbar";
import { MainChart } from "../main chart/MainChart";
import { MarketBreakdown } from "../breakdowns/MarketBreakdown";
import { TokenBreakdown } from "../breakdowns/TokenBreakdown";
import "./Dashboard.scss";
import { AggregateDashboardData } from "../../AnalyticsUtility";
import { DashboardData } from "../../../../models/DashboardData";
import { ErrorMessage } from "../../../../components/middleware states/ErrorMessage";
import { LoadingSpinner } from "../../../../components/middleware states/LoadingAnimation";

export function Dashboard() {
    const { dashboardData, setDashboardData, selectedCasinos, setSelectedCasinos, selectedChains, setSelectedChains, timeframe, setTimeframe } = useAnalyticsSettingsContext();
  
    const { data, isError, error, isLoading } = useGetDashboardQuery();

    useEffect(() => {
      if (data !== undefined && data !== null) {
        //console.log(data);
        //console.log(JSON.parse(JSON.stringify(data)))

        const copiedData = JSON.parse(JSON.stringify(data)) as DashboardData;

        //console.log("copied", copiedData);

        setDashboardData(copiedData);
      }

      // setSelectedCasinos(["Stake", "Rollbit", "Roobet", "Gamdom", "Shuffle"]);
    }, [data]);

    useEffect(() => {
      //console.log("dashboardData updated:", dashboardData);
    }, [dashboardData]);

    useMemo(() => {
      AggregateDashboardData(dashboardData, selectedChains);
    }, [dashboardData, selectedChains])

    if (isError) {
      return (
        <div className="dashboard">
          <ErrorMessage message="There was an error loading analytics data" />
        </div>
      );
    }
  
    if (isLoading || !dashboardData || JSON.stringify(dashboardData) === "{}") {
      return (
        <div className="dashboard">
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <div className="dashboard">
        <Toolbar />
        <MainChart />
        <MarketBreakdown />
        <TokenBreakdown />
      </div>
    )
}