import { useState } from "react";
import { DealInfo, Icons } from "../../../models/CreatorsData";
import "./CreatorDeal.scss";
import { CreatorLevelBadge } from "../../creators/CreatorsPage";

interface DealGroup {
  dealId: string;
  deals: DealInfo[];
}

const dateOptions: Intl.DateTimeFormatOptions = { 
  day: 'numeric', 
  month: 'long', // Full month name (e.g., June)
  year: 'numeric' 
};

function formatDate(_date: Date) {
  const date = new Date(_date);

  //console.log(date);

  const formattedDate: string = date.toLocaleDateString(undefined, dateOptions);
  const formattedWithComma: string = formattedDate.replace(/ (\d{4})$/, ', $1');

  return formattedWithComma;
}

export function CreatorDeal({deal, icons, selected}: {deal: DealGroup, icons: Icons, selected: boolean}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(selected);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  if (deal.deals.length == 0) return <></>;
  
  return (
    <div className="creator-deal">
      <div className="creator-deal__header" onClick={toggleDropdown}>
        <div className="creator-deal__header__left">
          <div className="creator-deal__header__casino">
            <p>{deal.deals[0].casino}</p>
          </div>
          <div className="creator-deal__header__badge">
            <CreatorLevelBadge level={deal.deals[0].verificationLevel} />
          </div>
          <div className="creator-deal__header__date">
            <div className="desktop">
              {deal.deals[0].type === "Previous" ? <p>From {formatDate(deal.deals[0].startDate)} to {formatDate(deal.deals[0].endDate as Date)}</p> : <p>Date started: {formatDate(deal.deals[0].startDate)}</p>}
            </div>
            {/* <div className="mobile">
              {deal.deals[0].type === "Previous" ? <p>{new Date(deal.deals[0].startDate).toLocaleDateString()} to {new Date(deal.deals[0].endDate as Date).toLocaleDateString()}</p> : <p>Started: {new Date(deal.deals[0].startDate).toLocaleDateString()}</p>}
            </div> */}
          </div>
        </div>
        <div className={`creator-deal__header__arrow${isDropdownOpen ? " open" : ""}`}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 8" fill="none">
            <path d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z" fill="white"/>
          </svg>
        </div>
      </div>
      {isDropdownOpen && (
        <div className="creator-deal__dropdown">
          {deal.deals.map(x => {

            return (
              <div key={x.dealId} className="creator-deal__site-deal">
                <div className="creator-deal__site-deal__site">
                  <img src={icons[x.platform]}></img>
                  <p>{x.platform} Deal</p>
                </div>
                <div className="creator-deal__site-deal__content">
                  <div className="creator-deal__details">
                    <div className="creator-deal__details__title">
                      <p>Requirements</p>
                    </div>
                    <div className="creator-deal__details__list">
                      {x.requirements.map((req, index) => {
                        return (
                          <div key={index} className="creator-deal__detail">
                            <img src={icons[req.icon] ?? ""}/>
                            <p>{req.description}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                

                  <div className="creator-deal__details">
                    <div className="creator-deal__details__title">
                      <p>Payment</p>
                    </div>
                    <div className="creator-deal__details__list">
                      {x.payments.map((req, index) => {
                        return (
                          <div key={index} className="creator-deal__detail">
                            <img src={icons[req.icon] ?? ""}/>
                            <p>{req.description}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )

          })}
        </div>
      )}
    </div>
  )
}