// ModalContext.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';

interface ModalContextType {
  openModal: (content: ReactNode) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);

  const openModal = (content: ReactNode) => {
    setModalContent(content);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  // Disable body scroll when modal is open
  useEffect(() => {
    if (modalContent) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    
    // Cleanup in case the component unmounts while modal is open
    return () => {
      document.body.style.overflow = '';
    };
  }, [modalContent]);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modalContent &&
        ReactDOM.createPortal(
          <div
            className="modal-overlay"
            onClick={(e) => {
              // Optionally close when clicking outside the modal content
              if (e.target === e.currentTarget) closeModal();
            }}
          >
            {modalContent}
          </div>,
          document.getElementById('root')!
        )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
