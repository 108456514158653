import { useEffect, useRef, useState } from "react";
import homeBackground from "../../../../assets/home-background.mp4";
import "./HeroSection.scss";
import PlinkoGame from "../plinko game/PlinkoGame";
import { NavLink } from "react-router-dom";

export function HeroSection() {
  return (
    <div className="hero">
      <PlinkoGame />
      <div className="hero__background">
        <video autoPlay muted loop playsInline className="hero__background__video">
          <source src={homeBackground} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero__background__overlay"></div>
      </div>
      <div className="hero__slogan">
        <div>
          CREATING THE TANZANITE STANDARD
        </div>
      </div>
      <div className="hero__sub-slogan">
        <div>
          Brought to you with over 10 years of experience in the crypto iGaming
          industry
        </div>
      </div>
      <NavLink to={'/contact'} style={{zIndex: 1}}>
        <div className="button hero__contact">
          <div>
            Get in touch
          </div>
        </div>
      </NavLink>
    </div>
  );
}
