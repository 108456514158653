import { TzTable, TzTableHeader, TzTableRow } from "../../../../components/tz table/TzTable";
import { ChainTimeframeData, DashboardData, EntityData } from "../../../../models/DashboardData";
import { useAnalyticsSettingsContext } from "../../AnalyticsSettingsContext";
import { ToSmallNumber } from "../../AnalyticsUtility";
import { DashSectionHeader } from "../section header/DashSectionHeader";
import "./Breakdowns.scss";

export function MarketBreakdown() {
  const { dashboardData, selectedCasinos, selectedChains, timeframe } = useAnalyticsSettingsContext();


 const headers: TzTableHeader[] = [
    { label: <p>#</p>, align: "left", width: "10%", mobileWidth: "6%" },
    { label: <p>Casino</p>, align: "left", width: "10%", mobileWidth: "12%" },
    { label: <p>Market Share</p>, align: "right", width: "17.5%", mobileWidth: "13%", sortable: true },
    { label: <p>Deposit Volume</p>, align: "right", width: "20%" },
    { label: <p>Deposits</p>, align: "right", width: "19%", sortable: true, mobileWidth: "17%" },
    { label: <p>New Depositors</p>, align: "right", sortable: true, mobileWidth: "23%" },
  ];

  const data = selectedCasinos.map(casino => {
    const casinoData = dashboardData.entities.find(entity => entity.id === casino) as EntityData;

    //console.log(casinoData?.chains);

    return {
      id: casinoData.id,
      name: casinoData.name,
      color: casinoData.color,
      iconUrl: casinoData?.iconUrl,
      //@ts-ignore
      data: (casinoData?.chains.find(chain => chain.chain === "AGG")?.[timeframe]) as ChainTimeframeData
    }
  }).sort((a, b) => b.data.analytics.depositedUsd - a.data.analytics.depositedUsd);

  const totalDeposited = data.reduce((acc, casino) => acc + casino.data.analytics.depositedUsd, 0);

  const rowData = data.map((casino, index) => {
    //console.log(casino);

    const marketShare = (casino.data.analytics.depositedUsd / totalDeposited * 100).toFixed(2) + "%"; // Round to 1 decimal place
    const deposited  = "$" + ToSmallNumber(casino.data.analytics.depositedUsd);
    const deposits = ToSmallNumber(casino.data.analytics.deposits);
    const uniqueDepositors = ToSmallNumber(casino.data.analytics.uniqueDepositors);

    const row: TzTableRow =
      {
        key: index,
        cells: 
        [
          { 
            content: <p>{index + 1}</p>
          },
          { 
            content: <div className="audits-table__casino"><img src={casino.iconUrl}/><p>{casino.name}</p></div> 
          },
          { 
            content: <p>{marketShare}</p>,
            sortValue: casino.data.analytics.depositedUsd / totalDeposited
          },
          { 
            content: <p>{deposited}</p>,
            sortValue: casino.data.analytics.depositedUsd
          },
          { 
            content: <p>{deposits}</p>,
            sortValue: casino.data.analytics.deposits
          },
          { 
            content: <p>{uniqueDepositors}</p>,
            sortValue: casino.data.analytics.uniqueDepositors
          },
        ],
      }

    return row;
  })

  return (
    <div className="dashboard-section market-breakdown">
      <DashSectionHeader title={"Market Breakdown"} />
      <TzTable headers={headers} rowData={rowData} tableClassName="tz-breakdown-table" />
    </div>
  )
}