import { Select, SelectOption } from "../../../../components/custom select/CustomSelect";
import { DashboardData } from "../../../../models/DashboardData";
import { useAnalyticsSettingsContext } from "../../AnalyticsSettingsContext";
import "./Toolbar.scss";

function LastUpdatedText({dashboardData}: {dashboardData: DashboardData}) {
  if (!dashboardData) return <p>Loading...</p>;

  const lastUpdate = new Date(dashboardData.lastUpdateEnd);
  const currentTime = new Date();

  const millisecondsDiff = currentTime.getTime() - lastUpdate.getTime();
  const minutes = Math.ceil(millisecondsDiff / 60000);
  const hours = Math.floor(minutes / 60);

  const diff = hours > 0 ? `${hours}h ${minutes % 60}m` : `${minutes}m`;

  return <p>Last updated: {diff} ago</p>;
}

export function Toolbar() {
  const { dashboardData, setDashboardData, selectedCasinos, setSelectedCasinos, selectedChains, setSelectedChains, timeframe, setTimeframe } = useAnalyticsSettingsContext();
  
  const chainOrder = ["ETH", "SOL", "BSC", "TRX"];

  const casinoOptions = !dashboardData ? [] : dashboardData.entities.map((entity) => ({
    value: entity.id,
    label: entity.name,
    leftIcon: <img src={entity.iconUrl} />,
    rightIcons: entity.chains.filter(x => x.addresses.length > 0 && x.chain !== "AGG").map((chain) => chain.chain).sort((a, b) => chainOrder.indexOf(a) - chainOrder.indexOf(b)).map(chain => (
      <img src={dashboardData?.chainInfo.find(x => x.chain == chain)?.iconUrl} alt={`Icon ${chain}`} />
    ))
  }));

  const chainOptions = !dashboardData ? [] : dashboardData.chainInfo.map((chain) => ({
    value: chain.chain,
    label: chain.chain,
    leftIcon: <img src={chain.iconUrl} />,
  })).sort((a, b) => chainOrder.indexOf(a.value) - chainOrder.indexOf(b.value));

  return (
    <div className="toolbar-container">
      <div className="dashboard__last-updated">
        <LastUpdatedText dashboardData={dashboardData} />
      </div>
      <div className="dashboard-section toolbar">
        <div className="toolbar__left">
          <div className="toolbar__variable">
            <p>Casino</p>
            <Select
              options={casinoOptions}
              value={selectedCasinos}
              onChange={setSelectedCasinos}
              multiple
              placeholder="Select casinos"
              maxDisplayItems={2}
            />
          </div>
          <div className="toolbar__variable">
            <p>Chain</p>
            <Select
              options={chainOptions}
              value={selectedChains}
              onChange={setSelectedChains}
              multiple
              placeholder="Select chains"
              maxDisplayItems={5}
            />
          </div>
        </div>
        <div className="toolbar__right">
          <div className="toolbar__timeframes">
            <div className={`button toolbar__timeframe-button ${timeframe === "week" ? "active" : ''}`} onClick={() => setTimeframe("week")}>
              <p>Week</p>
            </div>
            <div className={`button toolbar__timeframe-button ${timeframe === "month" ? "active" : ''}`} onClick={() => setTimeframe("month")}>
              <p>Month</p>
            </div>
            <div className={`button toolbar__timeframe-button ${timeframe === "year" ? "active" : ''}`} onClick={() => setTimeframe("year")}>
              <p>Year</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}