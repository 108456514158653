import React, { useMemo, useState } from "react";
import { TzTable, TzTableCell, TzTableHeader, TzTableRow } from "../../../components/tz table/TzTable";
import { useBetHistory } from "../../../BetHistoryStateProvider";
import { GameData } from "../../../models/GameData";
import { formatLongInfo, formatUnixTimestamp } from "../ProvablyFair";
import "./HistorySection.scss";
import { VerifySection } from "../verify section/VerifySection";
import { VerifyModal } from "../verify modal/VerifyModal";

export function HistorySection({setSelectedBet, openVerifyModal}: {setSelectedBet: React.Dispatch<React.SetStateAction<GameData | null>>, openVerifyModal: () => void}) {
  const [selectedHistoryTable, setSelectedHistoryTable] = useState("Plinko");

  const extractTextFromP = (cell: React.ReactNode): string => {
    if (React.isValidElement(cell) && cell.type === 'p') {
      // If the children are not a string, you might need to adjust this logic
      //@ts-ignore
      return typeof cell.props.children === 'string' ? cell.props.children : '';
    }
    return typeof cell === 'string' ? cell : '';
  };

  const { bets, isLoading, isError, error, isUninitialized } = useBetHistory();

  const headers: TzTableHeader[] = [
    { label: <p>Nonce</p>, align: "left", width: "14em", mobileWidth: "11em" },
    { label: <p>Time</p>, align: "left", hideOnMobile: true, width: "21em" },
    { label: <p>Request</p>, align: "left", hideOnMobile: true, width: "27em" },
    { label: <p>Result</p>, align: "left" },
    { label: "", align: "right" }
  ];

  const { plinkoData, rouletteData } = useMemo(() => {
    const plinkoRows: TzTableRow[] = [];
    const rouletteRows: TzTableRow[] = [];

    (bets ?? []).forEach((x: GameData) => {
      const row: TzTableRow = {
        key: x.nonce,
        cells: [
          { content: <p>{x.nonce}</p> },
          { content: <p>{formatUnixTimestamp(x.timestamp)}</p> },
          { content: <p>{formatLongInfo(x.request, 0, 20)}</p> },
          { content: <div className="pf-section--history__plinko-result"><p>{x.outcome}</p></div>},
          { content: <div
            className="button pf-section--history__verify"
            onClick={() => {
              setSelectedBet(x);
              openVerifyModal();
            }}
          >
            <p>Verify</p>
          </div>}
        ]
      }

      if (x.game === "plinko") {
        plinkoRows.push(row);
      } else if (x.game === "roulette") {
        rouletteRows.push(row);
      }
    });
    return { plinkoData: plinkoRows, rouletteData: rouletteRows };
  }, [bets]);

  return (
    <div className="pf-section pf-section--history">
      <div className="pf-section__title">
        <h1>HISTORY</h1>
      </div>

      <div className="pf-section__game-selection">
        <div
          className={`button pf-section__game-selection-button${selectedHistoryTable === "Plinko" ? " selected" : ""}`}
          onClick={() => setSelectedHistoryTable("Plinko")}
        >
          <p>Gem Plinko</p>
        </div>
        <div
          className={`button pf-section__game-selection-button${selectedHistoryTable === "Testimonials" ? " selected" : ""}`}
          onClick={() => setSelectedHistoryTable("Testimonials")}
        >
          <p>Testimonial Roulette</p>
        </div>
      </div>

      <div className="pf-section__table">
        {(isLoading || isUninitialized) && <>Loading...</>}
        {isError && <>Error {JSON.stringify(error)}</>}
        {bets && (
          <>
            {selectedHistoryTable === "Plinko" && (
              <TzTable headers={headers} rowData={plinkoData} rowsPerPage={10} tableClassName="tz-pf-table" />
            )}
            {selectedHistoryTable === "Testimonials" && (
              <TzTable headers={headers} rowData={rouletteData} rowsPerPage={10} tableClassName="tz-pf-table" />
            )}
          </>
        )}
      </div>
    </div>
  );
}