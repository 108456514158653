import { useParams } from "react-router-dom";
import { useGetCreatorQuery, useGetCreatorsQuery } from "../../state/api";
import { DealInfo } from "../../models/CreatorsData";
import "./CreatorPage.scss";
import { CreatorLevelBadge } from "../creators/CreatorsPage";
import { CreatorDeal } from "./creator deal/CreatorDeal";
import { CreatorStream } from "./creator stream/CreatorStream";
import { CreatorFAQ } from "../creators/faq/CreatorFAQ";
import { LoadingSpinner } from "../../components/middleware states/LoadingAnimation";
import { ErrorMessage } from "../../components/middleware states/ErrorMessage";

export function CreatorPage() {
  const { creatorId } = useParams();

  const { data, isLoading: isCreatorsDataLoading, isError: isCreatorsDataError } = useGetCreatorsQuery();

  const creator = data?.creators.find(x => x.name.toLowerCase() === creatorId?.toLowerCase())
  const kickUrl = creator?.socials.find(x => x.platform === "Kick")?.link;
  const kickUsername = kickUrl === undefined ? "" : kickUrl.split("/")[kickUrl.split("/").length - 1];

  const { data: streamData, isLoading: isStreamDataLoading, isError: isStreamDataError } = useGetCreatorQuery(kickUsername);

  if (isCreatorsDataError) {
    return (
      <div className="creator-page">
        <div className="creator-data">
          <ErrorMessage message="There was an error loading this creator's data" />
        </div>
      </div>
    );
  }


  if (isCreatorsDataLoading || isStreamDataLoading) {
    return (
      <div className="creator-page">
        <div className="creator-data">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (!data || !creator) {
    return (
      <div className="creator-page">
        <div className="creator-data">
          <ErrorMessage message="Creator not found" />
        </div>
      </div>
    );
  }

  const dealList = creator.deals;

  const groupedDeals = dealList.reduce((acc, deal) => {
    if (deal.verificationLevel > 0) {
        if (!acc[deal.dealId]) {
            acc[deal.dealId] = [];
        }
        acc[deal.dealId].push(deal);
    }
    return acc;
  }, {} as Record<string, DealInfo[]>);

  // Step 2: Sort dealIds in ascending order
  const sortedDealIds = Object.keys(groupedDeals).sort((a, b) => a.localeCompare(b));

  // Step 3: Create a sorted grouped array
  const sortedGroupedDeals = sortedDealIds.map(dealId => ({
      dealId,
      deals: groupedDeals[dealId],
  }));

  const { currentDealGroups, previousDealGroups } = sortedGroupedDeals.reduce(
    (result, group) => {
        // Check the type of the first deal in each group
        if (group.deals[0]?.type === "Current") {
            result.currentDealGroups.push(group);
        } else {
            result.previousDealGroups.push(group);
        }
        return result;
    },
    { currentDealGroups: [], previousDealGroups: [] } as { currentDealGroups: typeof sortedGroupedDeals; previousDealGroups: typeof sortedGroupedDeals }
);

  const bestDeal = dealList.filter(x => x.type == "Current").reduce((highest, deal) => {
    return deal.verificationLevel > highest.verificationLevel ? deal : highest;
  }, dealList.filter(x => x.type == "Current")[0]);

  return (
    <div className="creator-page">
      <div className="creator-data">
        <div className="creator-data__left">
          <div className="creator-data__card creator-data__card--profile">
            <div className="creator-data__card--profile__pfp">
              <img src={creator.pfp ?? ""}/>
              <div className="creator-data__card--profile__pfp__badge">
                <CreatorLevelBadge level={bestDeal.verificationLevel} />
              </div>
            </div>
            <div className="creator-data__card--profile__name">
              <p>{creator.name}</p>
            </div>
            <div className="creator-data__card--profile__socials">
              {creator.socials.map((social, index)  => {
                return (
                  <a key={index} href={social.link} target="_blank" rel="noopener noreferrer">
                    <div className="button creator-data__card--profile__social">
                      <img src={data?.icons[social.platform] ?? ""}/>
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
          <div className="creator-data__card creator-data__card--level">
            <div className={`creator-level ${bestDeal.verificationLevel === 0 ? " highlighted" : ""}`}>
              <div className="creator-level__left">
                <p>Level</p>
                <div className="creator-level__badge">
                  <CreatorLevelBadge level={0} />
                </div>
              </div>
              <div className="creator-level__right">
                <p>The deal has not been verified by Tanzanite</p>
              </div>
            </div>
            <div className={`creator-level ${bestDeal.verificationLevel === 1 ? " highlighted" : ""}`}>
              <div className="creator-level__left">
                <p>Level</p>
                <div className="creator-level__badge">
                  <CreatorLevelBadge level={1} />
                </div>
              </div>
              <div className="creator-level__right">
                <p>Deal details have been confirmed by the creator and the casino.</p>
              </div>
            </div>
            <div className={`creator-level ${bestDeal.verificationLevel === 2 ? " highlighted" : ""}`}>
              <div className="creator-level__left">
                <p>Level</p>
                <div className="creator-level__badge">
                  <CreatorLevelBadge level={2} />
                </div>
              </div>
              <div className="creator-level__right">
                <p>Tanzanite has verified the terms of the deal.</p>
              </div>
            </div>
            <div className={`creator-level ${bestDeal.verificationLevel === 3 ? " highlighted" : ""}`}>
              <div className="creator-level__left">
                <p>Level</p>
                <div className="creator-level__badge">
                  <CreatorLevelBadge level={3} />
                </div>
              </div>
              <div className="creator-level__right">
                <p>Advanced verification is coming soon...</p>
              </div>
            </div>
          </div>
        </div>
        <div className="creator-data__right">
          <div className="creator-data__card creator-data__about">
            <div className="creator-data__about__bio">
              <div className="creator-data__about__bio__title">
                <h1>ABOUT {creator.name.toUpperCase()}</h1>
              </div>
              <p>{creator.about.replaceAll("\"", "")}</p>
            </div>
            <div className="creator-data__about__socials">
              <h1>SOCIALS</h1>
              <div className="creator-data__about__socials-list">
                {creator.socials.map((social, index)  => {
                  return (
                    <a key={index} href={social.link} target="_blank" rel="noopener noreferrer">
                      <div className="creator-data__about__social button">
                        <div className="creator-data__about__social__icon">
                          <img src={data?.icons[social.platform] ?? ""}/>
                        </div>
                        <p>{social.platform}</p>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="creator-data__card creator-data__card--current-deals">
            <div className="creator-deals__header">
              <h1>CURRENT DEALS</h1>
              <p>Overview of all the current deals that this streamer has</p>
            </div>
            <div className="creator-deals__list">
              {currentDealGroups.map((x, index)  => {
                return <CreatorDeal key={index} icons={data.icons} deal={x} selected={index === 0} />
              })}
              {currentDealGroups.length == 0 ? (
                <div className="creator-deals__no-data">
                  <p>No deals verified by Tanzanite</p>
                </div>
              ) : <></>}
            </div>
          </div>
          <div className="creator-data__card creator-data__card--previous-deals">
            <div className="creator-deals__header">
              <h1>DEAL HISTORY</h1>
              <p>Overview of past deals that this streamer has verified with Tanzanite</p>
            </div>
            <div className="creator-deals__list">
              {previousDealGroups.map((x, index)  => {
                    return <CreatorDeal key={index} icons={data.icons} deal={x} selected={index === 0}/>
              })}
              {previousDealGroups.length == 0 ? (
                <div className="creator-deals__no-data">
                  <p>No deal history recorded</p>
                </div>
              ) : <></>}
            </div>
          </div>
          <div className="creator-data__card creator-data__card--streams">
            <div className="creator-deals__header">
              <h1>STREAMS</h1>
              <p>Analytics of the recent streams by this creator in the "Slots & Casino" category</p>
            </div>
            <div className="creator-deals__list">
              {!streamData || streamData?.kickStreams.length === 0 ?  <div className="creator-deals__no-data"><p>No Streams Found</p></div>  : <></>}
              {streamData?.kickStreams.map((kickStream, index) => (
                <CreatorStream kickStream={kickStream} key={index} selected={index === 0}/>
              ))}
            </div>
          </div>
        </div>
      </div>
      <CreatorFAQ />
    </div>
  )  
}