import "./DiscordCTA.scss";

export function DiscordCTA() {
  return (
    <div className="discord-cta">
      <div className="discord-cta__title">
        <h1>YOUR EDGE IN iGAMING STARTS HERE</h1>
      </div>
      <div className="discord-cta__desc">
        <p>Gain access to exclusive data-driven insights.</p>
      </div>
      <a href={"https://discord.gg/tanzanite"} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
        <div className="button discord-cta__button">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <path d="M20.8583 5.76772C19.4187 5.09663 17.86 4.60955 16.2364 4.32812C16.2079 4.32853 16.1806 4.3402 16.1607 4.3606C15.9658 4.71779 15.7385 5.18322 15.587 5.54042C13.8649 5.2808 12.1136 5.2808 10.3914 5.54042C10.2399 5.1724 10.0126 4.71779 9.80695 4.3606C9.79613 4.33895 9.76365 4.32812 9.73118 4.32812C8.10758 4.60955 6.55974 5.09663 5.10932 5.76772C5.0985 5.76772 5.08768 5.77854 5.07685 5.78937C2.13272 10.1947 1.32092 14.4811 1.72141 18.7241C1.72141 18.7457 1.73223 18.7674 1.75388 18.7782C3.7022 20.207 5.57476 21.0729 7.42567 21.6466C7.45814 21.6574 7.49061 21.6466 7.50143 21.6249C7.93439 21.0296 8.32406 20.4018 8.6596 19.7415C8.68125 19.6982 8.6596 19.6549 8.61631 19.6441C7.99934 19.406 7.41484 19.1246 6.84117 18.7998C6.79787 18.7782 6.79787 18.7133 6.83034 18.6808C6.94941 18.5942 7.06847 18.4968 7.18754 18.4102C7.20918 18.3885 7.24166 18.3885 7.26331 18.3994C10.9868 20.0987 15.0025 20.0987 18.6826 18.3994C18.7043 18.3885 18.7368 18.3885 18.7584 18.4102C18.8775 18.5076 18.9965 18.5942 19.1156 18.6916C19.1589 18.7241 19.1589 18.789 19.1048 18.8107C18.5419 19.1462 17.9466 19.4168 17.3296 19.6549C17.2864 19.6658 17.2755 19.7199 17.2864 19.7524C17.6327 20.4126 18.0224 21.0404 18.4445 21.6357C18.477 21.6466 18.5095 21.6574 18.5419 21.6466C20.4037 21.0729 22.2762 20.207 24.2245 18.7782C24.2462 18.7674 24.257 18.7457 24.257 18.7241C24.7333 13.8208 23.4669 9.56695 20.9016 5.78937C20.8907 5.77854 20.8799 5.76772 20.8583 5.76772ZM9.22245 16.1371C8.10758 16.1371 7.17671 15.1089 7.17671 13.8424C7.17671 12.576 8.08593 11.5477 9.22245 11.5477C10.3698 11.5477 11.279 12.5869 11.2682 13.8424C11.2682 15.1089 10.359 16.1371 9.22245 16.1371ZM16.7668 16.1371C15.6519 16.1371 14.7211 15.1089 14.7211 13.8424C14.7211 12.576 15.6303 11.5477 16.7668 11.5477C17.9141 11.5477 18.8234 12.5869 18.8125 13.8424C18.8125 15.1089 17.9141 16.1371 16.7668 16.1371Z" fill="white"/>
          </svg>
          <p>
            Join our Discord
          </p>
        </div>
      </a>
    </div>
  )
}