import { useState } from "react";
import { ProvablyFair } from "../../pages/provably fair/ProvablyFair";
import "./PlayButton.scss";

export function PlayButton({play, disabled}: {play: boolean, disabled: boolean}) {
  return (
    <div className="play-button-container">
      <div className={`button play-button${disabled ? " disabled" : ""}`}>
        {play && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M5 17V3L16 10L5 17ZM7 13.35L12.25 10L7 6.65V13.35Z" fill="#E8EAED"/>
        </svg>}
        {!play &&<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M6 18V6H18V18H6ZM8 16H16V8H8V16Z" fill="#E8EAED"/>
        </svg>}
        {play && <p>Play</p>}
        {!play && <p>Stop</p>}
      </div>
    </div>
  )
}

export function SpinButton({play, disabled}: {play: boolean, disabled: boolean}) {
  return (
    <div className="play-button-container">
      <div className={`button play-button${disabled ? " disabled" : ""}`}>
        {play && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M5 17V3L16 10L5 17ZM7 13.35L12.25 10L7 6.65V13.35Z" fill="#E8EAED"/>
        </svg>}
        {play && <p>Spin</p>}
        {!play && <p>Spinning</p>}
      </div>
    </div>
  )
}