import "./Supporters.scss";
import StakeLogo from "../../assets/supporters/Stake.png";
import BCGameLogo from "../../assets/supporters/BCGame.png";
import YoloGroupLogo from "../../assets/supporters/YoloGroup.png";

export function Supporters() {
  return (
    <div className="supporters">
      <div className="supporters__title">
        <h1>
          SUPPORTERS
        </h1>
      </div>
      <div className="supporters__desc">
        <p>We remain an independant authority, and our supporters do not influence our scorings, weightings or verification process</p>
      </div>
      <div className="supporters__list">
        <div className="supporters__item">
          <img src={StakeLogo} />
        </div>
        <div className="supporters__item">
          <img src={YoloGroupLogo} />
        </div>
        <div className="supporters__item">
          <img src={BCGameLogo} />
        </div>
      </div>
    </div>
  )
}