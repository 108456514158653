import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./Navbar.scss";
import { Logo } from "../Logo";

export function Navbar() {
  const location = useLocation();
  const navPaths = ["/audits", "/creators", "/analytics", "/blog"];
  const hasActive = navPaths.includes(location.pathname);

  const isNavActive = (path: string) =>
    location.pathname === path ? " active" : "";

  // Update NavLinkComponent to accept an onClick prop for mobile links
  const NavLinkComponent = ({
    target,
    text,
    mobile = false,
    onClick,
  }: {
    target: string;
    text: string;
    mobile?: boolean;
    onClick?: () => void;
  }) => {
    if (mobile) {
      return (
        <NavLink
          to={target}
          className={`navbar__link navbar__link--mobile${isNavActive(target)}`}
          onClick={onClick}
        >
          <p>{text}</p>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path
              d="M16.175 13H4V11H16.175L10.575 5.4L12 4L20 12L12 20L10.575 18.6L16.175 13Z"
              fill="white"
            />
          </svg>
        </NavLink>
      );
    }

    return (
      <NavLink to={target} className={`navbar__link${isNavActive(target)}`}>
        <p>{text}</p>
      </NavLink>
    );
  };

  // State to handle mobile menu open/close
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="navbar">
      {/* Logo */}
      <div className="navbar__logo">
        <NavLink to="/" onClick={handleCloseMenu}>
          <Logo />
        </NavLink>
      </div>

      {/* Regular desktop links */}
      <div className={`navbar__links ${hasActive ? "has-active" : "none-active"}`}>
        <NavLinkComponent target="/audits" text="Audits" />
        <NavLinkComponent target="/creators" text="Creators" />
        <NavLinkComponent target="/analytics" text="Analytics" />
        <NavLinkComponent target="/blog" text="Blog" />
      </div>

      {/* Desktop "Get in touch" button */}
      <NavLink to="/contact">
        <div className="button navbar__contact">
          <div>Get in touch</div>
        </div>
      </NavLink>

      {/* MOBILE MENU TOGGLE BUTTON */}
      <div className="button navbar__dropdown-button" onClick={handleToggleMenu}>
        {isMenuOpen ? (
          // Close icon
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path
              d="M6 6L18 18M6 18L18 6"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        ) : (
          // Hamburger icon
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path
              d="M3 18V16H21V18H3ZM3 13V11H21V13H3ZM3 8V6H21V8H3Z"
              fill="white"
            />
          </svg>
        )}
      </div>

      {/* MOBILE MENU OVERLAY */}
      {isMenuOpen && <div className="navbar__overlay" onClick={handleCloseMenu} />}

      {/* MOBILE MENU CONTENT */}
      {isMenuOpen && (
        <div className="navbar__dropdown-menu">
          {/* <NavLink
          to={"/"}
          onClick={handleCloseMenu}
          style={{width: "100%"}}
          >
            <div className="navbar__dropdown-menu__title">
              <Logo />
              <h1>TANZANITE</h1>
            </div>
          </NavLink> */}
          <div className="navbar__dropdown-menu__links">
            <NavLinkComponent
              target="/audits"
              text="Audits"
              mobile={true}
              onClick={handleCloseMenu}
            />
            <div className="navbar__dropdown-menu__seperator"></div>
            <NavLinkComponent
              target="/creators"
              text="Creators"
              mobile={true}
              onClick={handleCloseMenu}
            />
            <div className="navbar__dropdown-menu__seperator"></div>
            <NavLinkComponent
              target="/analytics"
              text="Analytics"
              mobile={true}
              onClick={handleCloseMenu}
            />
            <div className="navbar__dropdown-menu__seperator"></div>
            <NavLinkComponent
              target="/blog"
              text="Blog"
              mobile={true}
              onClick={handleCloseMenu}
            />
          </div>

          <NavLink to="/contact" onClick={handleCloseMenu}>
            <div className="button navbar__contact">
              <div>Get in touch</div>
            </div>
          </NavLink>
        </div>
      )}
    </div>
  );
}
