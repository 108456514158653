// VerifyModal.tsx
import React from "react";
import { VerifySection } from "../verify section/VerifySection";
import { GameData } from "../../../models/GameData";
import PinkScroll from "../../../components/pink scroll/PinkScroll";
import SimpleBar from "simplebar-react";

interface VerifyModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedBet: GameData | null;
}

export function VerifyModal({ isOpen, onClose, selectedBet }: VerifyModalProps) {
  if (!isOpen || !selectedBet) return null;

  const handleOuterOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOuterOverlayClick} style={{zIndex: 1000}}>
      <div className="verify-modal">
        <div className="button verify-modal__close">
          <svg onClick={onClose} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none">
              <path d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z" fill="white"/>
          </svg>
        </div>
        <SimpleBar
              autoHide={false}
              style={{ width: '100%', height: '100%' }}
              className="provably-fair__scroll"
            >
          {/* Pass the selected bet's properties to VerifySection */}
          <VerifySection
            _request={selectedBet.request}
            _proof={selectedBet.proof}
            _randomHash={selectedBet.randomHash}
            _selectedGame={selectedBet.game as "plinko" | "roulette"}
          />
        </SimpleBar>
      </div>
    </div>
  );
}