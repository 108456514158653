import { Creator, CreatorData, Icons } from "../../../models/CreatorsData";
import "./LiveSection.scss";
import { NavLink } from "react-router-dom";
import { CreatorLevelBadge } from "../CreatorsPage";
import PinkScroll from "../../../components/pink scroll/PinkScroll";

function LiveCard({creator, icons}: {creator: Creator, icons: Icons}) {
  if (!creator.streamerDeal) return <></>;

  return (
    <div className="live-card">
      <div className="live-card__pfp">
        <img src={creator.pfp ?? ""}/>
        <div className="live-card__badge">
          <CreatorLevelBadge level={creator.streamerDeal.verificationLevel} />
        </div>
      </div>
      <a href={creator.live.link} target="_blank" rel="noopener noreferrer">
        <div className="button live-card__viewers">
          <div className="live-card__viewers__icon"></div>
          <div className="live-card__viewers__text">
            <p>{creator.live.viewers?.toLocaleString() ?? "0"} viewers</p>
          </div>
          <div className="live-card__viewers__link">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
              <path d="M6.66696 3.33073H5.46696C4.72022 3.33073 4.34658 3.33073 4.06136 3.47606C3.81048 3.60388 3.60665 3.80771 3.47882 4.0586C3.3335 4.34381 3.3335 4.71746 3.3335 5.4642V10.5309C3.3335 11.2776 3.3335 11.6508 3.47882 11.936C3.60665 12.1869 3.81048 12.3911 4.06136 12.5189C4.3463 12.6641 4.71949 12.6641 5.46477 12.6641H10.5356C11.2808 12.6641 11.6535 12.6641 11.9384 12.5189C12.1893 12.3911 12.3938 12.1867 12.5216 11.9358C12.6668 11.6509 12.6668 11.2781 12.6668 10.5328V9.33073M13.3335 5.9974V2.66406M13.3335 2.66406H10.0002M13.3335 2.66406L8.66683 7.33073" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
        </div>
      </a>
      <div className="live-card__name">
        <p>{creator.name}</p>
      </div>
      <div className="live-card__casino">
        <img src={icons[creator.streamerDeal.casino] ?? ""}/>
        <p>{creator.streamerDeal.casino}</p>
      </div>
      <NavLink style={{width: "100%"}} to={"/creators/" + creator.name.toLowerCase()}>
        <div className={"button live-card__view-profile"}>
          <p>View Profile</p>
        </div>
      </NavLink>
    </div>
  );
}

export function LiveSection({creatorsData}: {creatorsData: CreatorData | undefined}) {
  const emptyElement = () => {
    return (
      <div className="live-section">
        <div className="">
          No enrolled streamers are live.
        </div>
      </div>
    )
  }

  if (!creatorsData || !creatorsData.creators) return emptyElement();

  const liveStreamers = creatorsData.creators.filter(x => x.live.isLive === true);

  if (liveStreamers === undefined || liveStreamers.length === 0) return emptyElement();

  return (
    <div className="live-section">
      <div className="live-section__header">
        <div className="live-section__title">
          <h1>LIVE NOW</h1>
        </div>
        <div className="live-section__live-circle"></div>
      </div>
      <div className="live-section__content">
        <PinkScroll>
          <div className="live-section__cards">
            {
              liveStreamers.sort((a, b) => {
                if (a.streamerDeal === null || b.streamerDeal === null) return 0; 

                if (a.streamerDeal?.verificationLevel !== b.streamerDeal?.verificationLevel) {
                  return b.streamerDeal.verificationLevel - a.streamerDeal.verificationLevel;
                } else {
                  return b.live.viewers - a.live.viewers; // Secondary sort by followers in descending order
                }
              })
              .map((creator, index) => {
                return <LiveCard key={index} icons={creatorsData.icons} creator={creator} />;
              })
            }
          </div>
        </PinkScroll>
      </div>
    </div>
  )
}