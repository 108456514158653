import React, { useState } from 'react';
import "./BlogCTA.scss";
import { useSubscribeNewsletterMutation } from '../../state/api';

export function BlogCTA() {
  const [email, setEmail] = useState('');
  const [localError, setLocalError] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  // RTK Query mutation hook for newsletter subscription
  const [subscribeNewsletter, { isLoading }] = useSubscribeNewsletterMutation();

  // Simple email validation function
  const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

  const handleSubmit = async () => {
    // Clear any previous errors and messages.
    setLocalError('');
    setResponseMessage('');

    if (!validateEmail(email)) {
      setLocalError('Please enter a valid email address.');
      return;
    }

    try {
      // Set response message to show loading state.
      setResponseMessage('Submitting...');
      
      // Call the subscription mutation.
      const result = await subscribeNewsletter({ email }).unwrap();

      // If the returned boolean is true, subscription was successful.
      if (result) {
        setResponseMessage('Thank you for subscribing!');
      } else {
        setResponseMessage('Subscription failed. Please try again.');
      }
    } catch (err) {
      console.error(err);
      setResponseMessage('Something went wrong. Please try again later.');
    }
  };

  return (
    <div className="blog-cta">
      <div className="blog-cta__desc">
        <p>Gain access to exclusive data-driven insights.</p>
      </div>
      <div className="blog-cta__subscribe">
        <input 
          type="email" 
          placeholder="Your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={localError ? 'error' : ''}
          disabled={isLoading}
        />
        <div 
          className="blog-cta__subscribe-btn"
          onClick={handleSubmit}
          style={{ cursor: 'pointer' }}
        >
          <p>Subscribe</p>
        </div>
      </div>
      {localError && (
        <div className="blog-cta__error" style={{ color: '#CC3055' }}>
          <p>{localError}</p>
        </div>
      )}
      {responseMessage && (
        <div className="blog-cta__response">
          <p>{responseMessage}</p>
        </div>
      )}
    </div>
  );
}
