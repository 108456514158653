import React from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPrev: () => void;
  onNext: () => void;
}

export const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPrev, onNext }) => {
  // Optionally, don't render if there's only one page.

  return (
    <div className="tz-table-pagination">
      <div className="tz-table-pagination__buttons">
        <button className="button" disabled={currentPage <= 1} onClick={onPrev}>
          <p>Previous</p>
        </button>
        <button className="button" disabled={currentPage >= totalPages} onClick={onNext}>
          <p>Next</p>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
