import "./Header.scss";

export function Header({ title, desc }: {title: string, desc: string}) {
  return (
    <div className="header">
      <div className="header__title">
        <h1>{title}</h1>
      </div>
      <div className="header__desc">
        <p>{desc}</p>
      </div>
    </div>
  )
}