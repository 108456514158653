import { useGetBlogsQuery } from "../../state/api";
import "./BlogStrip.scss";
import { BlogCard } from "./BlogCard";
import { BlogCTA } from "./BlogCTA";
import { useEffect, useRef } from 'react';
import PinkScroll from "../pink scroll/PinkScroll";
import { ErrorMessage } from "../middleware states/ErrorMessage";
import { LoadingSpinner } from "../middleware states/LoadingAnimation";

export function BlogStrip() {
  const { data, isLoading, isError } = useGetBlogsQuery(0);
  // const scrollbarsRef = useRef<Scrollbars | null>(null);

  // const handleWheel = (e: WheelEvent) => {
  //   if (scrollbarsRef.current) {
  //     // Use a type cast so that TypeScript lets us access the underlying view.
  //     const view = (scrollbarsRef.current as any).view as HTMLElement;
  //     if (view) {
  //       // Use the vertical wheel delta to scroll horizontally
  //       const currentScrollLeft = scrollbarsRef.current.getScrollLeft();
  //       scrollbarsRef.current.scrollLeft(currentScrollLeft + e.deltaY);
  //       // Prevent default scrolling on the page.
  //       e.preventDefault();
  //       e.stopPropagation();
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const view = (scrollbarsRef.current as any)?.view as HTMLElement;
  //   if (view) {
  //     // Attach our native wheel event listener with passive: false
  //     view.addEventListener('wheel', handleWheel, { passive: false });
  //   }
  //   return () => {
  //     if (view) {
  //       view.removeEventListener('wheel', handleWheel);
  //     }
  //   };
  // }, []);

    if (isError) {
      return (
        <div className="blog">
          <div className="blog__content">
            <div className="blog__title">
              <h1>NEWS</h1>
            </div>
            <BlogCTA />
            <div className="blog__cards-container">
              <ErrorMessage message="There was an error loading blogs" />
            </div>
          </div>
        </div>
      );
    }
  
    if (isLoading) {
      return (
        <div className="blog">
          <div className="blog__content">
            <div className="blog__title">
              <h1>NEWS</h1>
            </div>
            <BlogCTA />
            <div className="blog__cards-container">
              <LoadingSpinner />
            </div>
          </div>
        </div>
      );
    }

  return (
    <div className="blog">
      <div className="blog__content">
        <div className="blog__title">
          <h1>NEWS</h1>
        </div>
        <BlogCTA />
        <div className="blog__cards-container">
          <PinkScroll>
            <div className="blog__cards" style={{ display: 'flex' }}>
              {data?.posts?.slice(0, 12).map((post) => (
                <BlogCard key={post.id} post={post} />
              ))}
            </div>
          </PinkScroll>
        </div>
      </div>
    </div>
  );
}
