import React, { useState, useEffect } from 'react';
import "./TzTable.scss";
import SortIcon from './SortIcon';
import Pagination from './Pagination';

export interface TzTableHeader {
  label: React.ReactNode;
  align: "left" | "center" | "right" | "justify";
  hideOnMobile?: boolean;
  width?: string;       // e.g. "150px", "20%"
  mobileWidth?: string; // e.g. "100px", "50%"
  sortable?: boolean;   // Mark column as sortable
}

export interface TzTableCell {
  content: React.ReactNode;
  sortValue?: string | number; // Optional: if not provided, sorting on this cell won't work
}

// Extend the row interface to include an optional dropdown content.
export interface TzTableRow {
  key: string | number;
  cells: TzTableCell[];
  dropdownContent?: React.ReactNode;
}

interface TzTableProps {
  headers: TzTableHeader[];
  rowData: TzTableRow[]; // Array of rows
  rowsPerPage?: number;
  tableClassName?: string; // Optional class name for the whole table container
  defaultSortDirection?: "asc" | "desc"; // Optional: initial sort direction
  defaultSortColumn?: number;          // Optional: index of column to sort by default
  searchTerm?: string;   // Optional search term to filter the table rows
  showPagination?: boolean;  // Optional: whether to show pagination controls or not
}

export function TzTable({ 
  headers, 
  rowData, 
  rowsPerPage, 
  tableClassName,
  defaultSortDirection = "desc",
  defaultSortColumn,
  searchTerm,
  // If showPagination is not provided, default to true when rowsPerPage is set
  showPagination = !!rowsPerPage
}: TzTableProps) {
  // Determine the first sortable column as default (if any)
  const sortedColumnIndex = headers.findIndex(x => x.sortable === true);
  const firstSortedColumn = sortedColumnIndex === -1 ? null : sortedColumnIndex;
  
  // Use provided defaultSortColumn if valid, else fallback to the first sortable column
  const initialSortedColumn =
    typeof defaultSortColumn === "number" && headers[defaultSortColumn]?.sortable
      ? defaultSortColumn
      : firstSortedColumn;

  const [currentPage, setCurrentPage] = useState(1);
  const [sortedColumn, setSortedColumn] = useState<number | null>(initialSortedColumn);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">(defaultSortDirection);
  // Allow multiple rows to be expanded by keeping an array of keys.
  const [expandedRowKeys, setExpandedRowKeys] = useState<Array<string | number>>([]);

  // First filter rows based on the searchTerm (if provided)
  const filteredRowData = React.useMemo(() => {
    if (!searchTerm) return rowData;
    const lowerSearchTerm = searchTerm.toLowerCase();
    return rowData.filter(row => 
      row.cells.some(cell => {
        // Use sortValue if available, otherwise check if content is a string.
        const value = cell.sortValue !== undefined 
          ? String(cell.sortValue) 
          : (typeof cell.content === "string" ? cell.content : "");
        return value.toLowerCase().includes(lowerSearchTerm);
      })
    );
  }, [rowData, searchTerm]);

  const totalPages = rowsPerPage ? Math.ceil(filteredRowData.length / rowsPerPage) : 1;

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [filteredRowData, rowsPerPage, totalPages, currentPage]);

  const handleSort = (colIndex: number) => {
    if (sortedColumn === colIndex) {
      setSortDirection(prev => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortedColumn(colIndex);
      // Reset to the default sort direction when switching columns
      setSortDirection(defaultSortDirection);
    }
    setCurrentPage(1);
  };

  const sortedRowData = React.useMemo(() => {
    if (sortedColumn === null) return filteredRowData;
    return [...filteredRowData].sort((a, b) => {
      const aVal = a.cells[sortedColumn].sortValue;
      const bVal = b.cells[sortedColumn].sortValue;
      // If either cell's sortValue is undefined, leave order unchanged.
      if (aVal === undefined || bVal === undefined) return 0;
      if (aVal < bVal) return sortDirection === "asc" ? -1 : 1;
      if (aVal > bVal) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });
  }, [filteredRowData, sortedColumn, sortDirection]);

  const displayedRows = rowsPerPage 
    ? sortedRowData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
    : sortedRowData;

  const handlePrev = () => {
    if (currentPage > 1) setCurrentPage(prev => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(prev => prev + 1);
  };

  const toggleExpanded = (key: string | number) => {
    setExpandedRowKeys(prev => 
      prev.includes(key)
        ? prev.filter(item => item !== key)
        : [...prev, key]
    );
  };

  return (
    <div className={`tz-table-container ${tableClassName ? tableClassName : ''}`}>
      <div className="tz-table-scroll-container">
        <table className='tz-table'>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th
                  key={index}
                  style={{
                    textAlign: header.align,
                    width: header.width,
                    "--mobile-width": header.mobileWidth || header.width,
                  } as React.CSSProperties}
                  className={header.hideOnMobile ? "desktop" : ""}
                  onClick={header.sortable ? () => handleSort(index) : undefined}
                >
                  {header.sortable ? (
                    <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: header.align }}>
                      <SortIcon isSorted={sortedColumn === index} sortDirection={sortDirection} />
                      <div>{header.label}</div>
                    </div>
                  ) : (
                    <div>{header.label}</div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {displayedRows.map(row => (
              <React.Fragment key={row.key}>
                <tr
                  className={`${row.dropdownContent ? 'clickable' : ''} ${expandedRowKeys.includes(row.key) ? 'expanded' : ''}`}
                  onClick={() => { if (row.dropdownContent) toggleExpanded(row.key); }}
                >
                  {row.cells.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      style={{
                        width: headers[cellIndex].width,
                        "--mobile-width": headers[cellIndex].mobileWidth || headers[cellIndex].width,
                      } as React.CSSProperties}
                      className={headers[cellIndex].hideOnMobile ? "desktop" : ""}
                    >
                      <div style={{ display: "flex", justifyContent: headers[cellIndex].align }}>
                        {cell.content}
                      </div>
                    </td>
                  ))}
                </tr>
                {expandedRowKeys.includes(row.key) && row.dropdownContent && (
                  <tr className="tz-table-dropdown expanded" key={`${row.key}-dropdown`}>
                    <td colSpan={headers.length}>
                      <div className="dropdown-content">
                        {row.dropdownContent}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {showPagination && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPrev={handlePrev}
          onNext={handleNext}
        />
      )}
    </div>
  );
}
