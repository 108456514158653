import { Header } from "../../components/header/Header";
import { ErrorMessage } from "../../components/middleware states/ErrorMessage";
import { LoadingSpinner } from "../../components/middleware states/LoadingAnimation";
import { CreatorData } from "../../models/CreatorsData";
import { useGetCreatorsQuery } from "../../state/api";
import { LiveSection } from "./live section/LiveSection";
import "./CreatorsPage.scss";
import { CreatorOverview } from "./overview/CreatorOverview";
import { CreatorCTA } from "./creator cta/CreatorCTA";
import { CreatorFAQ } from "./faq/CreatorFAQ";

export function CreatorLevelBadge({level}: {level: number}) {

  const level0SVG = (
    <svg width="36" height="45" viewBox="0 0 36 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.5907 0.656938C19.1688 0.235128 18.5966 -0.00183105 18 -0.00183105C17.4034 -0.00183105 16.8312 0.235128 16.4093 0.656938C14.5868 2.47944 11.97 3.55944 9.2475 4.12194C6.543 4.67994 3.96675 4.67544 2.4975 4.51119C2.18305 4.47639 1.8648 4.50829 1.56351 4.60482C1.26223 4.70134 0.984688 4.86032 0.749006 5.07137C0.513323 5.28243 0.324798 5.54081 0.195737 5.82966C0.0666753 6.1185 -2.16385e-05 6.43132 5.26601e-09 6.74769V26.9977C5.26601e-09 29.9227 1.278 32.5079 2.934 34.6364C4.5945 36.7694 6.75225 38.6032 8.81775 40.0792C11.4057 41.909 14.1633 43.4859 17.0528 44.7884L17.0933 44.8064L17.1067 44.8132H17.1135C17.3935 44.9336 17.695 44.9959 17.9998 44.9963C18.3045 44.9967 18.6062 44.9352 18.8865 44.8154L18 42.7477C18.8865 44.8177 18.8888 44.8154 18.8888 44.8154L18.8933 44.8132L18.9045 44.8064L18.9495 44.7884L19.0958 44.7232C19.9087 44.3527 20.7115 43.9603 21.5033 43.5464C23.4719 42.5194 25.3692 41.361 27.1822 40.0792C29.2477 38.6032 31.4055 36.7717 33.066 34.6364C34.722 32.5102 36 29.9227 36 26.9977V6.74769C36 6.43132 35.9333 6.1185 35.8043 5.82966C35.6752 5.54081 35.4867 5.28243 35.251 5.07137C35.0153 4.86032 34.7378 4.70134 34.4365 4.60482C34.1352 4.50829 33.8169 4.47639 33.5025 4.51119C32.0332 4.67544 29.457 4.67994 26.7525 4.12194C24.03 3.55944 21.4132 2.47944 19.5907 0.656938Z" fill="url(#paint0_linear_2032_8912)"/>
      <path d="M17.496 32.4794C15.6636 32.4723 14.0869 32.0213 12.7659 31.1264C11.452 30.2315 10.4399 28.9354 9.72967 27.2379C9.02654 25.5405 8.67853 23.4986 8.68564 21.1122C8.68564 18.733 9.0372 16.7053 9.74032 15.0291C10.4506 13.353 11.4626 12.0781 12.7765 11.2045C14.0976 10.3239 15.6707 9.88352 17.496 9.88352C19.3213 9.88352 20.8909 10.3239 22.2048 11.2045C23.5258 12.0852 24.5415 13.3636 25.2517 15.0398C25.9619 16.7088 26.3135 18.733 26.3064 21.1122C26.3064 23.5057 25.9513 25.5511 25.241 27.2486C24.5379 28.946 23.5294 30.2422 22.2155 31.1371C20.9015 32.032 19.3284 32.4794 17.496 32.4794ZM17.496 28.6548C18.746 28.6548 19.7439 28.0263 20.4896 26.7692C21.2354 25.5121 21.6047 23.6264 21.5976 21.1122C21.5976 19.4574 21.4271 18.0795 21.0862 16.9787C20.7524 15.8778 20.2765 15.0504 19.6586 14.4964C19.0479 13.9425 18.327 13.6655 17.496 13.6655C16.2531 13.6655 15.2588 14.2869 14.5131 15.5298C13.7673 16.7727 13.3909 18.6335 13.3838 21.1122C13.3838 22.7884 13.5507 24.1875 13.8845 25.3097C14.2254 26.4247 14.7048 27.2628 15.3227 27.8239C15.9406 28.3778 16.665 28.6548 17.496 28.6548Z" fill="white"/>
      <defs>
      <linearGradient id="paint0_linear_2032_8912" x1="-11.25" y1="5.62262" x2="48.375" y2="44.9976" gradientUnits="userSpaceOnUse">
      <stop stopColor="#989898"/>
      <stop offset="1" stopColor="#111525"/>
      </linearGradient>
      </defs>
    </svg>
  );

  const level1SVG = (
    <svg width="36" height="45" viewBox="0 0 36 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.5907 0.658769C19.1688 0.236959 18.5966 0 18 0C17.4034 0 16.8312 0.236959 16.4093 0.658769C14.5868 2.48127 11.97 3.56127 9.2475 4.12377C6.543 4.68177 3.96675 4.67727 2.4975 4.51302C2.18305 4.47822 1.8648 4.51012 1.56351 4.60665C1.26223 4.70317 0.984688 4.86215 0.749006 5.0732C0.513323 5.28426 0.324798 5.54264 0.195737 5.83149C0.0666753 6.12033 -2.16385e-05 6.43315 5.26601e-09 6.74952V26.9995C5.26601e-09 29.9245 1.278 32.5098 2.934 34.6383C4.5945 36.7713 6.75225 38.605 8.81775 40.081C11.4057 41.9108 14.1633 43.4878 17.0528 44.7903L17.0933 44.8083L17.1067 44.815H17.1135C17.3935 44.9355 17.695 44.9978 17.9998 44.9981C18.3045 44.9985 18.6062 44.937 18.8865 44.8173L18 42.7495C18.8865 44.8195 18.8888 44.8173 18.8888 44.8173L18.8933 44.815L18.9045 44.8083L18.9495 44.7903L19.0958 44.725C19.9087 44.3546 20.7115 43.9622 21.5033 43.5483C23.4719 42.5212 25.3692 41.3628 27.1822 40.081C29.2477 38.605 31.4055 36.7735 33.066 34.6383C34.722 32.512 36 29.9245 36 26.9995V6.74952C36 6.43315 35.9333 6.12033 35.8043 5.83149C35.6752 5.54264 35.4867 5.28426 35.251 5.0732C35.0153 4.86215 34.7378 4.70317 34.4365 4.60665C34.1352 4.51012 33.8169 4.47822 33.5025 4.51302C32.0332 4.67727 29.457 4.68177 26.7525 4.12377C24.03 3.56127 21.4132 2.48127 19.5907 0.658769Z" fill="url(#paint0_linear_2005_8354)"/>
      <path d="M21.4218 11.1818V33H16.8089V15.5604H16.681L11.6846 18.6925V14.6016L17.0858 11.1818H21.4218Z" fill="white"/>
      <defs>
      <linearGradient id="paint0_linear_2005_8354" x1="-11.25" y1="5.62445" x2="48.375" y2="44.9995" gradientUnits="userSpaceOnUse">
      <stop stopColor="#00BD57"/>
      <stop offset="1" stopColor="#72F238"/>
      </linearGradient>
      </defs>
    </svg>
  );

  const level2SVG = (
    <svg width="36" height="45" viewBox="0 0 36 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.5907 0.656908C19.1688 0.235098 18.5966 -0.00186157 18 -0.00186157C17.4034 -0.00186157 16.8312 0.235098 16.4093 0.656908C14.5868 2.47941 11.97 3.55941 9.2475 4.12191C6.543 4.67991 3.96675 4.67541 2.4975 4.51116C2.18305 4.47636 1.8648 4.50826 1.56351 4.60479C1.26223 4.70131 0.984688 4.86029 0.749006 5.07134C0.513323 5.28239 0.324798 5.54078 0.195737 5.82962C0.0666753 6.11847 -2.16385e-05 6.43129 5.26601e-09 6.74766V26.9977C5.26601e-09 29.9227 1.278 32.5079 2.934 34.6364C4.5945 36.7694 6.75225 38.6032 8.81775 40.0792C11.4057 41.9089 14.1633 43.4859 17.0528 44.7884L17.0933 44.8064L17.1067 44.8132H17.1135C17.3935 44.9336 17.695 44.9959 17.9998 44.9963C18.3045 44.9967 18.6062 44.9351 18.8865 44.8154L18 42.7477C18.8865 44.8177 18.8888 44.8154 18.8888 44.8154L18.8933 44.8132L18.9045 44.8064L18.9495 44.7884L19.0958 44.7232C19.9087 44.3527 20.7115 43.9603 21.5033 43.5464C23.4719 42.5193 25.3692 41.361 27.1822 40.0792C29.2477 38.6032 31.4055 36.7717 33.066 34.6364C34.722 32.5102 36 29.9227 36 26.9977V6.74766C36 6.43129 35.9333 6.11847 35.8043 5.82962C35.6752 5.54078 35.4867 5.28239 35.251 5.07134C35.0153 4.86029 34.7378 4.70131 34.4365 4.60479C34.1352 4.50826 33.8169 4.47636 33.5025 4.51116C32.0332 4.67541 29.457 4.67991 26.7525 4.12191C24.03 3.55941 21.4132 2.47941 19.5907 0.656908Z" fill="url(#paint0_linear_2005_8362)"/>
      <path d="M10.8202 32.9981V29.6743L18.5866 22.4832C19.2471 21.844 19.801 21.2687 20.2485 20.7574C20.703 20.246 21.0475 19.7453 21.2819 19.2552C21.5162 18.7581 21.6334 18.2219 21.6334 17.6466C21.6334 17.0074 21.4878 16.4569 21.1966 15.9953C20.9055 15.5265 20.5077 15.1679 20.0035 14.9193C19.4992 14.6636 18.9275 14.5358 18.2883 14.5358C17.6206 14.5358 17.0383 14.6707 16.5411 14.9406C16.0439 15.2105 15.6604 15.5976 15.3905 16.1018C15.1206 16.6061 14.9857 17.2062 14.9857 17.9023H10.6072C10.6072 16.4747 10.9303 15.2354 11.5766 14.1842C12.2229 13.1331 13.1285 12.3199 14.2932 11.7446C15.458 11.1693 16.8003 10.8817 18.3202 10.8817C19.8827 10.8817 21.2428 11.1586 22.4005 11.7126C23.5653 12.2595 24.4708 13.0194 25.1171 13.9925C25.7634 14.9655 26.0866 16.0805 26.0866 17.3376C26.0866 18.1615 25.9232 18.9747 25.5965 19.7773C25.2769 20.5798 24.7052 21.4711 23.8813 22.4513C23.0574 23.4243 21.8962 24.5926 20.3976 25.9562L17.2123 29.0777V29.2268H26.3742V32.9981H10.8202Z" fill="white"/>
      <defs>
      <linearGradient id="paint0_linear_2005_8362" x1="-11.25" y1="5.62259" x2="48.375" y2="44.9976" gradientUnits="userSpaceOnUse">
      <stop stopColor="#E7DF29"/>
      <stop offset="1" stopColor="#E76202"/>
      </linearGradient>
      </defs>
    </svg>
  );

  const level3SVG = (
    <svg viewBox="0 0 36 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.5907 0.658769C19.1688 0.236959 18.5966 0 18 0C17.4034 0 16.8312 0.236959 16.4093 0.658769C14.5868 2.48127 11.97 3.56127 9.2475 4.12377C6.543 4.68177 3.96675 4.67727 2.4975 4.51302C2.18305 4.47822 1.8648 4.51012 1.56351 4.60665C1.26223 4.70317 0.984688 4.86215 0.749006 5.0732C0.513323 5.28426 0.324798 5.54264 0.195737 5.83149C0.0666753 6.12033 -2.16385e-05 6.43315 5.26601e-09 6.74952V26.9995C5.26601e-09 29.9245 1.278 32.5098 2.934 34.6383C4.5945 36.7713 6.75225 38.605 8.81775 40.081C11.4057 41.9108 14.1633 43.4878 17.0528 44.7903L17.0933 44.8083L17.1067 44.815H17.1135C17.3935 44.9355 17.695 44.9978 17.9998 44.9981C18.3045 44.9985 18.6062 44.937 18.8865 44.8173L18 42.7495C18.8865 44.8195 18.8888 44.8173 18.8888 44.8173L18.8933 44.815L18.9045 44.8083L18.9495 44.7903L19.0958 44.725C19.9087 44.3546 20.7115 43.9622 21.5033 43.5483C23.4719 42.5212 25.3692 41.3628 27.1822 40.081C29.2477 38.605 31.4055 36.7735 33.066 34.6383C34.722 32.512 36 29.9245 36 26.9995V6.74952C36 6.43315 35.9333 6.12033 35.8043 5.83149C35.6752 5.54264 35.4867 5.28426 35.251 5.0732C35.0153 4.86215 34.7378 4.70317 34.4365 4.60665C34.1352 4.51012 33.8169 4.47822 33.5025 4.51302C32.0332 4.67727 29.457 4.68177 26.7525 4.12377C24.03 3.56127 21.4132 2.48127 19.5907 0.658769Z" fill="url(#paint0_linear_2005_8368)"/>
      <path d="M18.4454 32.2983C16.8545 32.2983 15.4376 32.0249 14.1947 31.478C12.9589 30.924 11.9823 30.1641 11.265 29.1982C10.5548 28.2251 10.189 27.103 10.1677 25.8317H14.8126C14.841 26.3643 15.015 26.8331 15.3346 27.2379C15.6613 27.6357 16.0945 27.9446 16.6343 28.1648C17.1741 28.3849 17.7813 28.495 18.4561 28.495C19.1592 28.495 19.7806 28.3707 20.3204 28.1222C20.8602 27.8736 21.2828 27.5291 21.5882 27.0888C21.8936 26.6484 22.0463 26.1406 22.0463 25.5653C22.0463 24.983 21.8829 24.468 21.5562 24.0206C21.2366 23.5661 20.7749 23.2109 20.1713 22.9553C19.5747 22.6996 18.8644 22.5717 18.0406 22.5717H16.0058V19.1839H18.0406C18.7366 19.1839 19.3509 19.0632 19.8836 18.8217C20.4234 18.5803 20.8424 18.2464 21.1407 17.8203C21.439 17.3871 21.5882 16.8828 21.5882 16.3075C21.5882 15.7607 21.4568 15.2812 21.194 14.8693C20.9383 14.4503 20.5761 14.1236 20.1073 13.8892C19.6457 13.6548 19.1059 13.5376 18.488 13.5376C17.863 13.5376 17.2913 13.6513 16.7728 13.8786C16.2544 14.0987 15.8389 14.4148 15.5264 14.8267C15.2139 15.2386 15.047 15.7216 15.0257 16.2756H10.6045C10.6258 15.0185 10.9845 13.9105 11.6805 12.9517C12.3765 11.9929 13.314 11.2436 14.493 10.7038C15.6791 10.157 17.0178 9.88352 18.5093 9.88352C20.015 9.88352 21.3325 10.157 22.4617 10.7038C23.591 11.2507 24.4681 11.9893 25.0931 12.9197C25.7252 13.843 26.0377 14.88 26.0306 16.0305C26.0377 17.2521 25.6578 18.2713 24.8907 19.0881C24.1308 19.9048 23.14 20.4233 21.9184 20.6435V20.8139C23.5235 21.0199 24.7451 21.5774 25.5832 22.4865C26.4284 23.3885 26.8474 24.5178 26.8403 25.8743C26.8474 27.1172 26.4887 28.2216 25.7643 29.1875C25.047 30.1534 24.0562 30.9134 22.792 31.4673C21.5278 32.0213 20.0789 32.2983 18.4454 32.2983Z" fill="white"/>
      <defs>
      <linearGradient id="paint0_linear_2005_8368" x1="-11.25" y1="5.62445" x2="48.375" y2="44.9995" gradientUnits="userSpaceOnUse">
      <stop stopColor="#9747FF"/>
      <stop offset="1" stopColor="#FF76E9"/>
      </linearGradient>
      </defs>
    </svg>
  )

  return (
    <div className="creator-badge">
      {level === 0 && level0SVG}
      {level === 1 && level1SVG}
      {level === 2 && level2SVG}
      {level === 3 && level3SVG}
    </div>
  );
}

export function CreatorsPage() {
  const { data: creatorsData, isLoading, isError } = useGetCreatorsQuery();

  if (isLoading) {
    return (
      <div className="creators">
        <Header 
          title="CREATOR TRANSPARENCY" 
          desc="A list of creators who have enrolled in Tanzanite's transparency program" 
        />
        <div className="creators-section">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (isError || !creatorsData) {
    return (
      <div className="creators">
        <Header 
          title="CREATOR TRANSPARENCY" 
          desc="A list of creators who have enrolled in Tanzanite's transparency program" 
        />
        <div className="creators-section">
          <ErrorMessage message="There was an error loading the creator data." />
        </div>
      </div>
    );
  }

  return (
    <div className="creators">
      <Header 
        title="CREATOR TRANSPARENCY" 
        desc="A list of creators who have enrolled in Tanzanite's transparency program" 
      />
      <div className="creators-section">
        <LiveSection creatorsData={creatorsData} />
        <CreatorOverview creatorsData={creatorsData} />
        <CreatorCTA />
        <CreatorFAQ />
      </div>
    </div>
  );
}