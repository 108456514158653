import React from 'react';

interface SortIconProps {
  isSorted: boolean;
  sortDirection?: "asc" | "desc";
}

export const SortIcon: React.FC<SortIconProps> = ({ isSorted, sortDirection }) => {
  return (
    <div style={{ width: "1em", height: "1.5em", marginRight: "0.7em" }}>
      {isSorted ? (
        sortDirection === "asc" ? (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 15" fill="none">
            <path d="M0.833496 6L5.00016 1L9.16683 6H0.833496Z" fill="#E8EAED"/>
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 15" fill="none">
            <path d="M5.00016 14L0.833496 9H9.16683L5.00016 14Z" fill="#E8EAED"/>
          </svg>
        )
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 15" fill="none">
          <path d="M0.833496 6L5.00016 1L9.16683 6H0.833496Z" fill="#E8EAED"/>
          <path d="M5.00016 14L0.833496 9H9.16683L5.00016 14Z" fill="#E8EAED"/>
        </svg>
      )}
    </div>
  );
};

export default SortIcon;
