import { useState } from "react";
import { BlogCard } from "../../components/blog/BlogCard";
import { BlogCTA } from "../../components/blog/BlogCTA";
import { Header } from "../../components/header/Header";
import { useGetBlogsQuery } from "../../state/api";
import "./BlogPage.scss";
import Pagination from "../../components/tz table/Pagination";
import { ErrorMessage } from "../../components/middleware states/ErrorMessage";
import { LoadingSpinner } from "../../components/middleware states/LoadingAnimation";

export function BlogPage() {
  const { data, isLoading, isError } = useGetBlogsQuery(0);

  if (isError) {
    return (
      <div className="blog-page" style={{marginBottom: "7em"}}>
        <Header title="BLOG" desc="" />

        <div className="blog-page__cta">
          <BlogCTA />
        </div>
        <ErrorMessage message="There was an error loading blogs" />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="blog-page" style={{marginBottom: "7em"}}>
        <Header title="BLOG" desc="" />

        <div className="blog-page__cta">
          <BlogCTA />
        </div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="blog-page">
      <Header title="BLOG" desc="" />

      <div className="blog-page__cta">
        <BlogCTA />
      </div>
  
      <div className="blogs-list">
        {data?.posts?.slice(0, 9).map((post) => (
          <BlogCard key={post.id} post={post} />
        ))}
      </div>
    </div>
  )
}